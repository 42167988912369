import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useAuth } from "../../../context/AuthProvider";
import { getPath, ROOT_DASHBOARD } from "../../../utils/constants";
import LogoutPage from "../../layouts/nav/Logout";

export default function HeaderProfile() {
  const { userDetail } = useAuth();
  const path = getPath();
  return (
    <Dropdown as="li" className="nav-item dropdown header-profile">
      <Dropdown.Toggle
        variant=""
        as="a"
        className="nav-link i-false c-pointer"
        role="button"
        data-toggle="dropdown"
      >
        <img
          src={userDetail?.imageUrl || "/assets/images/default-image-user.png"}
          width={20}
          alt=""
        />
        <div className="header-info ms-3 pe-4">
          <span className="font-w600 ">
            Hi,<b>{userDetail?.firstName}</b>
          </span>
          <small className="text-end font-w400">{userDetail?.email}</small>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="right"
        className="mt-3 dropdown-menu dropdown-menu-end"
      >
        {path === "" ? (
          <Link to={ROOT_DASHBOARD} className="dropdown-item ai-icon">
            <i class="fas fa-paper-plane text-primary"></i>
            <span className="ms-2">Dashboard </span>
          </Link>
        ) : (
          <>
            <Link to="/profile" className="dropdown-item ai-icon">
              <svg
                id="icon-user1"
                xmlns="http://www.w3.org/2000/svg"
                className="text-primary me-1"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx={12} cy={7} r={4} />
              </svg>
              <span className="ms-2">Profile </span>
            </Link>
            <Link to="/change-password" className="dropdown-item ai-icon">
              <i className="fas fa-key text-primary"></i>
              <span className="ms-2">Change Password </span>
            </Link>
          </>
        )}
        <LogoutPage />
      </Dropdown.Menu>
    </Dropdown>
  );
}
