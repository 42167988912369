import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useHistory } from "react-router-dom";
import { postStaf } from "../../../../../services/StafService";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import FormStafClinicComponent from "../../FormStafClinicComponent";

export default function EditStafClinic() {
  const notification = useNotification();
  const localize = useLocalization();
  const location = useLocation();
  const history = useHistory();

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postStaf,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          history.goBack();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleSubmitForm = (value) => {
    const data = {
      id: location.state.id,
      userPhoneNumber: value.phoneNumber,
      userDob: value.dateOfBirth,
      userGender: value.gender,
      userAddress: value.address,
      name: value.name,
      email: value.email,
      password: value.password,
      role: value.role.value,
      identificationNumber: value.identificationNumber,
    };

    mutateSubmit(data);
  };

  return (
    <FormStafClinicComponent
      handleSubmitForm={handleSubmitForm}
      isLoadingSubmit={isLoadingSubmit}
      isEdit
      initialValues={location?.state}
    />
  );
}
