import instance from "../config/interceptor";
import { PER_PAGE_SIZE } from "../utils/constants";

export const getStafAll = async ({ page = 0, size = PER_PAGE_SIZE, role }) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/staff?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);
    if (role) link = link.concat("&role=", role);

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const postStaf = async (data) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/staff`,
      data
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};
