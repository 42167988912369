import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Module
// import Dashboard from "./module/Dashboard";
import Home from "./module/Home";
import PasienHome from "./module/PasienHome";

import Profile from "./module/Profile/Container";
import ChangePassword from "./module/ChangePassword/Container";

import Patient from "./module/Patient/ListPatient/Container";
import AddPatient from "./module/Patient/AddPatient/Container";
import EditPatient from "./module/Patient/EditPatient/Container";
import DetailPatient from "./module/Patient/DetailPatient/Container";
import Assesment from "./module/ActiveVisit/Assesment/Container";

import ListActiveVisit from "./module/ActiveVisit/ListActiveVisit/Container";
import ListHistoryVisit from "./module/HistoryVisit/container/ListHistoryVisit";
import Reservation from "./module/Reservation/container";

import ListStafClinic from "./module/StafClinic/ListStafClinic/Container";
import AddStafClinic from "./module/StafClinic/AddStafClinic/Container";
import EditStafClinic from "./module/StafClinic/EditStafClinic/Container";
import DetailStafClinic from "./module/StafClinic/DetailStafClinic/Container";
import ScheduleDoctor from "./module/StafClinic/ScheduleDoctor/container";

import HistoryVisit from "./module/Patient/HistoryVisit/Container";

import HistoryPayment from "./module/HistoryPayment/container";

import NotFound from "./module/404";

import BusinessProfile from "./module/BusinessProfile";

import CategoryItem from "./module/CategoryItem/container";
import AddCategoryItem from "./module/CategoryItem/container/AddCategoryItem";
import DetailCategoryItem from "./module/CategoryItem/container/DetialCategoryItem";
import EditCategoryItem from "./module/CategoryItem/container/EditCategoryItem";

import Item from "./module/MasterItem/container";
import AddItem from "./module/MasterItem/container/AddItem";
import DetailItem from "./module/MasterItem/container/DetailItem";
import EditItem from "./module/MasterItem/container/EditItem";

// SATU SEHAT
import Credential from "./module/SatuSehat/Credential/container";

import Organization from "./module/SatuSehat/Organization/container";
import AddOrganization from "./module/SatuSehat/Organization/container/AddOrganization";
import DetailOrganization from "./module/SatuSehat/Organization/container/DetailOrganization";
import EditOrganization from "./module/SatuSehat/Organization/container/EditOrganization";

import Location from "./module/SatuSehat/Location/container";
import AddLocation from "./module/SatuSehat/Location/container/AddLocation";
import EditLocation from "./module/SatuSehat/Location/container/EditLocation";
import DetailLocation from "./module/SatuSehat/Location/container/DetialLocation";
import TemplateEmail from "./module/TemplateEmail";
/// Pages

import { ThemeContext } from "../context/ThemeContext";
import { getPath } from "../utils/constants";
import { useAuth } from "../context/AuthProvider";
import { roleUser, homePath } from "../utils/constants";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const { role } = useAuth();
  const routes = [
    { url: "", component: Home },
    { url: "pasien", component: PasienHome },
    { url: "template-email", component: TemplateEmail },
    // { url: "dashboard", component: Dashboard },

    { url: "profile", component: Profile },
    { url: "change-password", component: ChangePassword },

    { url: "patient", component: Patient },
    { url: "patient/add-patient", component: AddPatient },
    { url: "patient/edit-patient", component: EditPatient },
    { url: "patient/detail-patient", component: DetailPatient },

    { url: "active-visit", component: ListActiveVisit },
    { url: "history-visit", component: ListHistoryVisit },
    { url: "reservation", component: Reservation },

    { url: "active-visit/assesment", component: Assesment },

    { url: "history", component: HistoryVisit },
    { url: "payment", component: HistoryPayment },

    { url: "category-item", component: CategoryItem },
    { url: "category-item/add-category-item", component: AddCategoryItem },
    {
      url: "category-item/detail-category-item",
      component: DetailCategoryItem,
    },
    {
      url: "category-item/edit-category-item",
      component: EditCategoryItem,
    },

    { url: "item", component: Item },
    { url: "item/add-item", component: AddItem },
    {
      url: "item/detail-item",
      component: DetailItem,
    },
    {
      url: "item/edit-item",
      component: EditItem,
    },
    { url: "user-clinic", component: ListStafClinic },
    {
      url: "user-clinic/detail-user-clinic",
      component: DetailStafClinic,
    },
    {
      url: "user-clinic/schedule-doctor-clinic",
      component: ScheduleDoctor,
    },
    ...(role === roleUser.ROLE_OWNER
      ? [
          { url: "business-profile", component: BusinessProfile },
          { url: "user-clinic/add-user-clinic", component: AddStafClinic },
          { url: "user-clinic/edit-user-clinic", component: EditStafClinic },
          {
            url: "credential",
            component: Credential,
          },
          {
            url: "organization",
            component: Organization,
          },
          {
            url: "organization/add-organization",
            component: AddOrganization,
          },
          {
            url: "organization/detail-organization",
            component: DetailOrganization,
          },
          {
            url: "organization/edit-organization",
            component: EditOrganization,
          },
          {
            url: "location",
            component: Location,
          },
          {
            url: "location/add-location",
            component: AddLocation,
          },
          {
            url: "location/edit-location",
            component: EditLocation,
          },
          {
            url: "location/detail-location",
            component: DetailLocation,
          },
        ]
      : []),
    { url: "*", component: NotFound },
  ];
  const path = getPath();
  let title = "";
  let pagePath = path.split("-").includes("page");

  if (!homePath.includes(path)) {
    return (
      <>
        <div
          id={`${!pagePath ? "main-wrapper" : ""}`}
          className={`${!pagePath ? "show" : "mh100vh"}  ${
            menuToggle ? "menu-toggle" : ""
          }`}
        >
          {!pagePath && <Nav title={title} />}

          <div className={`${!pagePath ? "content-body" : ""}`}>
            <div
              className={`${!pagePath ? "container-fluid" : ""}`}
              style={{ minHeight: window.screen.height - 60 }}
            >
              <Switch>
                {routes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))}
              </Switch>
            </div>
          </div>
          {!pagePath && <Footer />}
        </div>
      </>
    );
  }

  return (
    <Switch>
      {routes.map((data, i) => (
        <Route key={i} exact path={`/${data.url}`} component={data.component} />
      ))}
    </Switch>
  );
};

export default Markup;
