import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { postPatient } from "../../../../../services/PatientService";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import FormPatient from "../../Components/FormPatients";
import moment from "moment";

export default function AddPatient() {
  const history = useHistory();
  const notification = useNotification();
  const localize = useLocalization();

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postPatient,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          history.goBack();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleSubmitForm = (value) => {
    const alergiesDTOs = value.allergies.map((item) => ({
      ...item,
      ...(item.__isNew__ ? {} : { __isNew__: false }),
    }));

    const data = {
      ...value,
      allergy: alergiesDTOs,
      dob: moment(value.dateOfBirth).format("DD-MM-YYYY"),
    };
    mutateSubmit(data);
  };

  return (
    <FormPatient
      handleSubmitForm={handleSubmitForm}
      isLoadingSubmit={isLoadingSubmit}
    />
  );
}
