import React from "react";
import classNames from "classnames";
import {
  OverlayTrigger,
  Tooltip,
  Button as ButtonCustom,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";

export default function Button({
  label,
  onClick,
  variant = "primary",
  fullWidth = false,
  type = "button",
  loading = false,
  disabled = false,
  size,
  children,
  tooltip = "",
  style,
  className,
  leftIcon,
  isDropdown = false,
  dropdownMenus = [],
}) {
  return (
    <OverlayTrigger
      placement="top"
      trigger={["hover", "focus"]}
      overlay={
        tooltip ? <Tooltip id="button-tooltip-2">{tooltip}</Tooltip> : <p></p>
      }
    >
      {({ ref, ...triggerHandler }) =>
        isDropdown ? (
          <ButtonGroup>
            <Dropdown>
              <Dropdown.Toggle
                ref={ref}
                variant={variant}
                fullWidth={fullWidth}
                size={size}
                type={type}
                disabled={loading || disabled}
                {...triggerHandler}
                className={classNames(
                  leftIcon ? "gap-2" : "gap-3",
                  `d-flex justify-content-center align-items-center p-2 ${
                    fullWidth && "btn-block"
                  }`,
                  className
                )}
                style={{ ...(!children && { minWidth: 120 }), ...style }}
              >
                {loading && (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {((!label && !loading) || label) && children}
                {leftIcon && leftIcon}
                {label}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dropdownMenus.map((menu, index) => (
                  <Dropdown.Item onClick={menu?.onClick} key={index}>
                    {menu?.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </ButtonGroup>
        ) : (
          <ButtonCustom
            ref={ref}
            variant={variant}
            fullWidth={fullWidth}
            size={size}
            type={type}
            onClick={onClick}
            disabled={loading || disabled}
            {...triggerHandler}
            className={classNames(
              leftIcon ? "gap-2" : "gap-3",
              `d-flex justify-content-center align-items-center p-2  ${
                fullWidth && "btn-block"
              }`,
              !children && "px-4",
              className
            )}
            style={{
              ...(!children &&
                !fullWidth && { minWidth: 120, width: "fit-content" }),
              ...style,
            }}
          >
            {loading && (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {((!label && !loading) || label) && children}
            {leftIcon && leftIcon}
            {label}
          </ButtonCustom>
        )
      }
    </OverlayTrigger>
  );
}
