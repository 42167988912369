import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import LocationComponent from "../components";
import { getLocations } from "../../../../../services/SatuSehat/LocationService";

export default function Location() {
  const [dataLocations, setDataLocations] = useState([]);

  const { isLoading } = useQuery(["LocationList"], getLocations, {
    onSuccess: (data) => {
      if (data.status === 200 || data.status === 201) {
        setDataLocations(data?.data?.entry || []);
      }
    },
  });

  return (
    <LocationComponent dataLocations={dataLocations} isLoading={isLoading} />
  );
}
