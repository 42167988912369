import React from "react";
import FormLocation from "../FormLocation";

export default function AddLocationComponent({
  handleSubmit,
  isLoadingSubmit,
}) {
  return (
    <FormLocation
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={{}}
    />
  );
}
