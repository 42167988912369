import { useLocalization } from "../context/LocalizeContextProvider";
import { useAuth } from "../context/AuthProvider";

export const optionsFilterDuration = [
  { label: "Harian", value: "day", format: "DD-MM-YYYY" },
  { label: "Bulanan", value: "month", format: "MMMM" },
  { label: "Tahunan", value: "year", format: "YYYY" },
];

export const languages = [
  { id: "id", label: "Indonesia", icon: "/assets/svg/indonesia-flag.svg" },
  { id: "en", label: "English", icon: "/assets/svg/united.svg" },
];

export const colorsAllergys = ["bg-blue", "bg-orange", "bg-danger"];

export const Gender = () => {
  const localize = useLocalization();

  return [
    { label: localize.getText("male"), id: "MALE" },
    { label: localize.getText("female"), id: "FEMALE" },
  ];
};

export const TypeIdentity = () => {
  const localize = useLocalization();

  return [
    { label: localize.getText("ktp"), id: "KTP" },
    { label: localize.getText("passport"), id: "PASSPORT" },
  ];
};

export const paymentMethod = [
  { label: "CASH", value: "CASH" },
  { label: "CREDIT_CARD", value: "CREDIT_CARD" },
  { label: "DEBIT", value: "DEBIT" },
  { label: "TRANSFER", value: "TRANSFER" },
  { label: "QRIS", value: "QRIS" },
];

export const typeDiscount = [
  { label: "Presentase", value: "PERCENTAGE" },
  { label: "Nominal", value: "NUMBER" },
];

export const typeDiscountObj = {
  PERCENTAGE: "PERCENTAGE",
  NUMBER: "NUMBER",
};

export const paymentItemType = {
  CONSULTATION: "CONSULTATION",
  CORRECTION: "CORRECTION",
  FARMASI: "FARMASI",
};

export const paymentTypeOptions = [
  { label: paymentItemType.CONSULTATION, value: paymentItemType.CONSULTATION },
  { label: paymentItemType.CORRECTION, value: paymentItemType.CORRECTION },
  { label: paymentItemType.FARMASI, value: paymentItemType.FARMASI },
];

export const roleUser = {
  ROLE_DOCTOR: "ROLE_DOCTOR",
  ROLE_BEAUTICIAN: "ROLE_BEAUTICIAN",
  ROLE_STAFF: "ROLE_STAFF",
  ROLE_OWNER: "ROLE_OWNER",
};

export const RoleUsers = () => {
  const localize = useLocalization();

  return [
    { label: localize.getText("doctor"), id: roleUser.ROLE_DOCTOR },
    { label: localize.getText("employee"), id: roleUser.ROLE_STAFF },
    { label: localize.getText("beautician"), id: roleUser.ROLE_BEAUTICIAN },
    { label: localize.getText("owner"), id: roleUser.ROLE_OWNER },
  ];
};

export const statusReservation = [
  { label: "Reservasi", value: "BOOKED", color: "bg-blue-light" },
  { label: "Aktif", value: "ACTIVE", color: "bg-success" },
  { label: "Selesai", value: "DONE", color: "bg-orange" },
  { label: "Batal", value: "CANCELLED", color: "bg-danger" },
];

export const homePath = [
  "login",
  "register",
  "forgot-password",
  "activate",
  "finish",
  "reservation-success",
  "reservation-pasien",
  "pasien",
  "template-email",
  "print-invoice",
  "",
];

export const validEmail =
  // eslint-disable-next-line max-len, no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getPath = () => {
  let path = window.location?.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return path;
};

export const PER_PAGE_SIZE = 50;

export const ROOT_DASHBOARD = "/active-visit";

export const logoImage = {
  main: "/assets/svg/logo-sistem-klinik.svg",
  mini: "/assets/svg/mini-logo.svg",
};

export const SidebarMenu = () => {
  const localize = useLocalization();
  const { role } = useAuth();

  return [
    //DY: LATER CHANGE TO DASHBOARD
    // {
    //   url: "/",
    //   label: localize.getText("dashboard"),
    //   icon: "fas fa-home",
    //   allowActive: ["", "dashboard"],
    // },
    {
      url: "",
      label: localize.getText("visit"),
      icon: "fas fa-paper-plane",
      allowActive: [
        "active-visit",
        "assesment",
        "history-visit",
        "reservation",
      ],
      subMenu: [
        { url: "/active-visit", label: localize.getText("active") },
        { url: "/history-visit", label: localize.getText("history") },
        { url: "/reservation", label: localize.getText("reservation") },
      ],
    },
    {
      url: "/patient",
      label: localize.getText("patient"),
      icon: "fas fa-users",
      allowActive: [
        "patient",
        "add-patient",
        "history",
        "detail-patient",
        "edit-patient",
      ],
      subMenu: [],
    },
    {
      url: "/payment",
      label: localize.getText("payment"),
      icon: "fas fa-file-invoice-dollar",
      allowActive: ["payment"],
      subMenu: [],
    },

    {
      url: "/user-clinic",
      label: localize.getText("staff"),
      icon: "fas fa-user-md",
      allowActive: [
        "user-clinic",
        "add-user-clinic",
        "edit-user-clinic",
        "detail-user-clinic",
        "schedule-doctor-clinic",
      ],
      subMenu: [],
    },
    ...(role === roleUser.ROLE_OWNER
      ? [
          {
            url: "/",
            label: localize.getText("masterData"),
            icon: "fas fa-list",
            allowActive: [
              "item",
              "category-item",
              "add-category-item",
              "add-item",
              "detail-item",
              "edit-item",
              "detail-category-item",
              "edit-category-item",
            ],
            subMenu: [
              { url: "/item", label: localize.getText("item") },
              {
                url: "/category-item",
                label: localize.getText("category-item"),
              },
            ],
          },
          {
            url: "/business-profile",
            label: localize.getText("businessProfile"),
            icon: "fas fa-building",
            allowActive: ["business-profile"],
            subMenu: [],
          },

          {
            url: "/",
            label: localize.getText("satuSehat"),
            icon: "fas fa-stethoscope",
            allowActive: [
              "location",
              "organization",
              "credential",
              "add-organization",
            ],
            subMenu: [
              { url: "/credential", label: localize.getText("credential") },
              { url: "/organization", label: localize.getText("organization") },
              { url: "/location", label: localize.getText("location") },
            ],
          },
        ]
      : []),
  ];
};

export const AVAILABLE_DAYS = [
  { id: 1, label: "Minggu" },
  { id: 2, label: "Senin" },
  { id: 3, label: "Selasa" },
  { id: 4, label: "Rabu" },
  { id: 5, label: "Kamis" },
  { id: 6, label: "Jumat" },
  { id: 7, label: "Sabtu" },
];

export const AVAILABLE_TIME_SCHEDULE = [
  {
    time: "06-07",
    label: "Pagi",
    value: { from: "06:00", to: "07:00", enabled: true },
  },
  {
    time: "07-08",
    label: "Pagi",
    value: { from: "07:00", to: "08:00", enabled: true },
  },
  {
    time: "08-09",
    label: "Pagi",
    value: { from: "08:00", to: "09:00", enabled: true },
  },
  {
    time: "09-10",
    label: "Menjelang Siang",
    value: { from: "09:00", to: "10:00", enabled: true },
  },
  {
    time: "10-11",
    label: "Menjelang Siang",
    value: { from: "10:00", to: "11:00", enabled: true },
  },
  {
    time: "11-12",
    label: "Menjelang Siang",
    value: { from: "11:00", to: "12:00", enabled: true },
  },
  {
    time: "12-13",
    label: "Siang",
    value: { from: "12:00", to: "13:00", enabled: true },
  },
  {
    time: "13-14",
    label: "Siang",
    value: { from: "13:00", to: "14:00", enabled: true },
  },
  {
    time: "14-15",
    label: "Siang",
    value: { from: "14:00", to: "15:00", enabled: true },
  },
  {
    time: "15-16",
    label: "Sore",
    value: { from: "15:00", to: "16:00", enabled: true },
  },
  {
    time: "16-17",
    label: "Sore",
    value: { from: "16:00", to: "17:00", enabled: true },
  },
  {
    time: "17-18",
    label: "Sore",
    value: { from: "17:00", to: "18:00", enabled: true },
  },
  {
    time: "18-19",
    label: "Malam",
    value: { from: "18:00", to: "19:00", enabled: true },
  },
  {
    time: "19-20",
    label: "Malam",
    value: { from: "19:00", to: "20:00", enabled: true },
  },
  {
    time: "20-21",
    label: "Malam",
    value: { from: "20:00", to: "21:00", enabled: true },
  },
  {
    time: "21-22",
    label: "Larut Malam",
    value: { from: "21:00", to: "22:00", enabled: true },
  },
  {
    time: "22-23",
    label: "Larut Malam",
    value: { from: "22:00", to: "23:00", enabled: true },
  },
  {
    time: "23-00",
    label: "Larut Malam",
    value: { from: "23:00", to: "00:00", enabled: true },
  },
  {
    time: "00-01",
    label: "Larut Malam",
    value: { from: "00:00", to: "01:00", enabled: true },
  },
  {
    time: "01-02",
    label: "Subuh",
    value: { from: "01:00", to: "02:00", enabled: true },
  },
  {
    time: "02-03",
    label: "Subuh",
    value: { from: "02:00", to: "03:00", enabled: true },
  },
  {
    time: "03-04",
    label: "Subuh",
    value: { from: "03:00", to: "04:00", enabled: true },
  },
  {
    time: "04-05",
    label: "Subuh",
    value: { from: "04:00", to: "05:00", enabled: true },
  },
  {
    time: "05-06",
    label: "Menjelang Pagi",
    value: { from: "05:00", to: "06:00", enabled: true },
  },
];
