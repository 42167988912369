import { uploadImage } from "../services/GeneralService";
import moment from "moment-timezone";

export const getCurrentUtc = () => {
  // Get the current time in the local time zone
  const now = moment();

  // Get the UTC offset in minutes for the local time zone
  const utcOffsetMinutes = now.utcOffset();

  // Calculate the hours and get the sign
  const hours = Math.floor(Math.abs(utcOffsetMinutes) / 60);
  const sign = utcOffsetMinutes < 0 ? "-" : "+";

  // Create the formatted time zone string
  const formattedTimeZone = `${sign}${String(hours)}`;

  return formattedTimeZone;
};

export const base64ToFile = (base64String, filename) => {
  const arr = base64String.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const formatNumberWithCurrency = (number) => {
  const isNegative = number < 0;
  const absNumber = Math.abs(number);
  const formattedString = absNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const result = `${isNegative ? "-Rp" : "Rp"} ${formattedString}`;
  return result;
};

export const findItemById = (data, itemId) => {
  for (const category of data) {
    for (const item of category.options) {
      if (item.value === itemId) {
        return item;
      }
    }
  }
  return null;
};

export const getCSSVariable = (variableName) => {
  return getComputedStyle(document.body).getPropertyValue(variableName).trim();
};

export const getParams = (params) => {
  var url = new URL(window.location.href);
  return url.searchParams.get(params);
};

export const fileReader = (files) => {
  let filePreview = "";
  let fileDocument = files;
  let fileName = files?.name;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;
    reader.onload = async () => {
      if (reader.readyState === 2) {
        filePreview = reader.result;
        resolve({
          fileDocument,
          filePreview,
          fileName,
        });
      }
    };
    reader.readAsDataURL(files);
  });
};

export const retrieveUrlFile = async (file, post_type = "REKAM_MEDIS") => {
  const url = await uploadImage(file, post_type, () => {
    return;
  });
  return url;
};

export const openLinkNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const setItemToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItemStorage = (key) => {
  return (
    localStorage.getItem(key) && JSON.parse(localStorage.getItem(key) || "")
  );
};

export const clearItemStorage = () => {
  localStorage.clear();
};

const base64UrlDecode = (base64Url) => {
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  while (base64.length % 4) {
    base64 += "=";
  }
  return atob(base64);
};

export const decodeJwtToken = (token) => {
  const [header, payload, signature] = token.split(".");
  const decodedHeader = JSON.parse(base64UrlDecode(header));
  const decodedPayload = JSON.parse(base64UrlDecode(payload));
  return {
    header: decodedHeader,
    payload: decodedPayload,
    signature: signature,
  };
};

export const satuSehatFindTelecomRes = (response, key) => {
  return response.telecom.find((item) => item.system === key).value;
};

export const satuSehatFindAddressExtentionRes = (response, key) => {
  return response.address[0].extension[0].extension.find(
    (item) => item.url === key
  ).valueCode;
};

export const satuSehatLocFindAddressExtentionRes = (response, key) => {
  return response.address.extension[0].extension.find(
    (item) => item.url === key
  ).valueCode;
};

export const formatOptionsToArray = (allergies = []) => {
  if (!allergies || allergies.length === 0) {
    return [];
  }

  return allergies.map((item) => item.label);
};

export const downloadBlob = (data, fileName, extension = "csv") => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  const file_name = `${fileName}.${extension}`;
  link.href = url;
  link.setAttribute("download", file_name);
  document.body.appendChild(link);
  link.click();
};

export const formatWithCurrentUTC = (
  dateTimeString,
  format = "DD-MM-YYYY HH:mm:ss",
  formatOutput = "DD-MM-YYYY HH:mm:ss"
) => {
  let timezoneOffset = moment().utcOffset() / 60;
  let parsedDateTime = moment(dateTimeString, format);
  parsedDateTime.add(timezoneOffset, "hours");
  let formattedDateTime = parsedDateTime.format(formatOutput);

  return formattedDateTime;
};

export const convertToUTCPlus7 = (
  timestamp,
  format = "DD-MM-YYYY HH:mm:ss",
  formatOutput = "DD-MM-YYYY HH:mm:ss"
) => {
  const originalTimestamp = moment.utc(timestamp, format);
  const convertedTimestamp = originalTimestamp.clone().tz("Asia/Bangkok");
  const formattedTimestamp = convertedTimestamp.format(formatOutput);
  return formattedTimestamp;
};

export const formatDaySlotsDataOneHour = (datas) => {
  const dataDays = datas.map((day) => {
    const dataSlots = day.slots.map((slot) => {
      const timeFrom = formatTimeToMoment(slot.from, "HH:mm:ss");
      let label;
      let to;

      switch (timeFrom) {
        case "06:00":
          label = "Pagi";
          to = "07:00";
          break;
        case "07:00":
          label = "Pagi";
          to = "08:00";
          break;
        case "08:00":
          label = "Pagi";
          to = "09:00";
          break;
        case "09:00":
          label = "Menjelang Siang";
          to = "10:00";
          break;
        case "10:00":
          label = "Menjelang Siang";
          to = "11:00";
          break;
        case "11:00":
          label = "Menjelang Siang";
          to = "12:00";
          break;
        case "12:00":
          label = "Siang";
          to = "13:00";
          break;
        case "13:00":
          label = "Siang";
          to = "14:00";
          break;
        case "14:00":
          label = "Siang";
          to = "15:00";
          break;
        case "15:00":
          label = "Sore";
          to = "16:00";
          break;
        case "16:00":
          label = "Sore";
          to = "17:00";
          break;
        case "17:00":
          label = "Sore";
          to = "18:00";
          break;
        case "18:00":
          label = "Malam";
          to = "19:00";
          break;
        case "19:00":
          label = "Malam";
          to = "20:00";
          break;
        case "20:00":
          label = "Malam";
          to = "21:00";
          break;
        case "21:00":
          label = "Larut Malam";
          to = "22:00";
          break;
        case "22:00":
          label = "Larut Malam";
          to = "23:00";
          break;
        case "23:00":
          label = "Larut Malam";
          to = "00:00";
          break;
        case "00:00":
          label = "Larut Malam";
          to = "01:00";
          break;
        case "01:00":
          label = "Subuh";
          to = "02:00";
          break;
        case "02:00":
          label = "Subuh";
          to = "03:00";
          break;
        case "03:00":
          label = "Subuh";
          to = "04:00";
          break;
        case "04:00":
          label = "Subuh";
          to = "05:00";
          break;
        case "05:00":
          label = "Menjelang Pagi";
          to = "06:00";
          break;
        default:
          break;
      }

      if (timeFrom && to) {
        return {
          value: {
            from: timeFrom,
            to: to,
            enabled: slot.enabled,
          },
          time: `${timeFrom.split(":")[0]}-${to.split(":")[0]}`,
          label: label,
        };
      }

      return null;
    });

    return {
      day: day.adjusted_day_of_week,
      slots: dataSlots.filter(Boolean),
      isActive: day.active_in_day,
    };
  });

  return dataDays;
};

export const formatTimeToMoment = (date, formatString, format = "HH:mm") => {
  return moment(date, formatString).format(format);
};

export const calculateTimeBooking = (times) => {
  const totalMinutes = (times / 4) * 60;

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours === 0) {
    return `${minutes} Menit`;
  } else if (minutes === 0) {
    return `${hours} Jam`;
  } else {
    return `${hours} Jam ${minutes} Menit`;
  }
};

export const formatTimeSlots = (timeSlots = []) => {
  return timeSlots.map((item) => ({
    availability_start: moment(item.availability_start, "HH:mm:ss").format(
      "HH:mm"
    ),
    availability_end: moment(item.availability_end, "HH:mm:ss").format("HH:mm"),
    id: item.id,
  }));
};

export const getFilteredTime = (selectedFilter, timeArray) => {
  switch (selectedFilter) {
    case 60:
      return getTimeSlotOneHourFormat(timeArray);
    case 30:
      return getThirtyMinuteTime(timeArray);
    default:
      return timeArray;
  }
};

export const getThirtyMinuteTime = (scheduleTime) => {
  const doctorHalfHour = [];

  scheduleTime.forEach((item, index) => {
    const startTime = item.availability_start;
    const endTime = scheduleTime[index + 1]?.availability_end;

    if (
      isRoundedTime(startTime, true) &&
      endTime &&
      checkTimeAvailable(startTime, endTime, 30)
    ) {
      doctorHalfHour.push({
        availability_start: startTime,
        availability_end: endTime,
        id: 0,
      });
    }
  });

  return doctorHalfHour;
};

export const getTimeSlotOneHourFormat = (scheduleTime = []) => {
  const formatedTimeSlot = [];

  scheduleTime.forEach((doctorItem, index) => {
    const startTime = doctorItem.availability_start;
    const endTime = scheduleTime[index + 3]?.availability_end;

    if (
      isRoundedTime(startTime) &&
      endTime &&
      checkTimeAvailable(startTime, endTime, 1)
    ) {
      formatedTimeSlot.push({
        availability_start: startTime,
        availability_end: endTime,
        active: doctorItem?.active || false,
        id: 0,
      });
    }
  });

  return formatedTimeSlot;
};

export const groupingAvailableSlotWithTime = (data, time) => {
  const timeToCompare = moment(time, "HH:mm");

  return data.filter((slot) => {
    const startTime = moment(slot.availability_start, "HH:mm:ss");
    return timeToCompare.format("HH") === startTime.format("HH");
  });
};

export const getTimeSlotOneHourReservationFormat = (
  scheduleTime = [],
  reservations = []
) => {
  const reservationSchedule = [];
  const dataReservation = [];
  const doctorHour = [];

  scheduleTime
    .sort((a, b) => a.availability_start.localeCompare(b.availability_start))
    .forEach((doctorItem, index) => {
      const startTime = doctorItem.availability_start;
      const endTime = scheduleTime[index + 3]?.availability_end;

      if (
        isRoundedTime(startTime) &&
        endTime &&
        checkTimeAvailable(startTime, endTime, 1)
      ) {
        doctorHour.push({
          availability_start: moment(startTime, "HH:mm:ss").format("HH:mm"),
          availability_end: moment(endTime, "HH:mm:ss").format("HH:mm"),
          active: doctorItem?.active || false,
          id: 0,
        });
      }
    });

  reservations.forEach((reservation) => {
    const reservationFormated = reservation?.timeSlots.map((slot) => {
      return {
        availability_start:
          moment(slot.availability_start, "HH:mm:ss").format("HH") + ":00",
        availability_end: slot.availability_end,
        id: slot.id,
      };
    });

    dataReservation.push({
      ...reservation,
      timeSlots: reservationFormated,
    });
  });

  doctorHour.forEach((doctor) => {
    const patients = [];
    dataReservation.forEach((reservation) => {
      if (
        doctor.availability_start ===
        moment(
          reservation?.timeSlots[0]?.availability_start,
          "HH:mm:ss"
        ).format("HH:mm")
      ) {
        patients.push({
          name: reservation.name,
          noSequence: reservation.noSequence,
          status: reservation.status,
          patientId: reservation.patientId,
          id: reservation.id,
          durationVisit: reservation.durationVisit,
        });
      }
    });

    reservationSchedule.push({
      active: doctor.active,
      time: doctor.availability_start,
      patients,
      availableSchedule: groupingAvailableSlotWithTime(
        scheduleTime,
        doctor.availability_start
      ),
    });
  });

  return reservationSchedule;
};

export const isRoundedTime = (time, is30Minutes = false) => {
  const parsedTime = moment(time, "HH:mm");
  const minutes = parsedTime.minutes();

  if (is30Minutes) {
    return minutes === 0 || minutes === 30;
  }
  return minutes === 0;
};

export const checkTimeAvailable = (startTime, endTime, differenceInMinutes) => {
  const diff = {
    1: "hour",
    30: "minutes",
  };
  const parsedTime1 = moment(startTime, "HH:mm");
  const parsedTime2 = moment(endTime, "HH:mm");
  const timeDifference = parsedTime2.diff(
    parsedTime1,
    diff[differenceInMinutes]
  );

  return timeDifference === differenceInMinutes;
};
