import React, { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import CategoryItemComponent from "../components";
import {
  getCategoryItems,
  deleteCategoryItem,
} from "../../../../services/Handlers/MasterCategoryItemService";
import { useAuth } from "../../../../context/AuthProvider";
import { useNotification } from "../../../../context/NotificationProvider";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import { useModal } from "../../../../context/ModalProvider";
import { PER_PAGE_SIZE } from "../../../../utils/constants";

export default function CategoryItem() {
  const localize = useLocalization();
  const notification = useNotification();
  const modal = useModal();

  const { userDetail } = useAuth();

  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [dataCategoryItem, setDataCategoryItem] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE_SIZE);

  const { isLoading, isFetching, refetch } = useQuery(
    ["CategoryItemList", userDetail?.businessId, page, sizePerPage],
    () =>
      getCategoryItems({
        businessId: userDetail?.businessId,
        page,
        size: sizePerPage,
      }),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          setDataCategoryItem(res?.data.data || []);
          setTotalEntries(res?.data.total);
          setTotalPage(Math.ceil(res?.data.total / sizePerPage));
        }
      },
      enabled: !!userDetail?.businessId,
    }
  );

  const { isLoading: isLoadingDelete, mutate: mutateDelete } = useMutation(
    deleteCategoryItem,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          modal.dismiss();
          refetch();
          notification.success(localize.getText("successDeleteData"));
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handlePagination = (value) => {
    setPage(value);
  };

  const handleSetSizePerPage = (size) => {
    setPage(0);
    setSizePerPage(size);
  };

  return (
    <CategoryItemComponent
      dataCategoryItem={dataCategoryItem}
      isLoading={isLoading || isFetching || isLoadingDelete}
      mutateDelete={mutateDelete}
      totalPage={totalPage}
      totalEntries={totalEntries}
      activePage={page}
      handlePagination={handlePagination}
      handleSetSizePerPage={handleSetSizePerPage}
      sizePerPage={sizePerPage}
    />
  );
}
