import instance from "../config/interceptor";

export const getAnnotationPhoto = async () => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/annotation-photo`;

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const postStartVisit = async (data) => {
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/start-visit`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const postEndVisit = async ({ patientId, note, fileUrl }) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/end-visit/${patientId}`,
      { note, fileUrl }
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const postSaveVisit = async (data) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/save-visit`,
      data
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};
