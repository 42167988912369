import instance from "../config/interceptor";

export const getProfile = async () => {
  let _response;
  try {
    const response = await instance.get(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/profile`
    );
    _response = response;
  } catch (response) {
    _response = response;
  }
  return _response;
};

export const postProfile = async (data) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/profile`,
      data
    );
    _response = response;
  } catch (err) {
    _response = err;
  }
  return _response;
};
