import React from "react";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import styles from "./WelcomeOnboarding.module.css";

export default function WelcomeOnboarding() {
  const localize = useLocalization();
  return (
    <div className={styles.wrapper}>
      <div className="d-flex gap-sm-3 flex-wrap items-center justify-content-center">
        <h1 className={styles.title}>{localize.getText("welcomeTo")}</h1>
        <img
          className={styles.imageLogo}
          src="/assets/svg/logo-sistem-klinik.svg"
          alt=""
        />
      </div>
      <p className={styles.caption}>
        {localize.getText("captionOnboardWelcome")}
      </p>
    </div>
  );
}
