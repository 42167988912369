import { useEffect, useState, useRef } from "react";
import { useModal } from "../context/ModalProvider";
import { useQuery } from "@tanstack/react-query";
import ModalAddNotes from "../jsx/module/ActiveVisit/Assesment/ModalAddNotes";
import { getAnnotationPhoto } from "../services/AssesmentService";

export default function useCanvasAnatomi() {
  const [clickLayer, setClickLayer] = useState(false);
  const [selectedImage, setSelectedImage] = useState("MALE_FRONT");
  const [activeType, setActiveType] = useState("MALE");
  const [positionAnnotation, setPossitionAnnotation] = useState([]);
  const [dataAnnotation, setDataAnnotation] = useState([]);
  const [summaryAnnotation, setSummaryAnnotation] = useState("");

  const { isLoading: isLoadingAnnotation } = useQuery(
    ["AnnotationPhoto"],
    getAnnotationPhoto,
    {
      onSuccess: (res) => {
        setDataAnnotation(res.data);
      },
    }
  );

  const modal = useModal();
  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    contextRef.current = context;

    if (dataAnnotation.length > 0) {
      const imageFilter = dataAnnotation.find(
        (value) => value.subType === selectedImage
      );

      const image = new Image();
      image.src = imageFilter?.imageUrl;

      image.onload = () => {
        canvas.height = 510;
        canvas.width = 335;

        context.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
    }

    // eslint-disable-next-line
  }, [selectedImage, dataAnnotation]);

  useEffect(() => {
    const imageFilter = dataAnnotation.find(
      (value) => value.subType === `${activeType}_FRONT`
    );

    setSelectedImage(imageFilter?.subType);
  }, [activeType, dataAnnotation]);

  useEffect(() => {
    const summary = positionAnnotation.flatMap((annotation) =>
      annotation.annotationDetails.map((detail) => detail.description)
    );

    setSummaryAnnotation(summary.join(", "));
  }, [positionAnnotation]);

  const handleSetPosition = (e) => {
    const dataPosition = [...positionAnnotation];
    const index = e.index;

    const imageFilter = dataAnnotation.find(
      (value) => value.subType === selectedImage
    );
    const filterSelectedImg = dataPosition.filter(
      (value) => value.type === selectedImage
    );

    if (filterSelectedImg.length <= 0) {
      dataPosition.push({
        type: imageFilter.subType,
        photoUrl: imageFilter.imageUrl,
        annotationDetails: [
          {
            x: e.layerX,
            y: e.layerY,
            dx: e.dx || 80,
            dy: e.dy || 80,
          },
        ],
      });
    }

    const selectedPositionImg = filterSelectedImg[0];

    if (
      selectedPositionImg.annotationDetails.filter(
        (_, indexP) => indexP === index
      ).length > 0
    ) {
      const selectedPosition = selectedPositionImg.annotationDetails[index];
      selectedPosition.x = e.layerX;
      selectedPosition.y = e.layerY;
      selectedPosition.dy = e.dy || 80;
      selectedPosition.dx = e.dx || 80;
    } else {
      selectedPositionImg.annotationDetails.push({
        x: e.layerX,
        y: e.layerY,
        dx: e.dx || 80,
        dy: e.dy || 80,
      });
    }

    setPossitionAnnotation(dataPosition);
  };

  const handleAddPosition = (e) => {
    modal.show(
      () => (
        <ModalAddNotes
          handleDismisModal={() => modal.dismiss()}
          positionAnnotation={positionAnnotation}
          dataAnnotation={dataAnnotation}
          selectedImage={selectedImage}
          setPossitionAnnotation={(value) => setPossitionAnnotation(value)}
          event={e}
        />
      ),
      { centered: true }
    );
  };

  const handleChangeType = (value) => {
    setActiveType(value);
  };

  const handleUndo = () => {
    const dataPosition = [...positionAnnotation];
    const filterSelectedImg = dataPosition.find(
      (value) => value.type === selectedImage
    );

    if (filterSelectedImg?.annotationDetails.length > 0) {
      filterSelectedImg?.annotationDetails.pop();
    }

    setPossitionAnnotation(dataPosition);
  };

  const activeAnnotation =
    positionAnnotation.find((value) => value.type === selectedImage) || null;

  return {
    canvasRef,
    isLoadingAnnotation,
    activeAnnotation,
    positionAnnotation,
    dataAnatomis: dataAnnotation || [],
    handleSetPosition,
    setSelectedImage,
    handleAddPosition,
    setClickLayer,
    clickLayer,
    activeType,
    handleChangeType,
    selectedImage,
    handleUndo,
    setPossitionAnnotation,
    summaryAnnotation,
  };
}
