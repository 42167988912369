import React from "react";
import CalendarScheduleDoctor from "../../../components/CalendarScheduleDoctor";
import ModalAddReservation from "../../../components/ModalAddReservation";
import ModalDetailReservation from "../../../components/ModalDetailReservation";
import ModalStartVisit from "../../../components/ModalStartVisit";
import ModalEndVisit from "../../../components/ModalEndVisit";
import ModalAlert from "../../../components/ModalAlert";

export default function ReservationList({
  businessId = null,
  dataReservation = [],
  dataServices = [],
  reservationDate,
  setReservationDate,
  loading = false,
  isLoadingDetail = false,
  isLoadingAdd = false,
  isLoadingStartVisit = false,
  isLoadingEndVisit = false,
  showModalDetail,
  setShowModalAdd = () => undefined,
  setShowModalDetail = () => undefined,
  handleChangeStatusReservation = () => undefined,
  handleChangeForm = () => undefined,
  handleSubmitExisting = () => undefined,
  handleSubmitNew = () => undefined,
  dataDetailReservation,
  dataReservationNew,
  dataFormPatientNew,
  selectedExistingPatient,
  showModalStartVisit,
  showModalEndVisit,
  setSelectedExistingPatient = () => undefined,
  handleChangePatientReservation = () => undefined,
  handleUpdatePatientReservation = () => undefined,
  handleModalStartVisit = () => undefined,
  handleModalEndVisit = () => undefined,
  handleStartVisit = () => undefined,
  handleEndVisit = () => undefined,
  handleClickPayment = () => undefined,
  handleShowModalAlert = () => undefined,
  handleDetailModalAlert = () => undefined,
  handleYaModalAlert = () => undefined,
  showModalAlert,
  isLoadingEndToStart = false,
}) {
  return (
    <div className="card">
      <div className="card-body d-flex flex-column gap-4">
        <div className="d-flex flex-column gap-1">
          <p className="mb-0 h5">Daftar Reservasi</p>
          <p className="mb-0 small">
            Sorot jam kosong untuk menambahkan reservasi pasien
          </p>
        </div>
        <CalendarScheduleDoctor
          dataReservation={dataReservation}
          loading={loading}
          reservationDate={reservationDate}
          setReservationDate={setReservationDate}
          setShowModalAdd={setShowModalAdd}
          setShowModalDetail={setShowModalDetail}
        />
      </div>
      <ModalAddReservation
        show={!!dataReservationNew.doctorId}
        data={dataReservationNew}
        handleModal={setShowModalAdd}
        handleChangeForm={handleChangeForm}
        dataFormNew={dataFormPatientNew}
        handleSubmitExisting={handleSubmitExisting}
        handleSubmitNew={handleSubmitNew}
        dataServices={dataServices}
        loading={isLoadingAdd}
        selectedExistingPatient={selectedExistingPatient}
        setSelectedExistingPatient={setSelectedExistingPatient}
        handleModalStartVisit={handleModalStartVisit}
        handleStartVisit={handleStartVisit}
        handleShowModalAlert={handleShowModalAlert}
      />
      <ModalDetailReservation
        dataDetailReservation={dataDetailReservation}
        show={showModalDetail}
        loading={isLoadingDetail}
        dataFormNew={dataFormPatientNew}
        selectedExistingPatient={selectedExistingPatient}
        handleChangeForm={handleChangeForm}
        handleModal={setShowModalDetail}
        handleChangeStatusReservation={handleChangeStatusReservation}
        setSelectedExistingPatient={setSelectedExistingPatient}
        handleChangePatientReservation={handleChangePatientReservation}
        handleSubmitNew={handleUpdatePatientReservation}
        handleModalStartVisit={handleModalStartVisit}
        handleModalEndVisit={handleModalEndVisit}
        handleClickPayment={handleClickPayment}
        businessId={businessId}
        handleStartVisit={handleStartVisit}
        handleShowModalAlert={handleShowModalAlert}
      />
      <ModalEndVisit
        show={!!showModalEndVisit}
        patientId={showModalEndVisit?.patientId}
        visitCompleted={showModalEndVisit?.visitCompleted}
        handleHideModal={() => handleModalEndVisit(null)}
        handleConfirmModal={handleEndVisit}
        isLoading={isLoadingEndVisit}
      />
      <ModalStartVisit
        show={
          showModalStartVisit?.isPatientNew
            ? !!showModalStartVisit?.identificationNumber
            : !!showModalStartVisit?.patientId
        }
        isReservation
        isLoading={isLoadingStartVisit}
        bookingId={showModalStartVisit?.bookingId}
        patientId={showModalStartVisit?.patientId}
        doctorId={showModalStartVisit?.doctorId}
        identificationNumber={showModalStartVisit?.identificationNumber}
        handleHideModal={() => handleModalStartVisit(null)}
        handleConfirmModal={handleStartVisit}
      />
      <ModalAlert
        data={showModalAlert}
        show={!!showModalAlert}
        loading={isLoadingEndToStart}
        handleCancel={() => handleShowModalAlert(null)}
        handleDetailReservation={handleDetailModalAlert}
        handleOke={handleYaModalAlert}
        description="Pasien ini masih memiliki aktif visit, apakah anda yakin mengakhiri visit sebelumnya dan memulai aktif yang baru?"
      />
    </div>
  );
}
