import React, { useContext, createContext, useState } from "react";
import {
  getServices,
  getClinics,
  getClinicByService,
} from "../services/HomeService";
import { useQuery } from "@tanstack/react-query";
import { getParams } from "../utils/utils";
import moment from "moment";
import { getDoctorByBusinessId } from "../services/GeneralService";
import { useAuth } from "./AuthProvider";

export const HomeContext = createContext({
  dataServices: [],
  dataClinics: [],
  dataClinic: [],
  isLoadingServices: false,
  isLoadingClinics: false,
  isLoadingClinic: false,
  isLoadingDoctor: false,
  selectedService: null,
  selectedClinic: null,
  setSelectedService: () => {},
  setSelectedClinic: () => {},
  selectedServiceData: null,
  selectedClinicData: null,
  selectedTime: null,
  handleChangeReservation: () => {},
  handleResetReservation: () => {},
  dataTime: [],
  dataDoctors: [],
  dataReservation: {
    doctor: null,
    searchDateVisit: null,
    dateVisit: null,
    timeVisit: [],
    durationVisit: 15,
    identityNumber: "",
    identificationType: "",
    name: "",
    email: "",
    gender: null,
    dateOfBirth: null,
    phoneNumber: null,
    privacyPolice: false,
    createAccountFlag: false,
  },
});

export const useHome = () => useContext(HomeContext);

export default function HomeProvider({ children }) {
  const defaultDataReservation = {
    doctor: null,
    searchDateVisit: moment(),
    dateVisit: null,
    timeVisit: [],
    durationVisit: 15,
    //bio data
    identityNumber: "",
    identificationType: "KTP",
    name: "",
    email: "",
    gender: null,
    dateOfBirth: null,
    phoneNumber: null,
    privacyPolice: false,
    createAccountFlag: false,
  };
  const { userDetail } = useAuth();
  const clinicId = +getParams("clinicId") || userDetail?.businessId;

  const [sizeClinics] = useState(3);
  const [selectedService, setSelectedService] = useState(
    +getParams("serviceId") || null
  );
  const [selectedClinic, setSelectedClinic] = useState(clinicId || null);
  const [dataDoctors, setDataDoctors] = useState([]);

  const [dataReservation, setDataReservation] = useState(
    defaultDataReservation
  );

  const { isLoading: isLoadingDoctor, isFetching: isFetchingDoctor } = useQuery(
    ["getDoctorByBusinessId", clinicId],
    () => getDoctorByBusinessId(clinicId),
    {
      onSuccess: (res) => {
        const response = res.data;
        if (response && response.length > 0) {
          setDataDoctors(response);
        }
      },
      enabled: !!clinicId,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataServices, isLoading: isLoadingServices } = useQuery(
    ["getServicesHome"],
    getServices,
    { refetchOnWindowFocus: false }
  );

  const { data: dataClinic, isLoading: isLoadingClinic } = useQuery(
    ["getClinicByServicesHome", selectedService],
    () => getClinicByService(selectedService),
    {
      enabled: !!selectedService,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataClinics, isLoading: isLoadingClinics } = useQuery(
    ["getClinicsHome", sizeClinics],
    getClinics,
    { refetchOnWindowFocus: false }
  );

  const selectedServiceData =
    (selectedService &&
      dataServices?.data?.find((value) => value.id === selectedService)) ||
    null;

  const selectedClinicData =
    (selectedClinic &&
      dataClinics?.status === 200 &&
      dataClinics?.data?.find((value) => value.id === selectedClinic)) ||
    null;

  const handleChangeReservation = (name, value) => {
    setDataReservation((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleResetReservation = () => {
    setDataReservation(defaultDataReservation);
  };

  const response = {
    dataServices: dataServices?.data || [],
    dataClinics: dataClinics?.data || [],
    dataClinic: dataClinic?.data || [],
    dataDoctors,
    selectedService,
    selectedClinic,
    isLoadingServices,
    isLoadingClinics,
    isLoadingClinic,
    isLoadingDoctor: isLoadingDoctor || isFetchingDoctor,
    setSelectedService,
    setSelectedClinic,
    selectedServiceData,
    selectedClinicData,
    handleChangeReservation,
    dataReservation,
    handleResetReservation,
  };

  return (
    <HomeContext.Provider value={{ ...response }}>
      {children}
    </HomeContext.Provider>
  );
}
