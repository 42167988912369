import instance from "../../config/interceptor";
import { PER_PAGE_SIZE } from "../../utils/constants";

export const postOrganization = async (data) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/satu-sehat/organization`,
      data
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getOrganizations = async ({ page = 1, size = PER_PAGE_SIZE }) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/satu-sehat/organization?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getOrganizationDetail = async (id) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/satu-sehat/organization/${id}`;

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};
