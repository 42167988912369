import React, { useContext, createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getPath, ROOT_DASHBOARD } from "../utils/constants";
import {
  setItemToStorage,
  getItemStorage,
  clearItemStorage,
  decodeJwtToken,
} from "../utils/utils";
import LoadingSection from "../jsx/components/LoadingSection";
import { getAccountContent } from "../services/AccountService";
import { useQuery } from "@tanstack/react-query";
import { homePath } from "../utils/constants";

export const AuthContext = createContext({
  userDetail: null,
  role: null,
  isAuthenticated: false,
  isLoadingRefresh: false,
  logout: () => {},
  login: () => {},
  refetchAuth: () => {},
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const path = getPath();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const {
    data: dataAccount,
    isLoading: isLoadingAccount,
    refetch,
  } = useQuery(["getAccountContent"], getAccountContent, {
    enabled: !!isAuthenticated,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const userDetail = getItemStorage("userDetails");

    if (userDetail) {
      setIsAuthenticated(true);
    } else if (!userDetail && !homePath.includes(path)) {
      history.push("/login");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      dataAccount?.data.onboardingCompleted === false &&
      path !== "onboarding"
    ) {
      history.push("/onboarding");
    }

    //DY: LATER CHANGE TO DASHBOARD
    // if (path === "") {
    //   window.location.href = "/active-visit";
    // }
    // eslint-disable-next-line
  }, [dataAccount]);

  useEffect(() => {
    if (
      dataAccount?.data.onboardingCompleted === true &&
      path === "onboarding"
    ) {
      history.push(ROOT_DASHBOARD);
    }

    // eslint-disable-next-line
  }, [dataAccount]);

  const handleLogout = () => {
    clearItemStorage();
    setIsAuthenticated(false);
    history.push("/login");
  };

  const handleLogin = (tokenDetails) => {
    setItemToStorage("userDetails", tokenDetails);
    setIsAuthenticated(true);

    if (tokenDetails.onboardingCompleted === false) {
      history.push("/onboarding");
      return;
    }

    //DY: LATER CHANGE TO DASHBOARD
    history.push(ROOT_DASHBOARD);
  };
  const userDetail = getItemStorage("userDetails");
  const role = userDetail
    ? decodeJwtToken(userDetail?.id_token).payload?.auth
    : null;

  const auth = {
    userDetail: dataAccount?.data,
    role,
    isAuthenticated,
    logout: handleLogout,
    login: handleLogin,
    refetchAuth: refetch,
  };

  if (isLoadingAccount) {
    <LoadingSection isPage />;
  }

  return (
    <AuthContext.Provider value={{ ...auth }}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
