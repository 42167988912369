import React from "react";

export default function CheckboxField({
  label,
  value,
  onChange = () => undefined,
  onBlur = () => undefined,
  error,
  id = "",
}) {
  return (
    <div className="form-group">
      <div className="custom-control custom-checkbox ml-1 d-flex gap-2">
        <input
          type="checkbox"
          className="form-check-input"
          id={id}
          onChange={() => onChange(!value)}
          onBlur={onBlur}
          checked={value}
          value={value}
        />
        <label className="form-check-label flex-1" htmlFor={id}>
          {label}
        </label>
      </div>
      {error && (
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: "block" }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
