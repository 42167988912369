import React from "react";
import styles from "./Card.module.css";

export default function Card({ children, title, header }) {
  return (
    <div className="card">
      {(title || header) && (
        <div className="card-header">
          {title && <p className={styles.title}>{title}</p>}
          {header}
        </div>
      )}
      <div className="card-body">{children}</div>
    </div>
  );
}
