import React from "react";
import Button from "../Button";
import styles from "./ModalService.module.css";

export default function ModalService({
  data = [],
  handleSelect = () => undefined,
}) {
  return (
    <div className={styles.wrapperItem}>
      {data.map((item, index) => (
        <div className={styles.wrapperItemList} key={index}>
          <p className={styles.textService}>{item.name}</p>
          <Button
            label="Pilih"
            variant="outline-primary"
            className={styles.actionService}
            onClick={() => handleSelect(item.id)}
          />
        </div>
      ))}
    </div>
  );
}
