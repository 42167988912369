import React, { useEffect } from "react";
import { ROOT_DASHBOARD } from "../../../utils/constants";
import { useHistory } from "react-router-dom";

export default function NotFound() {
  const history = useHistory();

  useEffect(() => {
    history.push(ROOT_DASHBOARD);
  }, [history]);
  return <div></div>;
}
