import React from "react";
import FormItem from "../FormItem";

export default function AddItemComponent({ handleSubmit, isLoadingSubmit }) {
  return (
    <FormItem
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={{}}
    />
  );
}
