import React, { forwardRef } from "react";
import cx from "classnames";
import styles from "./PrintReportPdf.module.css";
import { formatNumberWithCurrency } from "../../../utils/utils";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import { typeDiscount, typeDiscountObj } from "../../../utils/constants";

const PrintReportPdf = forwardRef(({ data }, ref) => {
  const localize = useLocalization();
  const dataHistoryPaymentTotal =
    data?.filter(
      (item, index, self) =>
        item.id === null || index === self.findIndex((t) => t.id === item.id)
    ) || [];
  const totalPrice = dataHistoryPaymentTotal.reduce((total, item) => {
    return total + (item.total_amount || 0);
  }, 0);

  return (
    <div ref={ref} className={cx(styles.tableContainer)}>
      <table className={styles.paymentTable}>
        <thead>
          <tr>
            <th>{localize.getText("idPayment")}</th>
            <th>{localize.getText("namePatient")}</th>
            <th>{localize.getText("doctor")}</th>
            <th>{localize.getText("nameItem")}</th>
            <th>{localize.getText("priceItem")}</th>
            <th>{localize.getText("quantity")}</th>
            <th>{localize.getText("datePayment")}</th>
            <th>{localize.getText("paymentMethod")}</th>
            <th>{localize.getText("paymentType")}</th>
            <th>{localize.getText("totalPayment")}</th>
            <th>{localize.getText("discountType")}</th>
            <th>{localize.getText("discount")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.payment_id}</td>
              <td>{item.user_name || "-"}</td>
              <td>{item.doctor_name || "-"}</td>
              <td>{item.name_item}</td>
              <td>{formatNumberWithCurrency(item?.amount_item || 0)}</td>
              <td>{item.quantity}</td>
              <td>{item.payment_date}</td>
              <td>{item.payment_method}</td>
              <td>{item.payment_type}</td>
              <td>{formatNumberWithCurrency(item?.total_amount || 0)}</td>
              <td>
                {typeDiscount.find((type) => type.value === item.type_discount)
                  ?.label || "-"}
              </td>
              <td>
                {item.type_discount === typeDiscountObj.NUMBER
                  ? formatNumberWithCurrency(item.discount)
                  : item.discount || "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.wrapperTotalpayment}>
        <p>{localize.getText("totalPayment")}</p>
        <p>{formatNumberWithCurrency(totalPrice)}</p>
      </div>
    </div>
  );
});

export default PrintReportPdf;
