/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { getPath, SidebarMenu } from "../../../utils/constants";
import { isMobile } from "react-device-detect";
//import LogoutPage from './Logout';

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const path = getPath();

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);
  let scrollPosition = useScrollPosition();

  const handleMinimizeMenu = () => {
    if (isMobile) {
      document.getElementById("nav-burger").click();
    }
  };

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          {SidebarMenu().map((item, index) => (
            <li
              key={index}
              className={`${
                item.allowActive.includes(path) ? "mm-active" : ""
              }`}
              onClick={handleMinimizeMenu}
            >
              <Link to={item.url} className="ai-icon">
                <i className={item.icon}></i>
                <span className="nav-text">{item.label}</span>
              </Link>
              {item?.subMenu.length > 0 && (
                <ul>
                  {item.subMenu.map((itemSub, indexSub) => (
                    <li key={indexSub} style={{ fontWeight: 500 }}>
                      <Link
                        className={`${
                          "/" + path === itemSub.url ? "mm-active" : ""
                        }`}
                        to={itemSub.url}
                      >
                        {itemSub.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
