import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getLocations } from "../../services/SatuSehat/LocationService";

export default function useLocation() {
  const [dataLocations, setDataLocations] = useState([]);

  const { isLoading } = useQuery(["LocationListHooks"], getLocations, {
    onSuccess: (data) => {
      if (data.status === 200 || data.status === 201) {
        const datas = [];
        data?.data?.entry?.forEach((item) => {
          datas.push(item.resource);
        });
        setDataLocations(datas);
      }
    },
  });

  return {
    dataLocations,
    isLoading,
  };
}
