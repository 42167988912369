import React, { createContext, useContext } from "react";
import { useSnackbar } from "notistack";

export const NotificationContext = createContext({
  default: () => {
    // DO NOTHING
  },
  success: () => {
    // DO NOTHING
  },
  info: () => {
    // DO NOTHING
  },
  error: () => {
    // DO NOTHING
  },
  // eslint-enable
});

export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleShowMessage = (variant) => (message, options) => {
    enqueueSnackbar(message, { ...(options || {}), variant });
  };

  const response = {
    default: handleShowMessage("default"),
    success: handleShowMessage("success"),
    info: handleShowMessage("info"),
    error: handleShowMessage("error"),
  };

  return (
    <NotificationContext.Provider value={response}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.defaultProps = {};

export default NotificationProvider;
