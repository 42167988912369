import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import ListPatientComponent from "../Component";
import usePatientActive from "../../../../../hooks/usePatientActive";
import { retrieveUrlFile } from "../../../../../utils/utils";
import ModalEndVisit from "../../../../components/ModalEndVisit";
import { endVisitReservation } from "../../../../../services/Handlers/ReservationService";

export default function ListActiveVisit() {
  const notification = useNotification();
  const localize = useLocalization();
  const {
    page,
    totalPage,
    totalEntries,
    dataPatient,
    isLoading,
    isRefetching,
    sizePerPage,
    setSizePerPage,
    refetch,
    handlePagination,
    handleSetFilter,
  } = usePatientActive();
  const [showEndVisit, setShowEndVisit] = useState(null);

  const { isLoading: isLoadingEnd, mutate: mutateEndVisit } = useMutation(
    endVisitReservation,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("endVisitSuccess"));
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
        refetch();
        setShowEndVisit(null);
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleConfirmEndVisit = async (value) => {
    let imageUrl = "";
    if (value.fileUrl) {
      imageUrl = await retrieveUrlFile(value.fileUrl);
    }

    mutateEndVisit({
      note: value?.note,
      fileUrl: imageUrl,
      status: "DONE",
      patient_id: value.patientId,
      booking_id: null,
    });
  };

  const handleEndVisit = (item) => {
    setShowEndVisit(item);
  };

  return (
    <>
      <ListPatientComponent
        dataPatient={dataPatient}
        isLoading={isLoading || isRefetching}
        isLoadingEnd={isLoadingEnd}
        totalPage={totalPage}
        totalEntries={totalEntries}
        activePage={page}
        sizePerPage={sizePerPage}
        setSizePerPage={setSizePerPage}
        handlePagination={handlePagination}
        mutateEndVisit={handleEndVisit}
        handleSetFilter={handleSetFilter}
      />
      <ModalEndVisit
        patientId={showEndVisit?.id}
        visitCompleted={showEndVisit?.visitCompleted}
        show={!!showEndVisit}
        handleHideModal={() => setShowEndVisit(null)}
        handleConfirmModal={handleConfirmEndVisit}
        isLoading={isLoadingEnd}
      />
    </>
  );
}
