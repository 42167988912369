import React from "react";
import { AnnotationLabel } from "react-annotation";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function MyAnnotation({
  canvasRef,
  positionAnnotation,
  handleSetPosition,
  handleAddPosition,
  setClickLayer,
  clickLayer,
  isView = false,
}) {
  return (
    <TransformWrapper panning={{ disabled: true }}>
      <TransformComponent>
        <div
          style={{
            position: "relative",
            border: "solid 1px #F0F0F0",
            borderRadius: 10,
            zIndex: 0,
          }}
        >
          <canvas
            ref={canvasRef}
            onClick={(e) => {
              if (!isView) {
                handleAddPosition({
                  layerX: e.nativeEvent.offsetX,
                  layerY: e.nativeEvent.offsetY,
                });
              }
            }}
          />
          {positionAnnotation?.annotationDetails?.length > 0 && (
            <svg
              width="100%"
              height="100%"
              style={{ position: "absolute", top: 0, left: 0 }}
              onClick={(e) => {
                if (!clickLayer && !isView) {
                  handleAddPosition({
                    layerX: e.nativeEvent.offsetX,
                    layerY: e.nativeEvent.offsetY,
                  });
                }

                setClickLayer(false);
              }}
            >
              {positionAnnotation.annotationDetails.map((item, index) => (
                <g key={index}>
                  <AnnotationLabel
                    canvas={canvasRef.current}
                    x={item.x}
                    y={item.y}
                    dy={item.dy}
                    dx={item.dx}
                    color={"#9610ff"}
                    editMode={true}
                    note={{
                      title: item.title,
                      label: item.description,
                      align: "middle",
                      orientation: "topBottom",
                      bgPadding: 20,
                      padding: 15,
                      titleColor: "#59039c",
                      cursor: "pointer",
                    }}
                    onDragEnd={(e) => {
                      if (!isView) {
                        setClickLayer(true);
                        handleSetPosition({
                          layerX: e.x,
                          layerY: e.y,
                          dy: e.dy,
                          dx: e.dx,
                          index: index,
                        });
                      }
                    }}
                  />
                </g>
              ))}
            </svg>
          )}
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
}

export default MyAnnotation;
