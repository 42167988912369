import React from "react";
import classNames from "classnames";

export default function SelectField({
  label,
  data,
  value = "",
  placeholder,
  onChange = () => undefined,
  onBlur = () => undefined,
  error,
  disabled = false,
  fullWidth = false,
  isBorder = false,
  valueKey = "id",
  labelKey = "label",
  style,
}) {
  return (
    <div className={classNames("form-group", fullWidth && "w-100")}>
      {label && (
        <label className="mb-1 ">
          <strong>{label}</strong>
        </label>
      )}
      <select
        value={value}
        className="form-control"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        disabled={disabled}
        style={{
          ...style,
          ...{
            ...(isBorder && {
              border: "0.5px solid var(--primary)",
              height: 38,
            }),
          },
        }}
      >
        <option value="" disabled selected>
          {placeholder}
        </option>
        {data?.map((item, index) => (
          <option value={item[valueKey]} key={index}>
            {item[labelKey]}
          </option>
        ))}
      </select>
      {error && (
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: "block" }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
