import React from "react";
import Table from "../../../../components/Table";
import Button from "../../../../components/Button";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { Link } from "react-router-dom";

export default function OrganizationComponent({
  dataOrganizations,
  isLoading,
}) {
  const localize = useLocalization();
  const columns = [
    { label: localize.getText("id") },
    { label: localize.getText("name") },
    {
      label: localize.getText("action"),
      className: "text-center",
      style: { width: "200px" },
    },
  ];

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-end">
        <Link to="/organization/add-organization">
          <Button label={localize.getText("addOrganization")} size="btn-sm" />
        </Link>
      </div>
      <div className="card-body">
        <Table
          columns={columns}
          loading={isLoading}
          activePage={0}
          totalPage={0}
          totalShow={dataOrganizations.length}
          totalEntries={dataOrganizations.length}
          handlePagination={() => {}}
          noPagination
        >
          {dataOrganizations.map((item, index) => (
            <tr key={index}>
              <td className="sorting_1">{item.resource.id}</td>
              <td>{item.resource.name}</td>
              <td>
                <div className="d-flex justify-content-center gap-3">
                  {/* <Button variant="danger">
                      <i class="fas fa-trash"></i>
                    </Button> */}
                  <Link
                    to={{
                      pathname: "/organization/detail-organization",
                      search: `?id=${item.resource.id}`,
                    }}
                  >
                    <Button variant="warning">
                      <i class="fas fa-eye"></i>
                    </Button>
                  </Link>
                  <Link
                    to={{
                      pathname: "/organization/edit-organization",
                      search: `?id=${item.resource.id}`,
                    }}
                  >
                    <Button variant="info">
                      <i class="fas fa-edit"></i>
                    </Button>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
