import React from "react";
import { Tabs as TabsRB, Tab } from "react-bootstrap";

export default function Tabs({ items }) {
  return (
    <div className="d-flex flex-column gap-4 w-100">
      <TabsRB id="uncontrolled-tab-example" style={{ width: "100%" }}>
        {items.map((tab) => (
          <Tab
            eventKey={tab.key}
            title={tab.label}
            key={tab.key}
            className="w-100"
          >
            {tab.children}
          </Tab>
        ))}
      </TabsRB>
    </div>
  );
}
