import React from "react";
import { useNotification } from "../../../../context/NotificationProvider";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import { useMutation } from "@tanstack/react-query";
import { changePassword } from "../../../../services/AccountService";
import ChangePasswordComponent from "../Component";

export default function ChangePassword() {
  const notification = useNotification();
  const localize = useLocalization();

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    changePassword,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successChangePassword"));
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleSubmitForm = (value) => {
    mutateSubmit({
      currentPassword: value.currentPassword,
      newPassword: value.newPassword,
    });
  };

  return (
    <ChangePasswordComponent
      handleSubmitForm={handleSubmitForm}
      isLoadingSubmit={isLoadingSubmit}
    />
  );
}
