import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getVisitHistory } from "../../../../../services/HistoryService";
import { getPatientSingle } from "../../../../../services/PatientService";
import { getParams } from "../../../../../utils/utils";
import { PER_PAGE_SIZE } from "../../../../../utils/constants";
import HistoryVisitComponent from "../Component";

export default function HistoryVisit() {
  const patientId = getParams("id");

  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [dataHistory, setDataHistory] = useState([]);

  const { isLoading, isRefetching } = useQuery(
    ["HistoryPatientList", patientId, page],
    () => getVisitHistory({ page, patientId }),
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          setDataHistory(data?.data || []);
          setTotalEntries(Number(data.headers["x-total-count"]));
          setTotalPage(
            Math.ceil(Number(data.headers["x-total-count"]) / PER_PAGE_SIZE)
          );
        }
      },
    }
  );

  const { isLoading: isLoadingPatient, data: dataPatient } = useQuery(
    ["SinglePatient", patientId],
    () => getPatientSingle(patientId)
  );

  const handlePagination = (value) => {
    setPage(value);
  };

  return (
    <HistoryVisitComponent
      dataHistory={dataHistory}
      isLoading={isLoading || isRefetching || isLoadingPatient}
      totalPage={totalPage}
      totalEntries={totalEntries}
      activePage={page}
      handlePagination={handlePagination}
      itemData={dataPatient?.data || null}
    />
  );
}
