import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
//import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import LocalizationProvider from "./context/LocalizeContextProvider";
import NotificationProvider from "./context/NotificationProvider";
import ModalProvider from "./context/ModalProvider";
import AuthProvider from "./context/AuthProvider";
import HomeProvider from "./context/HomeProvider";
import "./i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";

import { LocalizationProvider as LocalizationMaterialProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ScrollToTop from "./jsx/components/ScrollToTop";
import moment from "moment";
import "moment/locale/id";

moment.locale("id");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename="/dashboard">
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            autoHideDuration={2500}
          >
            <LocalizationMaterialProvider dateAdapter={AdapterMoment}>
              <AuthProvider>
                <LocalizationProvider>
                  <NotificationProvider>
                    <HomeProvider>
                      <ModalProvider>
                        <ThemeContext>
                          <ScrollToTop />
                          <App />
                        </ThemeContext>
                      </ModalProvider>
                    </HomeProvider>
                  </NotificationProvider>
                </LocalizationProvider>
              </AuthProvider>
            </LocalizationMaterialProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
