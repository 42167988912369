import React from "react";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import styles from "./SuccessOnboarding.module.css";

export default function SuccessOnboarding() {
  const localize = useLocalization();
  return (
    <div className={styles.wrapper}>
      <div className="d-flex gap-sm-3 flex-wrap items-center justify-content-center">
        <h1 className={styles.title}>{localize.getText("allDone")}</h1>
        <img
          className={styles.imageLogo}
          src="/assets/svg/logo-sistem-klinik.svg"
          alt=""
        />
      </div>
      <p className={styles.caption}>{localize.getText("captionOnboardDone")}</p>
    </div>
  );
}
