import React from "react";
import Modal from "../Modal";
import Spinner from "../Spinner";
import Button from "../Button";

export default function ModalAlert({
  show = false,
  handleOke = () => undefined,
  handleCancel = () => undefined,
  handleDetailReservation = () => undefined,
  title = "Pemberitahuan",
  description,
  loading = false,
  data,
}) {
  return (
    <Modal visible={show} isForce centered>
      <Spinner loading={loading}>
        <div className="d-flex flex-column align-items-center gap-4">
          <div className="d-flex flex-column align-items-center gap-2">
            <i
              className="fas fa-info-circle text-warning"
              style={{ fontSize: 64 }}
            />
            <p className="mb-0 h3">{title}</p>
            {description && <p className="mb-0 text-center">{description}</p>}
          </div>

          <div className="d-flex align-items-center gap-2">
            <Button
              label="Batal"
              variant="outline-primary"
              onClick={handleCancel}
            />
            {data?.newReservation?.bookingId && (
              <Button
                label="Lihat Reservasi"
                variant="info"
                onClick={handleDetailReservation}
              />
            )}
            <Button label="Ya" onClick={handleOke} />
          </div>
        </div>
      </Spinner>
    </Modal>
  );
}
