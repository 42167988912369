import React, { useState } from "react";
import { getStafAll } from "../../../../../services/StafService";
import { useQuery } from "@tanstack/react-query";
import ListStafClinicComponent from "../Component";
import { PER_PAGE_SIZE } from "../../../../../utils/constants";

export default function ListStafClinic() {
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [dataStaf, setDataStaf] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE_SIZE);

  const { isLoading } = useQuery(
    ["StafList", page, sizePerPage],
    () => getStafAll({ page, size: sizePerPage }),
    {
      onSuccess: (data) => {
        if (data.status === 200 || data.status === 201) {
          setDataStaf(data?.data);
          setTotalEntries(Number(data.headers["x-total-count"]));
          setTotalPage(
            Math.ceil(Number(data.headers["x-total-count"]) / sizePerPage)
          );
        }
      },
    }
  );

  const handlePagination = (value) => {
    setPage(value);
  };

  const handleSetSizePerPage = (size) => {
    setPage(0);
    setSizePerPage(size);
  };

  return (
    <ListStafClinicComponent
      dataStaf={dataStaf}
      isLoading={isLoading}
      totalPage={totalPage}
      totalEntries={totalEntries}
      activePage={page}
      handlePagination={handlePagination}
      handleSetSizePerPage={handleSetSizePerPage}
      sizePerPage={sizePerPage}
    />
  );
}
