import React from "react";
import InputField from "../../../components/InputField";
import AsyncSelect from "../../../components/AsyncSelect";
import { Form, Field } from "react-final-form";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import styles from "./StepTwoOnboarding.module.css";

export default function StepTwoOnboarding({
  valueFormStepTwo,
  handleChangeFormStepTwo,
  dataServices,
  dataBusiness,
  isLoadingService = false,
  isLoadingBusiness = false,
}) {
  const localize = useLocalization();
  return (
    <div className={styles.wrapper}>
      <div>
        <h1 className={styles.titleStep}>
          {localize.getText("stepNumber", { number: 2 })}
        </h1>
        <p className={styles.descStep}>{localize.getText("descStepTwo")}</p>
      </div>

      <Form
        onSubmit={(value) => console.log(value, "DY: this is value")}
        initialValues={{
          businessName: valueFormStepTwo.businessName,
          businessType: valueFormStepTwo.businessType,
          businessService: valueFormStepTwo.businessService,
          businessAddress: valueFormStepTwo.businessAddress,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.businessName) {
            errors.businessName = localize.getText(
              "generalRequiredFieldValidation",
              { fieldName: localize.getText("businessName") }
            );
          }
          if (!values.businessType) {
            errors.businessType = localize.getText(
              "generalRequiredFieldValidation",
              { fieldName: localize.getText("businessType") }
            );
          }
          if (values.businessService.length === 0) {
            errors.businessService = localize.getText(
              "generalRequiredFieldValidation",
              { fieldName: localize.getText("businessService") }
            );
          }
          if (!values.businessAddress) {
            errors.businessAddress = localize.getText(
              "generalRequiredFieldValidation",
              { fieldName: localize.getText("businessAddress") }
            );
          }

          return errors;
        }}
      >
        {() => (
          <>
            <form className={styles.wrapperForm}>
              <Field name="businessName">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("businessName")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("businessName"),
                    })}
                    error={meta.touched && meta.error}
                    onChange={(value) =>
                      handleChangeFormStepTwo("businessName", value)
                    }
                    onBlur={input.onBlur}
                    value={input.value}
                  />
                )}
              </Field>
              <Field name="businessType">
                {({ input, meta }) => (
                  <AsyncSelect
                    label={localize.getText("businessType")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("businessType"),
                    })}
                    error={meta.touched && meta.error}
                    onChange={(value) =>
                      handleChangeFormStepTwo("businessType", value)
                    }
                    onBlur={input.onBlur}
                    value={input.value}
                    options={dataBusiness}
                    isLoading={isLoadingBusiness}
                  />
                )}
              </Field>
              <Field name="businessService">
                {({ input, meta }) => (
                  <AsyncSelect
                    label={localize.getText("businessService")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("businessService"),
                    })}
                    error={meta.touched && meta.error}
                    onChange={(value) =>
                      handleChangeFormStepTwo("businessService", value)
                    }
                    onBlur={input.onBlur}
                    value={input.value}
                    options={dataServices}
                    isMulti
                    isLoading={isLoadingService}
                  />
                )}
              </Field>
              <Field name="businessAddress">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("businessAddress")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("businessAddress"),
                    })}
                    type="textarea"
                    error={meta.touched && meta.error}
                    onChange={(value) =>
                      handleChangeFormStepTwo("businessAddress", value)
                    }
                    onBlur={input.onBlur}
                    value={input.value}
                  />
                )}
              </Field>
            </form>
          </>
        )}
      </Form>
    </div>
  );
}
