import React, { forwardRef, useEffect, useState } from "react";
import moment from "moment";
import { formatNumberWithCurrency } from "../../../utils/utils";
import { typeDiscountObj, paymentItemType } from "../../../utils/constants";
import styles from "./PrintInvoice.module.css";
import cx from "classnames";

const PrintInvoice = forwardRef((props, ref) => {
  const [productItems, setProductItems] = useState([]);
  const PrintContentLabel = ({ label, value, isSmall = false }) => {
    return (
      <div className="d-flex">
        <div
          className={
            isSmall ? styles.labelTitleInvoiceSmall : styles.labelTitleInvoice
          }
        >
          <p>{label}</p>
        </div>
        <div className="">
          <p>: {value}</p>
        </div>
      </div>
    );
  };

  const PrintContentCounted = ({ label, value }) => {
    return (
      <div className="d-flex w-100">
        <div style={{ width: widthBlank }}></div>
        <div style={{ width: widthSubTotal }}>
          <p style={{ fontWeight: 600 }}>{label} :</p>
        </div>
        <div style={{ width: widthValueSubTotal }}>
          <p style={{ fontWeight: 600 }}>{formatNumberWithCurrency(value)}</p>
        </div>
      </div>
    );
  };

  const formatProductItems = (items) => {
    const formatted = {};

    items.forEach((item) => {
      const categoryKey = item.category_name;
      if (!formatted[categoryKey]) {
        formatted[categoryKey] = {
          nameCategory: categoryKey,
          subTotal: 0,
          products: [],
        };
      }

      // Calculate discount based on type without considering quantity
      let finalAmount = item.amount;
      if (item.type_discount === "NUMBER") {
        finalAmount -= parseFloat(item.discount);
      } else if (item.type_discount === "PERCENTAGE") {
        finalAmount -= (finalAmount * parseFloat(item.discount)) / 100;
      }

      formatted[categoryKey].subTotal += finalAmount;
      formatted[categoryKey].products.push({
        name: item.item_name_original || item.item_name,
        qty: item.quantity,
        amount: item.amount,
        typeDiscount: item.type_discount,
        discount: item.discount,
      });
    });

    return Object.values(formatted);
  };

  useEffect(() => {
    if (
      props.dataDetailReservation &&
      props.dataDetailReservation?.payment &&
      productItems.length === 0
    ) {
      const formatProduct = formatProductItems(
        props.dataDetailReservation?.payment?.payment_items.filter(
          (item) => item.type === paymentItemType.CONSULTATION
        )
      );

      setProductItems(formatProduct);
    }
    // eslint-disable-next-line
  }, [props?.dataDetailReservation]);

  const widthItemCode = "15%";
  const widthItemDescription = "55%";
  const widthItemQty = "15%";
  const widthItemValue = "15%";

  const widthBlank = "50%";
  const widthSubTotal = "20%";
  const widthValueSubTotal = "30%";

  const dataReservation = props?.dataDetailReservation;
  return (
    <div ref={ref} className={styles.wrapperBackground}>
      <div className={styles.wrapperBody}>
        {/* <p>KOP SURAT KLINIK</p> */}
        <div className="d-flex justify-content-center">
          <p>BUKTI PEMBAYARAN</p>
        </div>
        <div>
          <div className={styles.borderItem}>
            <div className="d-flex">
              <div className={styles.wrapperContentInvoice}>
                <PrintContentLabel label="Pemjamin" value="Pribadi" />
                <PrintContentLabel
                  label="Kasir"
                  value={dataReservation?.payment?.updated_by}
                />
              </div>
              <div className={styles.wrapperContentInvoice}>
                <PrintContentLabel
                  label="No. Invoice"
                  value={dataReservation?.payment?.no_invoice}
                />
                <PrintContentLabel
                  label="Tgl. Invoice"
                  value={moment(dataReservation?.payment?.created_at).format(
                    "DD MMMM YYYY, HH:mm"
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.borderBottomItem}>
            <div className="d-flex">
              <div className={styles.wrapperContentInvoice}>
                <PrintContentLabel
                  label="Nama Pasien"
                  value={dataReservation?.name}
                />
                <PrintContentLabel
                  label="Tgl. Konsultasi"
                  value={dataReservation?.reservationDate}
                />
              </div>
              <div className={styles.wrapperContentInvoice}>
                <PrintContentLabel
                  label="No. Rekam Medis"
                  value={props?.noRm}
                />
                <PrintContentLabel
                  label="Tgl. Lahir"
                  value={dataReservation?.dateOfBirth}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section-item">
          <div className={styles.borderItem}>
            <div className="d-flex title-item">
              <p style={{ width: widthItemDescription }}>Keterangan</p>
              <p style={{ width: widthItemQty }}>Jumlah</p>
              <p style={{ width: widthItemValue }}>Nilai</p>
              <p style={{ width: widthItemCode }}>Discount</p>
            </div>
          </div>
          <div>
            {productItems.map((item, categoryIndex) => (
              <div className={styles.wrapperItemCategory} key={categoryIndex}>
                {item.products.map((product, index) => (
                  <div className="d-flex" key={index}>
                    <div
                      className="product-item"
                      style={{ width: widthItemDescription }}
                    >
                      <p>{product.name}</p>
                    </div>
                    <div
                      className={styles.contentItem}
                      style={{ width: widthItemQty }}
                    >
                      <p>{product.qty}</p>
                    </div>
                    <div
                      className={styles.contentItem}
                      style={{ width: widthItemValue }}
                    >
                      <p>
                        {product.amount &&
                          formatNumberWithCurrency(product.amount)}
                      </p>
                    </div>
                    <div
                      className={styles.contentItem}
                      style={{ width: widthItemCode }}
                    >
                      <p>
                        {product.typeDiscount === typeDiscountObj.NUMBER
                          ? formatNumberWithCurrency(product.discount)
                          : product.discount
                          ? `${product.discount}%`
                          : ""}
                      </p>
                    </div>
                  </div>
                ))}
                <div className={styles.borderBottomItem}>
                  <PrintContentCounted
                    label="Sub Total"
                    value={item.subTotal}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className={cx("d-flex flex-column", styles.borderBottomItem)}>
            <PrintContentCounted
              label="Total Biaya"
              value={dataReservation?.payment?.total_amount || 0}
            />
          </div>
        </div>
        <ul className={styles.userAggrement}>
          <li>
            Barang yang sudah dibeli tidak dapat ditukar atau dikembalikan
          </li>
          <li>Harga sudah termasuk PPN</li>
        </ul>
      </div>
    </div>
  );
});

export default PrintInvoice;
