import React, { useState } from "react";
import DetailLocationComponent from "../../components/DetailLocation";
import { useQuery } from "@tanstack/react-query";
import { getLocationDetail } from "../../../../../../services/SatuSehat/LocationService";
import {
  getParams,
  satuSehatLocFindAddressExtentionRes,
  satuSehatFindTelecomRes,
} from "../../../../../../utils/utils";
import LoadingSection from "../../../../../components/LoadingSection";

export default function DetailLocation() {
  const id = getParams("id");

  const [initialValues, setInitialValues] = useState({});

  const { isLoading } = useQuery(
    ["LocationDetail"],
    () => getLocationDetail(id),
    {
      enabled: !!id,
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          setInitialValues({
            name: response.name,
            description: response.description,
            email: satuSehatFindTelecomRes(response, "email"),
            phoneNumber: satuSehatFindTelecomRes(response, "phone"),
            website: satuSehatFindTelecomRes(response, "url"),
            address: response.address.line[0],
            city: satuSehatLocFindAddressExtentionRes(response, "city"),
            postalCode: response.address.postalCode,
            province: satuSehatLocFindAddressExtentionRes(response, "province"),
            district: satuSehatLocFindAddressExtentionRes(response, "district"),
            village: satuSehatLocFindAddressExtentionRes(response, "village"),
            rt: satuSehatLocFindAddressExtentionRes(response, "rt"),
            rw: satuSehatLocFindAddressExtentionRes(response, "rw"),
          });
        }
      },
    }
  );

  if (isLoading) {
    <LoadingSection />;
  }

  return <DetailLocationComponent initialValues={initialValues} />;
}
