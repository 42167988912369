import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import styles from "./FilterPatient.module.css";
import InputField from "../InputField";
import Button from "../Button";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function FilterPatient({
  isLoadingSearch = false,
  isAddButton = false,
  isNoReset = false,
  handleSetFilter,
  defaultForm,
}) {
  const localize = useLocalization();
  const history = useHistory();

  const [searchName, setSearchName] = useState("");
  const [searchNoTelp, setSearchNoTelp] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const { windowWidth } = useContext(ThemeContext);
  const isMobile = windowWidth <= 768;

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      handleSetFilter(searchName, searchNoTelp, searchDate, searchAddress);
    }, 1000);

    return () => {
      clearTimeout(typingTimeout);
    };
    // eslint-disable-next-line
  }, [searchName, searchNoTelp, searchDate, searchAddress]);

  useEffect(() => {
    if (defaultForm) {
      const { name, phoneNumber, date } = defaultForm;

      if (name && name !== searchName) {
        setSearchName(name);
      }
      if (phoneNumber) {
        setSearchNoTelp(phoneNumber);
      }
      if (date) {
        setSearchDate(moment(date, "DD-MMM-YYYY").format("YYYY-MM-DD"));
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleReset = () => {
    setSearchName("");
    setSearchNoTelp("");
    setSearchDate("");
    setSearchAddress("");
    handleSetFilter("", "", "", "");
  };

  return (
    <>
      <div className={styles.wrapperFilter}>
        <div className={styles.wrapperInput}>
          <InputField
            placeholder={localize.getText("placeholderSearch", {
              field: localize.getText("name"),
            })}
            isBorder
            value={searchName}
            onChange={(value) => setSearchName(value)}
            classNameInput={styles.inputStyle}
            fullWidth={isMobile}
          />
          <InputField
            placeholder={localize.getText("placeholderSearch", {
              field: localize.getText("phoneNumber"),
            })}
            type="number"
            isBorder
            value={searchNoTelp}
            onChange={(value) => setSearchNoTelp(value)}
            classNameInput={styles.inputStyle}
            fullWidth={isMobile}
          />
          <InputField
            placeholder={localize.getText("placeholderSearch", {
              field: localize.getText("dateOfBirth"),
            })}
            type="date"
            isBorder
            value={searchDate}
            onChange={(value) => {
              setSearchDate(value);
            }}
            fullWidth={isMobile}
            classNameInput="date-picker-search"
          />
          <InputField
            placeholder={localize.getText("placeholderSearch", {
              field: localize.getText("address"),
            })}
            isBorder
            value={searchAddress}
            onChange={(value) => setSearchAddress(value)}
            classNameInput={styles.inputStyle}
            fullWidth={isMobile}
          />
        </div>
      </div>

      {(isAddButton || !isNoReset) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 12,
          }}
        >
          {isAddButton && (
            <Button
              label={localize.getText("addPatient")}
              size="btn-sm"
              fullWidth={isMobile}
              onClick={() => history.push("/patient/add-patient")}
            />
          )}

          {!isNoReset && (
            <Button
              label={localize.getText("reset")}
              variant="danger"
              loading={isLoadingSearch}
              onClick={handleReset}
              fullWidth={isMobile}
              size="btn-sm"
            />
          )}
        </div>
      )}
    </>
  );
}
