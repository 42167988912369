import React from "react";
import cx from "classnames";
import styles from "./TextStatusReservation.module.css";

export default function TextStatusReservation({ label, color }) {
  return (
    <div className="d-flex align-items-center gap-2">
      <p className="m-0 font-w500 fs-5">{label}:</p>
      <div className={cx(styles.circle, color)}></div>
    </div>
  );
}
