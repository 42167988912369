import React from "react";
import AddLocationComponent from "../../components/AddLocation";
import { useMutation } from "@tanstack/react-query";
import { useNotification } from "../../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../../context/LocalizeContextProvider";
import { postLocation } from "../../../../../../services/SatuSehat/LocationService";
import { useHistory } from "react-router-dom";

export default function AddLocation() {
  const notification = useNotification();
  const localize = useLocalization();
  const history = useHistory();

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postLocation,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          history.goBack();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleSubmit = (value) => {
    const data = {
      resourceType: "Location",
      status: "active",
      name: value.name,
      description: value.description,
      telecom: [
        {
          system: "phone",
          value: value.phoneNumber,
          use: "work",
        },
        {
          system: "email",
          value: value.email,
          use: "work",
        },
        {
          system: "url",
          value: value.website,
          use: "work",
        },
      ],
      address: {
        use: "work",
        line: [value.address],
        city: value.city,
        postalCode: value.postalCode,
        country: "ID",
        extension: [
          {
            url: "https://fhir.kemkes.go.id/r4/StructureDefinition/administrativeCode",
            extension: [
              {
                url: "province",
                valueCode: value.province,
              },
              {
                url: "city",
                valueCode: value.city,
              },
              {
                url: "district",
                valueCode: value.district,
              },
              {
                url: "village",
                valueCode: value.village,
              },
              {
                url: "rt",
                valueCode: value.rt,
              },
              {
                url: "rw",
                valueCode: value.rw,
              },
            ],
          },
        ],
      },
    };
    mutateSubmit(data);
  };

  return (
    <AddLocationComponent
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
    />
  );
}
