import React from "react";
import AddOrganizationComponent from "../../components/AddOrganization";
import { useMutation } from "@tanstack/react-query";
import { useNotification } from "../../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../../context/LocalizeContextProvider";
import { postOrganization } from "../../../../../../services/SatuSehat/OrganizationService";
import { useHistory } from "react-router-dom";

export default function AddOrganization() {
  const notification = useNotification();
  const localize = useLocalization();
  const history = useHistory();

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postOrganization,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          history.goBack();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleSubmit = (value) => {
    const data = {
      active: true,
      name: value.name,
      telecom: [
        {
          system: "phone",
          value: value.phoneNumber,
          use: "work",
        },
        {
          system: "email",
          value: value.email,
          use: "work",
        },
        {
          system: "url",
          value: value.website,
          use: "work",
        },
      ],
      address: [
        {
          use: "work",
          type: "both",
          line: [value.address],
          city: value.city,
          postalCode: value.postalCode,
          country: "ID",
          extension: [
            {
              url: "https://fhir.kemkes.go.id/r4/StructureDefinition/administrativeCode",
              extension: [
                {
                  url: "province",
                  valueCode: value.province,
                },
                {
                  url: "city",
                  valueCode: value.city,
                },
                {
                  url: "district",
                  valueCode: value.district,
                },
                {
                  url: "village",
                  valueCode: value.village,
                },
              ],
            },
          ],
        },
      ],
    };
    mutateSubmit(data);
  };

  return (
    <AddOrganizationComponent
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
    />
  );
}
