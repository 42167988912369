import React from "react";
import cx from "classnames";

import { getCSSVariable } from "../../../utils/utils";
import styles from "./WrapperContent.module.css";

export default function WrapperContent({
  title,
  children,
  extraHeader,
  disableBackground = false,
  color = "primary",
}) {
  const styleColor = {
    primary: {
      border: getCSSVariable("--primary"),
      background: getCSSVariable("--primary-light"),
    },
    danger: {
      border: "#fc2e53",
      background: "#FFEEF1",
    },
    plaint: {
      border: getCSSVariable("--primary"),
      background: "",
    },
  };
  return (
    <div
      className={cx(styles.warapper)}
      style={{
        border: `1px solid ${styleColor[color].border}`,
        background: !disableBackground ? styleColor[color].background : "",
      }}
    >
      {title && (
        <div
          className={styles.wrapperHeader}
          style={{
            background: styleColor[color].background,
          }}
        >
          <p>{title}</p>
          {extraHeader}
        </div>
      )}
      <div className={styles.wrapperBody}>{children}</div>
    </div>
  );
}
