import React from "react";
import LoadingSection from "../../../components/LoadingSection";
import { getProfile, postProfile } from "../../../../services/ProfileService";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNotification } from "../../../../context/NotificationProvider";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import { retrieveUrlFile } from "../../../../utils/utils";
import { useAuth } from "../../../../context/AuthProvider";
import ProfileComponent from "../Component";

export default function Profile() {
  const notification = useNotification();
  const localize = useLocalization();
  const { refetchAuth } = useAuth();
  const { isLoading, data } = useQuery(["getProfile"], getProfile);

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postProfile,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          refetchAuth();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  if (isLoading) {
    return <LoadingSection />;
  }

  const handleSubmitForm = async (value) => {
    let imageUrl = "";
    if (value.imageUrl && value.imageUrl.constructor === File) {
      imageUrl = await retrieveUrlFile(value.imageUrl);
    }
    mutateSubmit({ ...value, imageUrl });
  };

  return (
    <ProfileComponent
      initialValues={data?.data}
      handleSubmitForm={handleSubmitForm}
      loadingSubmit={isLoadingSubmit}
    />
  );
}
