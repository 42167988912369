import React, { useState } from "react";
import EditOrganizationComponent from "../../components/EditOrganization";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNotification } from "../../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../../context/LocalizeContextProvider";
import {
  postOrganization,
  getOrganizationDetail,
} from "../../../../../../services/SatuSehat/OrganizationService";
import { useHistory } from "react-router-dom";
import {
  getParams,
  satuSehatFindAddressExtentionRes,
  satuSehatFindTelecomRes,
} from "../../../../../../utils/utils";
import LoadingSection from "../../../../../components/LoadingSection";

export default function EditOrganization() {
  const notification = useNotification();
  const localize = useLocalization();
  const history = useHistory();
  const id = getParams("id");

  const [initialValues, setInitialValues] = useState({});

  const { isLoading } = useQuery(
    ["OrganizationDetail"],
    () => getOrganizationDetail(id),
    {
      enabled: !!id,
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          setInitialValues({
            name: response.name,
            email: satuSehatFindTelecomRes(response, "email"),
            phoneNumber: satuSehatFindTelecomRes(response, "phone"),
            website: satuSehatFindTelecomRes(response, "url"),
            address: response.address[0].line[0],
            city: satuSehatFindAddressExtentionRes(response, "city"),
            postalCode: response.address[0].postalCode,
            province: satuSehatFindAddressExtentionRes(response, "province"),
            district: satuSehatFindAddressExtentionRes(response, "district"),
            village: satuSehatFindAddressExtentionRes(response, "village"),
          });
        }
      },
    }
  );

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postOrganization,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          history.goBack();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleSubmit = (value) => {
    const data = {
      id,
      active: true,
      name: value.name,
      telecom: [
        {
          system: "phone",
          value: value.phoneNumber,
          use: "work",
        },
        {
          system: "email",
          value: value.email,
          use: "work",
        },
        {
          system: "url",
          value: value.website,
          use: "work",
        },
      ],
      address: [
        {
          use: "work",
          type: "both",
          line: [value.address],
          city: value.city,
          postalCode: value.postalCode,
          country: "ID",
          extension: [
            {
              url: "https://fhir.kemkes.go.id/r4/StructureDefinition/administrativeCode",
              extension: [
                {
                  url: "province",
                  valueCode: value.province,
                },
                {
                  url: "city",
                  valueCode: value.city,
                },
                {
                  url: "district",
                  valueCode: value.district,
                },
                {
                  url: "village",
                  valueCode: value.village,
                },
              ],
            },
          ],
        },
      ],
    };
    mutateSubmit(data);
  };

  if (isLoading) {
    <LoadingSection />;
  }

  return (
    <EditOrganizationComponent
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={initialValues}
    />
  );
}
