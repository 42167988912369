import instance from "../config/interceptor";
import { PER_PAGE_SIZE } from "../utils/constants";

export const getServices = async () => {
  let _response;
  try {
    const link = `${process.env.REACT_APP_BASE_API_URL}/services/${process.env.REACT_APP_ID}`;

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getClinicByService = async (id) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/service-provider/${id}`;
    if (process.env.REACT_APP_STAGING === "true") {
      link = link.concat("?published=false");
    }

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getClinics = async ({ page = 0, size = PER_PAGE_SIZE }) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/service-provider/app/${process.env.REACT_APP_ID}?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);
    if (process.env.REACT_APP_STAGING === "true") {
      link = link.concat("&published=false");
    }

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};
