import React from "react";
import styles from "./Modal.module.css";
import { Modal as ModalRB } from "react-bootstrap";
import classNames from "classnames";

export default function Modal({
  visible = false,
  handleHideModal = () => undefined,
  centered = false,
  isBorderTitle = false,
  size = "md",
  title = "",
  children,
  isForce = false,
  classNameWrapper,
  dialogClassName,
}) {
  return (
    <ModalRB
      show={visible}
      onHide={handleHideModal}
      centered={centered}
      size={size}
      dialogClassName={dialogClassName}
    >
      <ModalRB.Body
        className={classNames(classNameWrapper, styles.modalWrapper)}
      >
        {!isForce && (
          <i
            className={classNames("fas fa-times-circle", styles.closeIcon)}
            onClick={handleHideModal}
          ></i>
        )}
        <div className={isBorderTitle && styles.modalTitleWrapper}>
          {title && <h4 className="mb-0">{title}</h4>}
        </div>
        {children}
      </ModalRB.Body>
    </ModalRB>
  );
}
