import { PER_PAGE_SIZE } from "../utils/constants";
import instance from "../config/interceptor";

export const getVisitHistory = async ({
  patientId,
  page = 1,
  size = PER_PAGE_SIZE,
}) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/visits-history/${patientId}?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};
