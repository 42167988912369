import React from "react";
import ListHistoryVisitComponent from "../../components/ListHistoryVisit";
import useHistoryVisit from "../../../../../hooks/useHistoryVisit";

export default function ListHistoryVisit() {
  const {
    dataHistoryVisit,
    handleChangeFilter,
    handleChangeSizePerPage,
    handleClickFilter,
    handlePagination,
    handleClearFilter,
    handleExportHistory,
    isLoading,
    totalEntries,
    totalPage,
    filterHistory,
    isLoadingExport,
  } = useHistoryVisit();

  return (
    <ListHistoryVisitComponent
      dataHistory={dataHistoryVisit}
      isLoading={isLoading}
      totalPage={totalPage}
      totalEntries={totalEntries}
      activePage={filterHistory?.page}
      handlePagination={handlePagination}
      filterHistory={filterHistory}
      handleChangeFilter={handleChangeFilter}
      handleClickFilter={handleClickFilter}
      handleClearFilter={handleClearFilter}
      handleChangeSizePerPage={handleChangeSizePerPage}
      handleExportHistory={handleExportHistory}
      isLoadingExport={isLoadingExport}
    />
  );
}
