import axios from "axios";

export const getScheduleDoctor = async (doctorId) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_LAMBDA_URL + `/get-doctor-schedule/${doctorId}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const upsertScheduleDoctor = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_LAMBDA_URL + `/save-schedule-doctor`,
      data
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getAvailableSlotDoctor = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_LAMBDA_URL + `/get-available-slots`,
      data
    );

    return response;
  } catch (error) {
    return error;
  }
};
