import React from "react";
import InputField from "../../../../components/InputField";
import { Form, Field } from "react-final-form";
import { Card } from "react-bootstrap";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import Button from "../../../../components/Button";

export default function CredentialComponent({
  handleChangeField,
  isLoadingSubmit = false,
  initialState,
  handleSubmit,
}) {
  const localize = useLocalization();

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors = {};

        if (!values.organizationId) {
          errors.organizationId = localize.getText(
            "generalRequiredFieldValidation",
            { fieldName: localize.getText("organizationId") }
          );
        }
        if (!values.clientKey) {
          errors.clientKey = localize.getText(
            "generalRequiredFieldValidation",
            { fieldName: localize.getText("clientKey") }
          );
        }
        if (!values.secretKey) {
          errors.secretKey = localize.getText(
            "generalRequiredFieldValidation",
            { fieldName: localize.getText("secretKey") }
          );
        }

        return errors;
      }}
      initialValues={initialState}
    >
      {({ handleSubmit }) => (
        <Card>
          <Card.Body>
            <form className="py-4 d-flex flex-column gap-2">
              <Field name="organizationId">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("organizationId")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("organizationId"),
                    })}
                    error={meta.touched && meta.error}
                    onChange={(value) =>
                      handleChangeField("organizationId", value)
                    }
                    onBlur={input.onBlur}
                    value={initialState?.organizationId}
                  />
                )}
              </Field>
              <Field name="clientKey">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("clientKey")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("clientKey"),
                    })}
                    error={meta.touched && meta.error}
                    onChange={(value) => handleChangeField("clientKey", value)}
                    onBlur={input.onBlur}
                    value={initialState?.clientKey}
                  />
                )}
              </Field>
              <Field name="secretKey">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("secretKey")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("secretKey"),
                    })}
                    error={meta.touched && meta.error}
                    onChange={(value) => handleChangeField("secretKey", value)}
                    onBlur={input.onBlur}
                    value={initialState?.secretKey}
                  />
                )}
              </Field>
              <Button
                label={localize.getText("save")}
                className="mt-3"
                onClick={handleSubmit}
                loading={isLoadingSubmit}
              />
            </form>
          </Card.Body>
        </Card>
      )}
    </Form>
  );
}
