import React, { useState } from "react";
import Button from "../../../../components/Button";
import InputField from "../../../../components/InputField";

export default function ModalAddNotes({
  handleDismisModal,
  event,
  positionAnnotation,
  dataAnnotation,
  selectedImage,
  setPossitionAnnotation,
}) {
  const [description, setDescription] = useState("");

  const handleClickSave = () => {
    const dataPosition = [...positionAnnotation];
    const filterSelectedImg = dataPosition.filter(
      (value) => value.type === selectedImage
    );
    const imageFilter = dataAnnotation.find(
      (value) => value.subType === selectedImage
    );

    if (filterSelectedImg.length <= 0) {
      dataPosition.push({
        type: imageFilter.subType,
        photoUrl: imageFilter.imageUrl,
        annotationDetails: [
          {
            x: event.layerX,
            y: event.layerY,
            dx: event.dx || 80,
            dy: event.dy || 80,
            title: "Anotation " + 1,
            description,
          },
        ],
      });
    } else {
      const selectedPositionImg = filterSelectedImg[0];
      selectedPositionImg.annotationDetails.push({
        x: event.layerX,
        y: event.layerY,
        dx: event.dx || 80,
        dy: event.dy || 80,
        title:
          "Anotation " + (selectedPositionImg.annotationDetails.length + 1),
        description,
      });
    }

    setPossitionAnnotation(dataPosition);
    handleDismisModal();
  };
  return (
    <div>
      <InputField
        label="Description"
        type="textarea"
        value={description}
        onChange={(value) => setDescription(value)}
        id="description-annotation"
        autoFocus
      />

      <div className="d-flex gap-2 mt-4 justify-content-end">
        <Button
          label="Cancel"
          variant="danger"
          style={{ width: 100 }}
          size="sm"
          onClick={handleDismisModal}
        />
        <Button
          label="Save"
          style={{ width: 100 }}
          size="sm"
          onClick={handleClickSave}
          disabled={!description}
        />
      </div>
    </div>
  );
}
