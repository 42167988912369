import React from "react";
import FormCategoryItem from "../FormCategoryItem";

export default function EditCategoryItemComponent({
  handleSubmit,
  isLoadingSubmit,
  initialValues,
}) {
  return (
    <FormCategoryItem
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={initialValues}
      isEdit
    />
  );
}
