import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getPatientSingle } from "../../../../../services/PatientService";
import { getParams } from "../../../../../utils/utils";
import LoadingSection from "../../../../components/LoadingSection";
import FormPatient from "../../Components/FormPatients";

export default function DetailPatient() {
  const id = getParams("id");

  const { isLoadingSingle, data } = useQuery(["DetailPatient", id], () =>
    getPatientSingle(id)
  );

  if (isLoadingSingle) {
    <LoadingSection />;
  }

  return <FormPatient dataForm={data?.data} isDetail />;
}
