import React from "react";
import FormItem from "../FormItem";

export default function EditItemComponent({
  handleSubmit,
  isLoadingSubmit,
  initialValues,
}) {
  return (
    <FormItem
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={initialValues}
      isEdit
    />
  );
}
