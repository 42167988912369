import instance from "../config/interceptor";
import { PER_PAGE_SIZE } from "../utils/constants";

export const getPatientAll = async ({
  page = 1,
  searchName,
  searchNoTelp,
  searchDate,
  searchAddress,
  size = PER_PAGE_SIZE,
}) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/patients?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);
    if (searchName) link = link.concat("&name.contains=" + searchName);
    if (searchNoTelp)
      link = link.concat("&phoneNumber.contains=" + searchNoTelp);
    if (searchAddress) link = link.concat("&address.contains=" + searchAddress);
    if (searchDate) link = link.concat("&dob.equals=" + searchDate);

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getPatientSingle = async (id) => {
  let _response;
  try {
    const response = await instance.get(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/patient/${id}`
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const postPatient = async (data) => {
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/patient`,
      data
    );

    return response;
  } catch (error) {
    return error;
  }
};
