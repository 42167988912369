import React, { useState } from "react";
import cx from "classnames";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./DatePickerIcon.module.css";

export default function DatePickerIcon({
  value,
  onChange = () => undefined,
  className,
  shouldDisableDate,
  disabled = false,
}) {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  return (
    <div className="position-relative">
      <i
        className={cx(
          className,
          disabled ? "cursor-not-allowed" : "c-pointer",
          "fas fa-calendar-alt h3 mb-0 "
        )}
        onClick={() => !disabled && setOpenDatePicker(!openDatePicker)}
        style={{ zIndex: 9999, color: disabled && "#adadad" }}
      ></i>
      <DatePicker
        open={openDatePicker}
        value={value}
        onChange={(value) => onChange(value)}
        onClose={() => setOpenDatePicker(!openDatePicker)}
        className={styles.positionDatePicker}
        shouldDisableDate={shouldDisableDate}
        disabled={disabled}
      />
    </div>
  );
}
