import React from "react";
import { Dropdown } from "react-bootstrap";

export default function DropdownField({
  label,
  datas = [],
  variant = "primary",
  onChange,
}) {
  return (
    <Dropdown>
      <Dropdown.Toggle variant={variant} id="dropdown-basic">
        {label}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {datas.map((item, index) => (
          <Dropdown.Item onClick={() => onChange(item?.id || "")} key={index}>
            {item?.label || ""}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
