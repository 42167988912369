import React from "react";

export default function RadioField({
  label,
  value,
  datas,
  onChange = () => undefined,
  onBlur = () => undefined,
  error,
  disabled = false,
}) {
  return (
    <div class="form-group w-100">
      <label className="mb-1 ">
        <strong>{label}</strong>
      </label>
      <div class="col-sm-10 d-flex gap-4">
        {datas?.map((item, index) => (
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name={label}
              id={item.label + index}
              value={item.id}
              checked={item.id === value}
              onChange={(e) => onChange(e.target.value)}
              onBlur={onBlur}
              disabled={disabled}
            />
            <label className="form-check-label" htmlFor={item.label + index}>
              {item.label}
            </label>
          </div>
        ))}
      </div>
      {error && (
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: "block" }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
