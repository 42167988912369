import React, { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import ScheduleDoctorComponent from "../components";
import { getParams } from "../../../../../utils/utils";
import {
  getScheduleDoctor,
  upsertScheduleDoctor,
} from "../../../../../services/Handlers/ScheduleService";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { useAuth } from "../../../../../context/AuthProvider";

export default function ScheduleDoctor() {
  const doctorId = +getParams("id");
  const notification = useNotification();
  const localize = useLocalization();
  const { userDetail } = useAuth();

  const [userSchedule, setUserSchedule] = useState([]);
  const [durationVisit, setDurationVisit] = useState(15);
  const [weekendMode, setWeekendMode] = useState(false);

  const { isLoading: isLoadingGetSchedule, refetch } = useQuery(
    ["Schedule Doctor"],
    () => getScheduleDoctor(doctorId),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          if (response) {
            setDurationVisit(response?.duration_visit || 15);
            setWeekendMode(response?.weekend_mode || false);
            if (response.doctor_schedule.length > 0) {
              setUserSchedule(response.doctor_schedule);
            }
          }
        }
      },
    }
  );

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    upsertScheduleDoctor,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          refetch();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleChangeUserSchedule = (scheduleData) => {
    const schedule = scheduleData.map((item) => ({
      day: item.day,
      active_in_day: item.isActive,
      slots: item.slots.map((slot) => ({
        from: slot.value.from,
        to: slot.value.to,
        enabled: slot.value.enabled,
      })),
    }));

    const dataDoctorSchedule = {
      duration_visit: durationVisit,
      availabilities: schedule,
      weekend_mode: weekendMode,
      doctor_id: doctorId,
      busines_id: userDetail?.businessId,
    };

    mutateSubmit(dataDoctorSchedule);
  };

  const handleChangeDuration = (value) => {
    setDurationVisit(value);
    const dataDoctorSchedule = {
      duration_visit: value,
      availabilities: userSchedule,
      weekend_mode: weekendMode,
      doctor_id: doctorId,
      busines_id: userDetail?.businessId,
    };

    mutateSubmit(dataDoctorSchedule);
  };

  const handleChangeWeekendMode = (value) => {
    setWeekendMode(value);
    const dataDoctorSchedule = {
      duration_visit: durationVisit,
      availabilities: userSchedule,
      weekend_mode: value,
      doctor_id: doctorId,
      busines_id: userDetail?.businessId,
    };

    mutateSubmit(dataDoctorSchedule);
  };

  return (
    <ScheduleDoctorComponent
      value={userSchedule}
      durationVisit={durationVisit}
      weekendMode={weekendMode}
      onChange={handleChangeUserSchedule}
      handleChangeDuration={handleChangeDuration}
      handleChangeWeekendMode={handleChangeWeekendMode}
      isLoading={isLoadingGetSchedule}
      isLoadingSchedule={isLoadingSubmit}
    />
  );
}
