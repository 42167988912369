import React from "react";
import FormLocation from "../FormLocation";

export default function EditLocationComponent({
  handleSubmit,
  isLoadingSubmit,
  initialValues,
}) {
  return (
    <FormLocation
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={initialValues}
      isEdit
    />
  );
}
