import React from "react";
import Switch from "react-js-switch";

export default function SwitchField({
  leftLabel,
  rightLabel,
  size = 40,
  value,
  onChange = () => undefined,
}) {
  return (
    <div className="d-flex gap-3 align-items-center">
      {leftLabel && <p className="m-0 font-w500">{leftLabel}</p>}
      <Switch
        backgroundColor={{ on: "#886CC0", off: "#CBCBCB" }}
        borderColor={{ on: "#886CC0", off: "#CBCBCB" }}
        size={size}
        value={value}
        onChange={() => onChange(!value)}
      />
      {rightLabel && <p className="m-0 font-w500">{rightLabel}</p>}
    </div>
  );
}
