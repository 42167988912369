import React, { useState, useEffect } from "react";
import { fileReader } from "../../../utils/utils";
import styles from "./UploadImageAvatar.module.css";

export default function UploadImageAvatar({
  value,
  onChange = () => undefined,
  error,
  isRounded = false,
  defaultImages = "/assets/images/default-image-user.png",
  style = {},
  label = "",
  id = "uploadFileAvatar",
}) {
  const [filePreviewLocal, setFilePreviewLocal] = useState("");

  useEffect(() => {
    if (value && !filePreviewLocal) {
      setFilePreviewLocal(value);
    }
  }, [value, filePreviewLocal]);

  const onChangeFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { fileDocument, filePreview } = await fileReader(e.target.files[0]);
      setFilePreviewLocal(filePreview);
      onChange(fileDocument);
    }
  };

  return (
    <div className={styles.wrapperProfile}>
      {label && (
        <label className="mb-1 ">
          <strong>{label}</strong>
        </label>
      )}
      <label htmlFor={id}>
        <img
          src={filePreviewLocal || defaultImages}
          alt=""
          className={styles.imageProfile}
          style={isRounded ? { ...style, borderRadius: "50%" } : { ...style }}
        />
      </label>
      <label htmlFor={id} className={styles.iconUploadProfile}>
        <i className="fas fa-edit"></i>
      </label>

      <input
        type="file"
        id={id}
        style={{ display: "none" }}
        onChange={(e) => onChangeFile(e)}
        accept="image/*"
      />
      {error && (
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: "block" }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
