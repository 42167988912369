import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import styles from "./ScheduleField.module.css";
import {
  AVAILABLE_DAYS,
  AVAILABLE_TIME_SCHEDULE,
} from "../../../utils/constants";
import { formatDaySlotsDataOneHour } from "../../../utils/utils";
import cx from "classnames";
import Tabs from "../Tabs";
import SwitchField from "../SwitchField";

const checkTimeLoader = (day, index, isLoadingTime) => {
  if (day === isLoadingTime.day && index === isLoadingTime.indexTime) {
    return true;
  }
  return false;
};

export const ItemTimeSchedule = ({
  item,
  onClick,
  day,
  valueDay,
  index,
  isLoadingTime,
}) => {
  const valueDayItem = valueDay.find((value) => value.day === day.id);

  const selectedTime = valueDayItem?.slots || [];
  const activeTime = !!selectedTime.find(
    (value) => value.value.enabled && value.time === item.time
  );

  return (
    <div
      className={cx(styles.wrapperDay, activeTime && styles.active)}
      onClick={onClick}
    >
      <p className={styles.timeDay}>{item.time}</p>
      <p className={styles.labelDay}>{item.label}</p>
      {checkTimeLoader(day.id, index, isLoadingTime) && (
        <div className={styles.positionSpinner}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export default function ScheduleField({
  onChange,
  isLoading = false,
  value = [],
}) {
  const defaultLoaderTime = {
    day: null,
    indexTime: null,
  };
  const [valueDay, setValueDay] = useState([]);
  const [isLoadingTime, setIsLoadingTime] = useState(defaultLoaderTime);

  useEffect(() => {
    if (value.length > 0 && valueDay.length === 0) {
      setValueDay(formatDaySlotsDataOneHour(value));
    }

    if (value.length === 0 && valueDay.length !== 0) {
      setValueDay([]);
    }
  }, [value, valueDay]);

  useEffect(() => {
    if (isLoading === false) {
      setIsLoadingTime(defaultLoaderTime);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handleChangeSlot = (day, slots, index) => {
    const datas = [...valueDay];
    const time = datas.find((item) => item.day === day);
    if (time?.slots) {
      if (
        time.slots.length > 0 &&
        time.slots.filter((item) => item.time === slots.time).length > 0
      ) {
        time.slots.forEach((item) => {
          if (item.time === slots.time) {
            item.value.enabled = !item.value.enabled;
          }
        });
      } else {
        time.slots = [...time.slots, slots];
      }
    } else {
      datas.push({
        day,
        isActive: true,
        slots: [slots],
      });
    }
    setIsLoadingTime({
      day: day,
      indexTime: index,
    });

    onChange(datas);
  };

  const handleChangeActiveDay = (day, value) => {
    const datas = [...valueDay];
    datas.find((value) => value.day === day).isActive = value;

    onChange(datas);
  };

  const itemTabs = AVAILABLE_DAYS.map((day) => ({
    key: day.id,
    label: (
      <div className="d-flex align-items-center gap-2">
        {day.label}{" "}
        <SwitchField
          size={35}
          value={
            valueDay.find((value) => value.day === day.id)?.isActive || false
          }
          onChange={(value) => handleChangeActiveDay(day.id, value)}
        />
      </div>
    ),
    children: (
      <div className={styles.wrapperItemDay}>
        {AVAILABLE_TIME_SCHEDULE.map((item, indexTime) => (
          <ItemTimeSchedule
            key={indexTime}
            index={indexTime}
            item={item}
            day={day}
            isLoadingTime={isLoadingTime}
            valueDay={valueDay}
            onClick={() => handleChangeSlot(day.id, item, indexTime)}
          />
        ))}
      </div>
    ),
  }));

  return (
    <div className="d-flex flex-column gap-4 w-100">
      <Tabs items={itemTabs} />
    </div>
  );
}
