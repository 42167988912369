import React, { useContext } from "react";
import classNames from "classnames";
import { ThemeContext } from "../../../context/ThemeContext";

export default function PrimaryColorField({
  fullWidth = false,
  label,
  value = "color_1",
  onChange = () => undefined,
}) {
  const { colors } = useContext(ThemeContext);

  return (
    <div className={classNames("form-group", fullWidth && "w-100")}>
      {label && (
        <label className="mb-3">
          <strong>{label}</strong>
        </label>
      )}

      <div
        className="admin-settings d-flex gap-3 flex-wrap"
        style={{ maxWidth: 400 }}
      >
        {colors.map((color, i) => (
          <span key={i}>
            <input
              type="radio"
              name="primary_color"
              checked={color === value}
              className="filled-in chk-col-primary"
              id={`primary_${color}`}
              onClick={() => onChange(color)}
            />
            <label htmlFor={`primary_${color}`} />
          </span>
        ))}
      </div>
    </div>
  );
}
