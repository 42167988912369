import { useState, useEffect } from "react";
import { useNotification } from "../context/NotificationProvider";
import { useLocalization } from "../context/LocalizeContextProvider";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ROOT_DASHBOARD } from "../utils/constants";
import { onboardingAccount } from "../services/AccountService";
import { getServicesType, getBusinessType } from "../services/GeneralService";

export default function useOnboarding() {
  const notification = useNotification();
  const localize = useLocalization();

  const [getStep, setGetStep] = useState(0);
  const [dataServices, setDataServices] = useState([]);
  const [dataBusiness, setDataBusiness] = useState([]);
  const [valueFormStepOne, setValueFormStepOne] = useState({
    userPhoneNumber: "",
    userDob: null,
    userGender: null,
    userAddress: "",
  });
  const [valueFormStepTwo, setValueFormStepTwo] = useState({
    businessName: "",
    businessType: null,
    businessService: [],
    businessAddress: "",
  });

  const { isLoading: isLoadingService, data: dataServicesType } = useQuery(
    ["ServicesType"],
    getServicesType
  );
  const { isLoading: isLoadingBusiness, data: dataBusinessType } = useQuery(
    ["BusinessType"],
    getBusinessType
  );

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    onboardingAccount,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const step = getStep + 1;
          setGetStep(step);
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  useEffect(() => {
    if (dataServicesType && dataServicesType.data.length > 0) {
      const data = [];
      dataServicesType.data.forEach((item) => {
        data.push({
          value: item.id,
          label: item.name,
        });
      });
      setDataServices(data);
    }
  }, [dataServicesType]);

  useEffect(() => {
    if (dataBusinessType && dataBusinessType.data.length > 0) {
      const data = [];
      dataBusinessType.data.forEach((item) => {
        data.push({
          value: item.id,
          label: item.name,
        });
      });
      setDataBusiness(data);
    }
  }, [dataBusinessType]);

  const handleChangeFormStepOne = (name, value) => {
    setValueFormStepOne({
      ...valueFormStepOne,
      [name]: value,
    });
  };

  const handleChangeFormStepTwo = (name, value) => {
    setValueFormStepTwo({
      ...valueFormStepTwo,
      [name]: value,
    });
  };

  const handleNext = () => {
    const step = getStep + 1;

    //DY: LATER CHANGE TO DASHBOARD
    if (getStep === 3) return (window.location.href = ROOT_DASHBOARD);
    setGetStep(step);
  };

  const handleBack = () => {
    const step = getStep - 1;
    if (getStep === 0) return;
    setGetStep(step);
  };

  const disabledNextStepOne = () => {
    const convertObj = Object.entries(valueFormStepOne);
    const filtered = convertObj.filter(([key, value]) => !value);

    return filtered.length > 0 ? true : false;
  };

  const disabledNextStepTwo = () => {
    const convertObj = Object.entries(valueFormStepTwo);
    const filtered = convertObj.filter(([key, value]) => {
      if (key === "businessService" && value.length === 0) {
        return value;
      }
      return !value;
    });

    return filtered.length > 0 ? true : false;
  };

  const handleSubmitOnboarding = () => {
    const serviceIds = [];

    valueFormStepTwo?.businessService?.forEach((item) => {
      serviceIds.push(item.value);
    });

    const data = {
      ...valueFormStepOne,
      businessName: valueFormStepTwo.businessName,
      businessAddress: valueFormStepTwo.businessAddress,
      businessTypeId: valueFormStepTwo.businessType.value,
      serviceIds,
    };

    mutateSubmit(data);
  };

  return {
    activeStep: getStep,
    handleBack,
    handleNext,
    disabledNextStepOne,
    disabledNextStepTwo,
    handleChangeFormStepOne,
    handleChangeFormStepTwo,
    valueFormStepOne,
    valueFormStepTwo,
    handleSubmitOnboarding,
    isLoadingSubmit,
    dataServices,
    dataBusiness,
    isLoadingService,
    isLoadingBusiness,
  };
}
