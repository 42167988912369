import { lazy, Suspense } from "react";

/// Components
import Index from "./jsx";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// action
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { useAuth } from "./context/AuthProvider";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import OnboardingContainer from "./jsx/module/Onboarding/OnboardingContainer";

const ActivationAccount = lazy(() => import("./jsx/module/ActivationAccount"));
const CreateNewPassword = lazy(() =>
  import("./jsx/module/ForgotPassword/CreateNewPassword")
);
const ForgotPasswordForm = lazy(() =>
  import("./jsx/module/ForgotPassword/ForgotPasswordForm")
);
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const Login = lazy(() => import("./jsx/pages/Login"));
const Home = lazy(() => import("./jsx/module/Home"));
const TemplateEmail = lazy(() => import("./jsx/module/TemplateEmail"));
const PrintInvoice = lazy(() => import("./jsx/components/PrintInvoice"));
const PasienHome = lazy(() => import("./jsx/module/PasienHome"));
const ReservationHome = lazy(() => import("./jsx/module/ReservationHome"));
const ReservationSuccess = lazy(() =>
  import("./jsx/module/ReservationSuccess")
);

function App(props) {
  const { isAuthenticated, userDetail } = useAuth();

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPasswordForm} />
      <Route path="/account/activate" component={ActivationAccount} />
      <Route path="/account/reset/finish" component={CreateNewPassword} />
      <Route path="/pasien" component={PasienHome} />
      <Route path="/reservation-pasien" component={ReservationHome} />
      <Route path="/reservation-success" component={ReservationSuccess} />
      <Route path="/print-invoice" component={PrintInvoice} />
      <Route path="/template-email" component={TemplateEmail} />
      <Route path="" component={Home} />
    </Switch>
  );

  const routerOnboarding = (
    <Switch>
      <Route path="/onboarding" component={OnboardingContainer} />
    </Switch>
  );

  if (userDetail?.onboardingCompleted === false) {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routerOnboarding}
        </Suspense>
      </div>
    );
  }

  if (isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
