import React from "react";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import classNames from "classnames";
import styles from "./OnboardingContainer.module.css";
import Button from "../../../components/Button";
import WelcomeOnboarding from "../WelcomeOnboarding";
import StepOneOnboarding from "../StepOneOnboarding";
import StepTwoOnboarding from "../StepTwoOnboarding";
import SuccessOnboarding from "../SuccessOnboarding";
import useOnboarding from "../../../../hooks/useOnboarding";

export default function OnboardingContainer() {
  const localize = useLocalization();
  const {
    activeStep,
    handleBack,
    handleNext,
    disabledNextStepOne,
    disabledNextStepTwo,
    valueFormStepOne,
    valueFormStepTwo,
    handleChangeFormStepOne,
    handleChangeFormStepTwo,
    handleSubmitOnboarding,
    isLoadingSubmit,
    dataServices,
    dataBusiness,
    isLoadingService,
    isLoadingBusiness,
  } = useOnboarding();

  const validateButton = () => {
    switch (activeStep) {
      case 1:
        return disabledNextStepOne();
      case 2:
        return disabledNextStepTwo();

      default:
        return false;
    }
  };

  return (
    <div className={styles.wrapper}>
      {activeStep === 0 && <WelcomeOnboarding />}
      {activeStep === 1 && (
        <StepOneOnboarding
          valueFormStepOne={valueFormStepOne}
          handleChangeFormStepOne={handleChangeFormStepOne}
        />
      )}
      {activeStep === 2 && (
        <StepTwoOnboarding
          dataServices={dataServices}
          dataBusiness={dataBusiness}
          isLoadingService={isLoadingService}
          isLoadingBusiness={isLoadingBusiness}
          valueFormStepTwo={valueFormStepTwo}
          handleChangeFormStepTwo={handleChangeFormStepTwo}
        />
      )}
      {activeStep === 3 && <SuccessOnboarding />}

      <div
        className={classNames(
          "d-flex  gap-5",
          !(activeStep === 0 || activeStep === 3)
            ? "justify-content-between"
            : "justify-content-end",
          styles.wrapperAction
        )}
      >
        {!(activeStep === 0 || activeStep === 3) && (
          <Button
            label={localize.getText("back")}
            variant="outline-primary"
            style={{ width: 200 }}
            onClick={handleBack}
          />
        )}
        <Button
          label={
            activeStep !== 3
              ? localize.getText("next")
              : localize.getText("finish")
          }
          style={{ width: 200 }}
          onClick={activeStep !== 2 ? handleNext : handleSubmitOnboarding}
          disabled={validateButton()}
          loading={isLoadingSubmit}
        />
      </div>
    </div>
  );
}
