import React, { useState } from "react";
import EditLocationComponent from "../../components/EditLocation";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNotification } from "../../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../../context/LocalizeContextProvider";
import {
  postLocation,
  getLocationDetail,
} from "../../../../../../services/SatuSehat/LocationService";
import { useHistory } from "react-router-dom";
import {
  getParams,
  satuSehatLocFindAddressExtentionRes,
  satuSehatFindTelecomRes,
} from "../../../../../../utils/utils";
import LoadingSection from "../../../../../components/LoadingSection";

export default function EditLocation() {
  const notification = useNotification();
  const localize = useLocalization();
  const history = useHistory();
  const id = getParams("id");

  const [initialValues, setInitialValues] = useState({});

  const { isLoading } = useQuery(
    ["LocationDetail"],
    () => getLocationDetail(id),
    {
      enabled: !!id,
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          setInitialValues({
            name: response.name,
            description: response.description,
            email: satuSehatFindTelecomRes(response, "email"),
            phoneNumber: satuSehatFindTelecomRes(response, "phone"),
            website: satuSehatFindTelecomRes(response, "url"),
            address: response.address.line[0],
            city: satuSehatLocFindAddressExtentionRes(response, "city"),
            postalCode: response.address.postalCode,
            province: satuSehatLocFindAddressExtentionRes(response, "province"),
            district: satuSehatLocFindAddressExtentionRes(response, "district"),
            village: satuSehatLocFindAddressExtentionRes(response, "village"),
            rt: satuSehatLocFindAddressExtentionRes(response, "rt"),
            rw: satuSehatLocFindAddressExtentionRes(response, "rw"),
          });
        }
      },
    }
  );

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postLocation,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          history.goBack();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleSubmit = (value) => {
    const data = {
      id,
      resourceType: "Location",
      status: "active",
      name: value.name,
      description: value.description,
      telecom: [
        {
          system: "phone",
          value: value.phoneNumber,
          use: "work",
        },
        {
          system: "email",
          value: value.email,
          use: "work",
        },
        {
          system: "url",
          value: value.website,
          use: "work",
        },
      ],
      address: {
        use: "work",
        line: [value.address],
        city: value.city,
        postalCode: value.postalCode,
        country: "ID",
        extension: [
          {
            url: "https://fhir.kemkes.go.id/r4/StructureDefinition/administrativeCode",
            extension: [
              {
                url: "province",
                valueCode: value.province,
              },
              {
                url: "city",
                valueCode: value.city,
              },
              {
                url: "district",
                valueCode: value.district,
              },
              {
                url: "village",
                valueCode: value.village,
              },
              {
                url: "rt",
                valueCode: value.rt,
              },
              {
                url: "rw",
                valueCode: value.rw,
              },
            ],
          },
        ],
      },
    };
    mutateSubmit(data);
  };

  if (isLoading) {
    <LoadingSection />;
  }

  return (
    <EditLocationComponent
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={initialValues}
    />
  );
}
