import React, { useEffect } from "react";
import Button from "../Button";
import styles from "./ModalClinic.module.css";

export default function ModalClinic({
  data = [],
  handleSelect = () => undefined,
  setIsShowClinic = (value) => undefined,
}) {
  useEffect(() => {
    return () => {
      setIsShowClinic(false);
    };
  });
  return (
    <div className={styles.wrapperItem}>
      {data.map((item, index) => (
        <div className={styles.wrapperItemList} key={index}>
          <div className={styles.wrapperContent}>
            <img src={item?.logo || ""} alt="" />
            <div className={styles.contentInfo}>
              <p className={styles.contentTitleInfo}>{item.name}</p>
              <p className={styles.contentDescInfo}>{item.address}</p>
            </div>
          </div>
          <Button
            label="Pilih"
            variant="outline-primary"
            className={styles.actionService}
            onClick={() => handleSelect(item.id)}
          />
        </div>
      ))}
    </div>
  );
}
