import instance from "../config/interceptor";
import { PER_PAGE_SIZE } from "../utils/constants";

export const getHistoryVisits = async ({
  page = 1,
  size = PER_PAGE_SIZE,
  from,
  to,
  sort = "start,desc",
}) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/inactive-visits?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);
    link = link.concat("&from=" + from);
    link = link.concat("&to=" + to);
    link = link.concat("&sort=" + sort);

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const exportHistoryVisits = async ({
  page = 1,
  size = PER_PAGE_SIZE,
  from,
  to,
  sort = "start,desc",
}) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/inactive-visits/export-to-csv?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);
    link = link.concat("&from=" + from);
    link = link.concat("&to=" + to);
    link = link.concat("&sort=" + sort);

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};
