import React, { useState } from "react";
import DetailItemComponent from "../../components/DetailItem";
import { useQuery } from "@tanstack/react-query";
import { getItemDetail } from "../../../../../services/Handlers/MasterItemsService";
import { getParams } from "../../../../../utils/utils";
import LoadingSection from "../../../../components/LoadingSection";

export default function DetailItem() {
  const id = getParams("id");

  const [initialValues, setInitialValues] = useState({});

  const { isLoading, isFetching } = useQuery(
    ["ItemDetail"],
    () => getItemDetail(id),
    {
      enabled: !!id,
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          setInitialValues({
            name: response.name,
            price: response.price,
            category_item_id: response.category_item_id,
          });
        }
      },
    }
  );

  if (isLoading || isFetching) {
    <LoadingSection />;
  }

  return <DetailItemComponent initialValues={initialValues} />;
}
