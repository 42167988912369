import React from "react";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import { languages } from "../../../utils/constants";
import DropdownField from "../../components/DropdownField";
import HeaderProfile from "../../components/HeaderProfile";

const Header = ({ onNote, title }) => {
  const { setLocale, defaultLocale, getText } = useLocalization();
  var path = window.location?.pathname.split("/");
  let name = path[1].split("-");
  if (path.includes("dashboard") && path.length > 2) {
    name = path[2];
  }
  var filterName = name;

  return (
    <div className="header border-bottom">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
                id="title-page"
              >
                {!title && !filterName ? "Dashboard" : getText(filterName)}
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification d-flex align-items-center gap-3">
              <img
                src={languages.find((value) => value.id === defaultLocale).icon}
                width={30}
                alt=""
              />
              <DropdownField
                label={
                  languages.find((value) => value.id === defaultLocale).label
                }
                datas={languages || []}
                onChange={(value) => setLocale(value)}
                variant="transparent"
              />
              <HeaderProfile />

              {/* <li className="nav-item header-profile">
					<Link to={"#"} className="nav-link" role="button" data-bs-toggle="dropdown">
						<img src={profile} width="20" alt=""/>
					</Link>
				</li> */}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
