import React, { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

import Card from "../../../components/Card";
import {
  optionsFilterDuration,
  paymentMethod,
  paymentTypeOptions,
  typeDiscount,
  typeDiscountObj,
} from "../../../../utils/constants";
import AsyncSelect from "../../../components/AsyncSelect";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import Table from "../../../components/Table";
import { formatNumberWithCurrency } from "../../../../utils/utils";
import ModalDetailReservation from "../../../components/ModalDetailReservation";
import Modal from "../../../components/Modal";
import FormPayment from "../../../components/FormPayment";
import PrintReportPdf from "../../../components/PrintReportPdf";

export default function HistoryPaymentComponent({
  dataHistoryPayment,
  isLoading = false,
  totalPage = 0,
  totalEntries = 0,
  handlePagination,
  activePage,
  filterHistory,
  handleChangeFilter,
  handleClickFilter,
  handleClearFilter,
  handleChangeSizePerPage,
  dataCategoryItem = [],
  dataDoctors = [],
  isLoadingDoctor = false,
  isLoadingCategory = false,
  isLoadingExport = false,
  handleHideShowModalDetial = () => undefined,
  handleClickExport = () => undefined,
  handleHideShowModalDetialPayment = () => undefined,
  isLoadingModalDetail,
  dataDetailReservation,
  businessId = null,
  showModalDetail,
  handleClickPayment,
  handleClickPaymentPharmacy,
  showModalAddPayment,
  setShowModalAddPayment,
  isLoadingSubmitPayment = false,
  isDoctor = false,
  isOwner = false,
  dataDetailPayment,
  isLoadingDetailPayment = false,
  showModalDetailPayment,
  handleClickUpdatePayment,
}) {
  const localize = useLocalization();
  const componentExportPdfRef = useRef();
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);

  const handleExportPdf = useReactToPrint({
    content: () => componentExportPdfRef.current,
    onBeforeGetContent: () => {
      setIsLoadingPrint(true);

      const printStyle = document.createElement("style");
      printStyle.innerHTML = `
        @media print {
          @page {
            size: A4 landscape; 
            margin: 10mm;
          }
        }
      `;
      document.head.appendChild(printStyle);

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    onAfterPrint: () => {
      setIsLoadingPrint(false);

      const styleTag = document.querySelector("style");
      if (styleTag) {
        document.head.removeChild(styleTag);
      }
    },
  });

  const formatInputDate =
    optionsFilterDuration.find(
      (item) => item.value === filterHistory.type?.value
    )?.format || "DD-MM-YYYY";

  const columns = [
    { label: localize.getText("idPayment") },
    { label: localize.getText("namePatient") },
    { label: localize.getText("doctor") },
    { label: localize.getText("nameItem") },
    { label: localize.getText("priceItem") },
    { label: localize.getText("quantity") },
    { label: localize.getText("datePayment") },
    { label: localize.getText("paymentMethod") },
    ...(isOwner
      ? [
          { label: localize.getText("createdBy") },
          { label: localize.getText("updatedBy") },
        ]
      : []),
    { label: localize.getText("paymentType") },
    { label: localize.getText("totalPayment") },
    { label: localize.getText("discountType") },
    { label: localize.getText("discount") },
    {
      label: localize.getText("action"),
      className: "text-center",
    },
  ];

  return (
    <div>
      <Card title="FILTER DATA PEMBAYARAN">
        <div className="d-flex flex-column" style={{ gap: 32 }}>
          <Row style={{ rowGap: 18 }}>
            <Col md={4}>
              <AsyncSelect
                label={localize.getText("searchBy")}
                placeholder="Pilih kategori"
                options={optionsFilterDuration}
                value={filterHistory.type}
                onChange={(value) => handleChangeFilter("type", value)}
                fullWidth
                height={38}
                isBordered
              />
            </Col>
            <Col md={4}>
              <InputField
                type="date"
                views={[filterHistory.type?.value || "day"]}
                format={formatInputDate}
                label={localize.getText("from")}
                onChange={(value) => handleChangeFilter("from", value)}
                value={filterHistory.from || null}
                fullWidth
                disableHighlightToday
                isBorder
                classNameInput="date-picker-search"
              />
            </Col>
            <Col md={4}>
              <InputField
                type="date"
                views={[filterHistory.type?.value || "day"]}
                format={formatInputDate}
                label={localize.getText("to")}
                value={filterHistory.to || null}
                onChange={(value) => handleChangeFilter("to", value)}
                shouldDisableYear={(year) => {
                  return year.isBefore(filterHistory.from);
                }}
                shouldDisableMonth={(month) => {
                  return month.isBefore(filterHistory.from);
                }}
                shouldDisableDate={(date) => {
                  return date.isBefore(filterHistory.from);
                }}
                disabled={!filterHistory.from}
                fullWidth
                disableHighlightToday
                isEndField
                isBorder
                classNameInput="date-picker-search"
              />
            </Col>
          </Row>
          <Row style={{ rowGap: 18 }}>
            <Col md={3}>
              <AsyncSelect
                label={localize.getText("doctor")}
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("doctor"),
                })}
                options={dataDoctors}
                fullWidth
                height={38}
                isBordered
                isClearable
                labelKey="name"
                valueKey="id"
                isLoading={isLoadingDoctor}
                onChange={(value) => handleChangeFilter("doctor_id", value)}
                value={filterHistory.doctor_id}
                disabled={!!isDoctor}
              />
            </Col>
            <Col md={3}>
              <AsyncSelect
                label={localize.getText("category-item")}
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("category-item"),
                })}
                options={dataCategoryItem}
                fullWidth
                height={38}
                isBordered
                isClearable
                labelKey="name"
                valueKey="id"
                isLoading={isLoadingCategory}
                onChange={(value) =>
                  handleChangeFilter("category_item_id", value)
                }
                value={filterHistory.category_item_id}
              />
            </Col>
            <Col md={3}>
              <AsyncSelect
                label={localize.getText("paymentMethod")}
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("paymentMethod"),
                })}
                options={paymentMethod}
                fullWidth
                height={38}
                isBordered
                isClearable
                onChange={(value) => handleChangeFilter("method", value)}
                value={filterHistory.method}
              />
            </Col>
            <Col md={3}>
              <AsyncSelect
                label={localize.getText("paymentType")}
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("paymentType"),
                })}
                options={paymentTypeOptions}
                fullWidth
                height={38}
                isBordered
                isClearable
                onChange={(value) => handleChangeFilter("paymentType", value)}
                value={filterHistory.paymentType}
              />
            </Col>
          </Row>
          <div className="d-flex gap-4 justify-content-end">
            <Button
              label={localize.getText("search")}
              onClick={handleClickFilter}
              disabled={!(filterHistory.from && filterHistory.to)}
            />
            <Button
              leftIcon={<i className="far fa-file-alt"></i>}
              label="Export"
              variant="info"
              loading={isLoadingExport || isLoadingPrint}
              isDropdown
              dropdownMenus={[
                ...(isOwner
                  ? [{ label: "CSV", onClick: handleClickExport }]
                  : []),
                { label: "PDF", onClick: handleExportPdf },
              ]}
            />
            <Button
              label={localize.getText("reset")}
              variant="danger"
              onClick={handleClearFilter}
            />
          </div>
        </div>
      </Card>
      <div className="d-none">
        <PrintReportPdf
          ref={componentExportPdfRef}
          data={dataHistoryPayment || []}
        />
      </div>
      <Card
        header={
          <div className="d-flex justify-content-end w-100">
            <Button
              label={localize.getText("addPayment")}
              onClick={() => setShowModalAddPayment(!showModalAddPayment)}
            />
          </div>
        }
      >
        <Table
          columns={columns}
          loading={isLoading}
          activePage={activePage}
          totalPage={totalPage}
          totalShow={dataHistoryPayment.length}
          totalEntries={totalEntries}
          handlePagination={handlePagination}
          sizePerPage={filterHistory.size}
          handleSizePerPage={handleChangeSizePerPage}
        >
          {dataHistoryPayment.map((item, index) => (
            <tr key={index}>
              <td className="sorting_1">{item?.payment_id || "-"}</td>
              <td>{item?.user_name || "-"}</td>
              <td>{item.doctor_name || "-"}</td>
              <td>{item.name_item}</td>
              <td>{formatNumberWithCurrency(item.amount_item)}</td>
              <td>{item.quantity}</td>
              <td>{item.payment_date}</td>
              <td>{item.payment_method}</td>
              {isOwner && (
                <>
                  <td>{item.created_by}</td>
                  <td>{item.updated_by}</td>
                </>
              )}
              <td>{item.payment_type}</td>
              <td>{formatNumberWithCurrency(item.total_amount)}</td>
              <td>
                {typeDiscount.find((type) => type.value === item.type_discount)
                  ?.label || "-"}
              </td>
              <td>
                {item.type_discount === typeDiscountObj.NUMBER
                  ? formatNumberWithCurrency(item.discount)
                  : item.discount || "-"}
              </td>
              <td>
                <div className="d-flex justify-content-center gap-3">
                  {item.id ? (
                    <Button
                      variant="success"
                      tooltip={localize.getText("detailPayment")}
                      onClick={() => handleHideShowModalDetial(item.id)}
                    >
                      <i className="fas fa-money-bill-wave"></i>
                    </Button>
                  ) : (
                    <Button
                      variant="warning"
                      tooltip={localize.getText("detailPayment")}
                      onClick={() =>
                        handleHideShowModalDetialPayment(item.payment_id)
                      }
                    >
                      <i className="fas fa-eye"></i>
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </Table>
      </Card>
      <Modal
        visible={showModalAddPayment}
        size="lg"
        dialogClassName="max-w-1200"
        handleHideModal={() => setShowModalAddPayment(!showModalAddPayment)}
      >
        <FormPayment
          title={localize.getText("addPayment")}
          businessId={businessId}
          onClickCancel={() => setShowModalAddPayment(!showModalAddPayment)}
          onSubmit={handleClickPaymentPharmacy}
          loading={isLoadingSubmitPayment}
          isFieldUser
          isCancel
        />
      </Modal>
      <Modal
        visible={!!showModalDetailPayment}
        size="lg"
        dialogClassName="max-w-1200"
        handleHideModal={() => handleHideShowModalDetialPayment(null)}
      >
        <FormPayment
          title={localize.getText("updatePayment")}
          businessId={businessId}
          onClickCancel={() => handleHideShowModalDetialPayment(null)}
          onSubmit={handleClickUpdatePayment}
          loading={isLoadingDetailPayment}
          value={dataDetailPayment}
          isFieldUser
          isCancel
        />
      </Modal>
      <ModalDetailReservation
        isPaymentSection
        loading={isLoadingModalDetail}
        businessId={businessId}
        dataDetailReservation={dataDetailReservation}
        show={showModalDetail}
        handleModal={handleHideShowModalDetial}
        handleClickPayment={handleClickPayment}
      />
    </div>
  );
}
