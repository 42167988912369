import instance from "../config/interceptor";

export const getBusinessProfile = async () => {
  let _response;
  try {
    const response = await instance.get(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/business`
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const postBusinessProfile = async (data) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/business`,
      data
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};
