import React from "react";
import FormLocation from "../FormLocation";

export default function DetailLocationComponent({ initialValues }) {
  return (
    <FormLocation
      isView
      handleSubmit={() => {}}
      initialValues={initialValues}
    />
  );
}
