import React, { useEffect, useState } from "react";
import styles from "./PasienHome.module.css";
import Navbar from "../../layouts/Home/Navbar";
import Footer from "../../layouts/Home/Footer";
import Button from "../../components/Button";
import { useModal } from "../../../context/ModalProvider";
import { useHome } from "../../../context/HomeProvider";
import { useAuth } from "../../../context/AuthProvider";
import ModalService from "../../components/ModalService";
import ModalClinic from "../../components/ModalClinic";
import { useHistory } from "react-router-dom";

export default function PasienHome() {
  const modal = useModal();
  const history = useHistory();
  const {
    dataServices,
    dataClinics,
    dataClinic,
    selectedService,
    setSelectedClinic,
    setSelectedService,
  } = useHome();
  const { isAuthenticated } = useAuth();

  const [isShowClinic, setIsShowClinic] = useState(false);

  useEffect(() => {
    if (window.location.pathname.split("/").includes("dashboard")) {
      window.history.pushState({}, "", "/pasien");
    }
  }, []);

  useEffect(() => {
    if (
      isShowClinic &&
      (selectedService || selectedService === 0) &&
      dataClinic.length > 0
    ) {
      modal.show(
        () => (
          <ModalClinic
            data={dataClinic}
            handleSelect={(idClinic) => handleCheckReservation(idClinic)}
            setIsShowClinic={setIsShowClinic}
          />
        ),
        {
          title: "Pilih Klinik",
          isBorderTitle: true,
          size: "lg",
        }
      );
    }

    // eslint-disable-next-line
  }, [selectedService, dataClinic, isShowClinic]);

  const dataLink = [
    { label: "Untuk Klinik", url: "/", isLink: true },
    { label: "Untuk Pasien", url: "", isActive: true },
    { label: "Lokasi Klinik", url: "/" },
  ];

  const handleCheckReservation = (id) => {
    modal.dismiss();
    setIsShowClinic(!isShowClinic);
    setSelectedClinic(id);
    history.push(
      `/reservation-pasien?serviceId=${selectedService}&clinicId=${id}`
    );
  };

  return (
    <div style={{ background: "white" }}>
      <Navbar dataLink={dataLink} />
      <div className={styles.heroWrapper}>
        <div className={styles.wrapperDescHero}>
          <p className={styles.titleHero}>
            Pelayanan Kesehatan Terbaik dari Klinik
          </p>
          <p className={styles.descHero}>
            Untuk Anda dan Keluarga, di mana saja.
          </p>
          {!isAuthenticated && (
            <Button
              className={styles.buttonHero}
              onClick={() =>
                modal.show(
                  () => (
                    <ModalService
                      data={dataServices}
                      handleSelect={(id) => {
                        setIsShowClinic(!isShowClinic);
                        setSelectedService(id);
                      }}
                    />
                  ),
                  {
                    title: "Pilih Layanan",
                    isBorderTitle: true,
                  }
                )
              }
            >
              Pesan Sekarang
            </Button>
          )}
        </div>
        <img
          src="/assets/svg/hero-image-pasien.svg"
          alt=""
          className="img-fluid"
        />
      </div>

      <div className={styles.keunggulanWrapper}>
        <p className={styles.titleSection}>Keunggulan Kami</p>
        <div className={styles.keunggulanItemWrapper}>
          {[1, 2, 3].map((_, index) => (
            <div className={styles.keunggulanItem} key={index}>
              <div className={styles.iconItemWrapper}>
                <i className="fas fa-briefcase-medical"></i>
              </div>
              <p className={styles.keunggulanItemText}>
                Layanan kesehatan berkualitas sesuai kebutuhan
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.serviceWrapper}>
        <p className={styles.titleSection}>Layanan Kami</p>
        <div className={styles.serviceItemWrapper}>
          {dataServices?.map((item, index) => (
            <div className={styles.serviceItem} key={index}>
              <div className={styles.serviceWrapperDesc}>
                <p className={styles.serviceItemTitle}>{item.name}</p>
                <p className={styles.serviceItemDesc}>
                  Dapatkan kulit yang sehat serta bercahaya di Klinik Spesialis
                  Kulit dan Dokter Kecantikan Sistem Klinik.
                </p>
              </div>
              <img src="/assets/svg/service-1.svg" alt="" />
            </div>
          ))}
          {dataServices?.length < 2 && (
            <div className={styles.serviceItem}>
              <div className={styles.serviceWrapperDesc}>
                <p className={styles.serviceItemTitle}>Coming Soon</p>
              </div>
              <img src="/assets/svg/service-comingsoon.svg" alt="" />
            </div>
          )}
        </div>
      </div>

      <div className={styles.clinicWrapper}>
        <p className={styles.titleSection}>Klinik Kami</p>
        <div className={styles.clinicWrapperList}>
          {dataClinics.length > 0 &&
            dataClinics.slice(0, 3)?.map((item, index) => (
              <div className={styles.clinicItem} key={index}>
                <img
                  src={
                    item?.mainPhoto ||
                    "https://dummyimage.com/1000x400/999999/fff"
                  }
                  alt=""
                />
                <div className={styles.clinicItemInfo}>
                  <p className={styles.clinicInfoTitle}>{item.name}</p>
                  <div className={styles.infoAddress}>
                    <i className="fas fa-map-marker-alt"></i>
                    <p>{item.address}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <p className={styles.clinicGoAll}>Lihat Semua Klinik</p>
      </div>
      <Footer dataLink={dataLink} />
    </div>
  );
}
