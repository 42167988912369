import React, { useContext, useState } from "react";
/// React router dom
import { ROOT_DASHBOARD } from "../../../utils/constants";
import { Link } from "react-router-dom";
import { logoImage } from "../../../utils/constants";
import { ThemeContext } from "../../../context/ThemeContext";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { openMenuToggle, windowWidth, logoClinic } = useContext(ThemeContext);
  return (
    <div className="nav-header">
      {/* DY: LATER CHANGE TO DASHBOARD */}
      <Link to={ROOT_DASHBOARD} className="brand-logo">
        <img
          src={toggle || windowWidth <= 768 ? logoImage.mini : logoClinic}
          className="img-fluid"
          alt="logo"
        />
      </Link>

      <div
        className="nav-control"
        id="nav-burger"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
