import React, { useContext, createContext, useState } from "react";
import { setItemToStorage, getItemStorage } from "../utils/utils";
import { useTranslation } from "react-i18next";

export const LocatizeContext = createContext({
  getText: () => "",
  setLocale: (locale) => {
    // DO NOTHING
  },
  defaultLocale: "id",
});

export const useLocalization = () => useContext(LocatizeContext);

const LocalizationProvider = ({ children }) => {
  const { i18n, t } = useTranslation();
  const [defaultLocale, setDefaultLocale] = useState(
    getItemStorage("locale") || "id"
  );

  const getText = (name, obj) => {
    if (!obj) return t(name, { defaultValue: "" });

    return t(name, {
      defaultValue: "",
      ...obj,
    });
  };

  const setLocale = (locale) => {
    setDefaultLocale(locale);
    i18n.changeLanguage(locale);
    setItemToStorage("locale", locale);
  };

  const localize = {
    getText,
    setLocale,
    defaultLocale,
  };

  return (
    <LocatizeContext.Provider value={{ ...localize }}>
      {children}
    </LocatizeContext.Provider>
  );
};

export default LocalizationProvider;
