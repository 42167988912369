import instance from "../config/interceptor";
import axios from "axios";

export const uploadImage = async (file, post_type, setHeaderStyle) => {
  const file_extension = "." + file.type.split("/")[1];
  // eslint-disable-next-line
  const api_path = process.env.REACT_APP_BASE_API_URL + "/dashboard/files";

  const response = await instance.post(
    api_path,
    {},
    {
      params: { extension: file_extension, type: post_type },
    }
  );

  const completedUrl = response?.data;
  const unintercepted = axios.create();
  const upload = await unintercepted.put(completedUrl, file, {
    headers: {
      "Content-type": file.type,
      "Access-Control-Allow-Origin": "*",
    },
  });
  if (upload.status !== 200) {
    throw new Error(response.message || "Something went wrong!");
  }
  const url = response?.data?.split("?X")[0];

  if (setHeaderStyle) setHeaderStyle(url);

  return url;
};

export const getDiagnosisOption = async ({ page = 0, keyword, size = 10 }) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/icd10?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);
    if (keyword) link = link.concat("&keyword=" + keyword);

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getServicesType = async () => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/onboarding/services`;

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getBusinessType = async () => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/onboarding/business-types`;

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getDoctorByBusinessId = async (id) => {
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/service-provider/provider/${id}`;

    const response = await instance.get(link);

    return response;
  } catch (error) {
    return error;
  }
};
