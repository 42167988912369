import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { PER_PAGE_SIZE } from "../utils/constants";
import { getStafAll } from "../services/StafService";
import { useNotification } from "../context/NotificationProvider";
import { useLocalization } from "../context/LocalizeContextProvider";

export default function useStaff() {
  const notification = useNotification();
  const localize = useLocalization();

  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [dataStaf, setDataStaf] = useState([]);
  const [optionStaf, setOptionStaf] = useState([]);

  const { isLoading, mutate: mutateGetStaf } = useMutation(getStafAll, {
    onSuccess: (res) => {
      if (res.status === 200 || res.status === 201) {
        setDataStaf(res?.data);
        setTotalEntries(Number(res.headers["x-total-count"]));
        setTotalPage(
          Math.ceil(Number(res.headers["x-total-count"]) / PER_PAGE_SIZE)
        );
        if (res?.data.length > 0) {
          const datas = [];
          res.data.forEach((option) => {
            datas.push({
              value: option.id,
              label: option.name,
            });
          });
          setOptionStaf(datas);
        } else {
          setOptionStaf([]);
        }
      } else {
        notification.error(
          res?.data?.detail || localize.getText("unknownMessageError")
        );
      }
    },
    onError: () => {
      notification.error(localize.getText("unknownMessageError"));
    },
  });

  const handlePagination = (value) => {
    setPage(value);
    mutateGetStaf({
      page,
    });
  };

  return {
    page,
    totalPage,
    totalEntries,
    dataStaf,
    isLoading,
    handlePagination,
    mutateGetStaf,
    optionStaf,
  };
}
