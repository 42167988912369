import React from "react";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import cx from "classnames";

import AsyncSelect from "../../../../components/AsyncSelect";
import { getDiagnosisOption } from "../../../../../services/GeneralService";
import MyAnnotation from "../../../../components/MyAnnotation";
import InputField from "../../../../components/InputField";
import RadioField from "../../../../components/RadioField";
import UploadFileField from "../../../../components/UploadFileField";
import TitlePage from "../../../../components/TitlePage";
import Button from "../../../../components/Button";
import { Gender } from "../../../../../utils/constants";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { useAuth } from "../../../../../context/AuthProvider";
import { roleUser } from "../../../../../utils/constants";

export default function AssesmentComponent({
  canvasRef,
  dataAnatomis,
  activeAnnotation,
  handleSetPosition,
  setSelectedImage,
  handleAddPosition,
  setClickLayer,
  clickLayer,
  activeType,
  selectedImage,
  handleChangeType,
  handleUndo,
  handleSubmitForm,
  isLoadingSave = false,
  initialValues,
  summaryAnnotation,
  handleOnChange = () => undefined,
  isDoctor = true,
}) {
  const localize = useLocalization();
  const history = useHistory();
  const { state } = useLocation();
  const { role } = useAuth();
  const isView = state?.isView || role === roleUser.ROLE_STAFF;

  const loadOptions = async (inputValue, callback) => {
    if (inputValue) {
      const options = [];
      const { data } = await getDiagnosisOption({ keyword: inputValue });
      if (data && data.length > 0) {
        data.forEach((item) => {
          options.push({
            value: item.fullCode,
            label: item.shortDescription,
          });
        });
      }
      callback(options);
    }
  };

  const RenderActionButton = () => {
    return (
      <div className="d-flex justify-content-between mt-3 flex-wrap gap-3">
        <div className="d-flex gap-3">
          <Button
            label={localize.getText("cancel")}
            variant="outline-primary"
            onClick={() => history.goBack()}
          />
          {!isView && (
            <Button
              label={localize.getText("save")}
              type="submit"
              loading={isLoadingSave}
            />
          )}
        </div>
        <Link
          to={{
            pathname: "/history",
            search: `?id=${initialValues.patientId}`,
          }}
        >
          <Button label={localize.getText("history")} variant="info" />
        </Link>
      </div>
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        <TitlePage text={localize.getText("assesment")} isBack />
        <div>
          <Form
            onSubmit={(value) => handleSubmitForm(value)}
            initialValues={{
              ...initialValues,
              examination: summaryAnnotation,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.anamnesia && isDoctor) {
                errors.anamnesia = localize.getText(
                  "generalRequiredFieldValidation",
                  {
                    fieldName: localize.getText("anamnesia"),
                  }
                );
              }
              if (!values.examination && isDoctor) {
                errors.examination = localize.getText(
                  "generalRequiredFieldValidation",
                  {
                    fieldName: localize.getText("examination"),
                  }
                );
              }
              if (!values.treatment) {
                errors.treatment = localize.getText(
                  "generalRequiredFieldValidation",
                  {
                    fieldName: localize.getText("treatment"),
                  }
                );
              }
              if (
                values.diagnosis &&
                values.diagnosis.length === 0 &&
                isDoctor
              ) {
                errors.diagnosis = localize.getText(
                  "generalRequiredFieldValidation",
                  {
                    fieldName: localize.getText("diagnosis"),
                  }
                );
              }

              return errors;
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="d-flex mt-4 gap-5 flex-wrap">
                  <div className="flex-1 d-flex flex-column gap-3">
                    {initialValues?.satuSehatEncounterId && (
                      <InputField
                        label={
                          localize.getText("satuSehat") +
                          " - " +
                          localize.getText("encounterId")
                        }
                        disabled={true}
                        value={initialValues?.satuSehatEncounterId}
                      />
                    )}
                    {isDoctor && (
                      <>
                        <Field name="allergies">
                          {({ input, meta }) => (
                            <AsyncSelect
                              label={localize.getText("alergies")}
                              placeholder="Select Alergies..."
                              options={initialValues.allergies}
                              isMulti
                              isCreatable
                              error={meta.touched && meta.error}
                              onChange={(value) =>
                                handleOnChange("allergies", value)
                              }
                              onBlur={input.onBlur}
                              disabled={isView}
                              value={initialValues.allergies}
                            />
                          )}
                        </Field>
                        <Field name="historyOfIllness">
                          {({ input, meta }) => (
                            <InputField
                              label={localize.getText("historyOfIllness")}
                              type="textarea"
                              rows={6}
                              error={meta.touched && meta.error}
                              onChange={(value) =>
                                handleOnChange("historyOfIllness", value)
                              }
                              onBlur={input.onBlur}
                              value={initialValues.historyOfIllness}
                              disabled={isView}
                            />
                          )}
                        </Field>
                        <Field name="familyHistoryOfDisease">
                          {({ input, meta }) => (
                            <InputField
                              label={localize.getText("familyHistoryOfDisease")}
                              type="textarea"
                              rows={6}
                              error={meta.touched && meta.error}
                              onChange={(value) =>
                                handleOnChange("familyHistoryOfDisease", value)
                              }
                              onBlur={input.onBlur}
                              value={initialValues.familyHistoryOfDisease}
                              disabled={isView}
                            />
                          )}
                        </Field>
                        <Field name="anamnesia">
                          {({ input, meta }) => (
                            <InputField
                              label={localize.getText("anamnesia")}
                              type="textarea"
                              rows={6}
                              error={meta.touched && meta.error}
                              onChange={(value) =>
                                handleOnChange("anamnesia", value)
                              }
                              onBlur={input.onBlur}
                              value={initialValues.anamnesia}
                              disabled={isView}
                            />
                          )}
                        </Field>
                        <Field name="examination">
                          {({ input, meta }) => (
                            <InputField
                              label={localize.getText("examination")}
                              type="textarea"
                              rows={6}
                              error={meta.touched && meta.error}
                              onChange={(value) =>
                                handleOnChange("examination", value)
                              }
                              onBlur={input.onBlur}
                              value={input.value}
                              disabled
                            />
                          )}
                        </Field>
                        <Field name="diagnosis">
                          {({ input, meta }) => (
                            <AsyncSelect
                              label={localize.getText("diagnosis")}
                              placeholder="Select Diagnosis..."
                              loadOptions={loadOptions}
                              isMulti
                              error={meta.touched && meta.error}
                              onChange={(value) =>
                                handleOnChange("diagnosis", value)
                              }
                              onBlur={input.onBlur}
                              disabled={isView}
                              value={initialValues.diagnosis}
                              isAsync
                            />
                          )}
                        </Field>
                      </>
                    )}

                    <Field name="treatment">
                      {({ input, meta }) => (
                        <InputField
                          label={localize.getText("treatment")}
                          type="textarea"
                          rows={6}
                          error={meta.touched && meta.error}
                          onChange={(value) =>
                            handleOnChange("treatment", value)
                          }
                          onBlur={input.onBlur}
                          value={initialValues.treatment}
                          disabled={isView}
                        />
                      )}
                    </Field>

                    {isDoctor && (
                      <Field name="fileUploads">
                        {({ input, meta }) => (
                          <UploadFileField
                            label={localize.getText("fileUpload")}
                            error={meta.touched && meta.error}
                            onChange={(value) =>
                              handleOnChange("fileUploads", value)
                            }
                            accept="file/*"
                            value={initialValues?.fileUploads}
                            disabled={isView}
                          />
                        )}
                      </Field>
                    )}

                    <BrowserView>
                      <RenderActionButton />
                    </BrowserView>
                  </div>

                  <div
                    className={cx(
                      "text-center mobile-full-width",
                      !isDoctor && "d-none"
                    )}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <RadioField
                        datas={Gender()}
                        value={activeType}
                        onChange={(value) => handleChangeType(value)}
                      />
                      {activeAnnotation &&
                        activeAnnotation?.annotationDetails?.length > 0 && (
                          <div
                            className="d-flex align-items-center gap-2 cursor-pointer"
                            onClick={handleUndo}
                          >
                            <i className="fas fa-undo"></i>
                            <p className="mb-0">{localize.getText("undo")}</p>
                          </div>
                        )}
                    </div>
                    <MyAnnotation
                      canvasRef={canvasRef}
                      positionAnnotation={activeAnnotation}
                      handleSetPosition={(e) => handleSetPosition(e)}
                      handleAddPosition={handleAddPosition}
                      setClickLayer={setClickLayer}
                      clickLayer={clickLayer}
                      isView={isView}
                    />
                    <div
                      className="d-flex mt-4 justify-content-between table-responsive"
                      style={{ maxWidth: "330px", overflowX: "scroll" }}
                    >
                      {dataAnatomis
                        .filter((value) => value.type === activeType)
                        .map((item, index) => (
                          <img
                            src={item.imageUrl}
                            key={index}
                            alt=""
                            onClick={() => setSelectedImage(item.subType)}
                            style={{
                              width: 80,
                              height: 180,
                              objectFit: "cover",
                              cursor: "pointer",
                              border:
                                selectedImage === item.subType
                                  ? "solid 1px #F0F0F0"
                                  : "",
                            }}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <MobileView>
                  <RenderActionButton />
                </MobileView>
              </form>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
