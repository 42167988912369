import React from "react";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import { Form, Field } from "react-final-form";
import { Gender } from "../../../../utils/constants";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import styles from "./StepOneOnboarding.module.css";

export default function StepOneOnboarding({
  valueFormStepOne,
  handleChangeFormStepOne,
}) {
  const localize = useLocalization();
  return (
    <div className={styles.wrapper}>
      <div>
        <h1 className={styles.titleStep}>
          {localize.getText("stepNumber", { number: 1 })}
        </h1>
        <p className={styles.descStep}>{localize.getText("descStepOne")}</p>
      </div>

      <Form
        onSubmit={(value) => console.log(value, "DY: this is value")}
        initialValues={{
          phoneNumber: valueFormStepOne.userPhoneNumber,
          address: valueFormStepOne.userAddress,
          dateOfBirth: valueFormStepOne.userDob,
          gender: valueFormStepOne.userGender,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.phoneNumber) {
            errors.phoneNumber = localize.getText(
              "generalRequiredFieldValidation",
              { fieldName: localize.getText("phoneNumber") }
            );
          }
          if (!values.dateOfBirth) {
            errors.dateOfBirth = localize.getText(
              "generalRequiredFieldValidation",
              { fieldName: localize.getText("dateOfBirth") }
            );
          }
          if (!values.gender) {
            errors.gender = localize.getText("generalRequiredFieldValidation", {
              fieldName: localize.getText("gender"),
            });
          }

          return errors;
        }}
      >
        {() => (
          <>
            <form className={styles.wrapperForm}>
              <Field name="phoneNumber">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("phoneNumber")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("phoneNumber"),
                    })}
                    type="number"
                    error={meta.touched && meta.error}
                    onChange={(value) =>
                      handleChangeFormStepOne("userPhoneNumber", value)
                    }
                    onBlur={input.onBlur}
                    value={input.value}
                  />
                )}
              </Field>
              <Field name="dateOfBirth">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("dateOfBirth")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("dateOfBirth"),
                    })}
                    type="date"
                    error={meta.touched && meta.error}
                    onChange={(value) =>
                      handleChangeFormStepOne("userDob", value)
                    }
                    onBlur={input.onBlur}
                    value={input.value}
                  />
                )}
              </Field>
              <Field name="gender">
                {({ input, meta }) => (
                  <SelectField
                    label={localize.getText("gender")}
                    data={Gender()}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("gender"),
                    })}
                    error={meta.touched && meta.error}
                    onChange={(value) =>
                      handleChangeFormStepOne("userGender", value)
                    }
                    onBlur={input.onBlur}
                    value={input.value}
                  />
                )}
              </Field>
              <Field name="address">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("address")}
                    placeholder={localize.getText("placeholderField", {
                      fieldName: localize.getText("address"),
                    })}
                    type="textarea"
                    error={meta.touched && meta.error}
                    onChange={(value) =>
                      handleChangeFormStepOne("userAddress", value)
                    }
                    onBlur={input.onBlur}
                    value={input.value}
                  />
                )}
              </Field>
            </form>
          </>
        )}
      </Form>
    </div>
  );
}
