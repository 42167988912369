import React from "react";
import classNames from "classnames";
import { Form, Field } from "react-final-form";

import UploadImageAvatar from "../../../components/UploadImageAvatar";
import { validEmail, Gender } from "../../../../utils/constants";
import InputField from "../../../components/InputField";
import RadioField from "../../../components/RadioField";
import TitlePage from "../../../components/TitlePage";
import Button from "../../../components/Button";
import styles from "./Profile.module.css";
import { useLocalization } from "../../../../context/LocalizeContextProvider";

export default function ProfileComponent({
  initialValues,
  loadingSubmit = false,
  handleSubmitForm = () => undefined,
}) {
  const localize = useLocalization();

  return (
    <div className="card">
      <div className={classNames("card-body", styles.wrapper)}>
        <div className={styles.wrapperTitle}>
          <TitlePage text="Edit Profile" />
        </div>
        <Form
          onSubmit={handleSubmitForm}
          initialValues={{
            name: initialValues?.name,
            email: initialValues?.email,
            phoneNumber: initialValues?.phoneNumber,
            dateOfBirth: initialValues?.dateOfBirth,
            gender: initialValues?.gender,
            address: initialValues?.address,
            imageUrl: initialValues?.imageUrl,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("name"),
              });
            }
            if (!values.email) {
              errors.email = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("email") }
              );
            }
            if (values.email && !values.email.match(validEmail)) {
              errors.email = localize.getText("notValidField", {
                fieldName: localize.getText("email"),
              });
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("phoneNumber") }
              );
            }
            if (!values.dateOfBirth) {
              errors.dateOfBirth = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("dateOfBirth") }
              );
            }
            if (!values.gender) {
              errors.gender = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("gender") }
              );
            }
            if (!values.address) {
              errors.address = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("address") }
              );
            }
            if (!values.imageUrl) {
              errors.imageUrl = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("photoProfile") }
              );
            }

            return errors;
          }}
        >
          {({ handleSubmit }) => (
            <>
              <form className={styles.wrapperForm} onSubmit={handleSubmit}>
                <Field name="imageUrl">
                  {({ input, meta }) => (
                    <div className={styles.wrapperProfileImage}>
                      <UploadImageAvatar
                        value={input.value}
                        error={meta.touched && meta.error}
                        onChange={(value) => input.onChange(value)}
                        isRounded
                      />
                    </div>
                  )}
                </Field>
                <Field name="name">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("name")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("name"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="email">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("email")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("email"),
                      })}
                      type="email"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                    />
                  )}
                </Field>

                <Field name="phoneNumber">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("phoneNumber")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("phoneNumber"),
                      })}
                      type="number"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                    />
                  )}
                </Field>

                <Field name="dateOfBirth">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("dateOfBirth")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("dateOfBirth"),
                      })}
                      type="date"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="gender">
                  {({ input, meta }) => (
                    <RadioField
                      label={localize.getText("gender")}
                      datas={Gender()}
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.touched && meta.error}
                    />
                  )}
                </Field>
                <Field name="address">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("address")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("address"),
                      })}
                      type="textarea"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                    />
                  )}
                </Field>

                <Button
                  label={localize.getText("save")}
                  style={{ width: 180, marginTop: 42 }}
                  type="submit"
                  loading={loadingSubmit}
                />
              </form>
            </>
          )}
        </Form>
      </div>
    </div>
  );
}
