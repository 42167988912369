import instance from "../config/interceptor";
import { PER_PAGE_SIZE } from "../utils/constants";

export const getActiveVisitAll = async ({
  page = 1,
  searchName,
  searchNoTelp,
  searchDate,
  size = PER_PAGE_SIZE,
}) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/visits?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);
    if (searchName) link = link.concat("&name.equals=" + searchName);
    if (searchNoTelp) link = link.concat("&phoneNumber.equals=" + searchNoTelp);
    if (searchDate) link = link.concat("&dob.equals=" + searchDate);

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getActiveVisitSingle = async (patientId) => {
  let _response;
  try {
    const link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/visits/${patientId}`;

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getActiveVisitReservation = async (id) => {
  try {
    const response = await instance.get(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/patient/active-visit/${id}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
