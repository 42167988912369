import React, { useState, useCallback, useRef } from "react";
import Webcam from "react-webcam";
import cx from "classnames";
import styles from "./TakePhotoCamera.module.css";
import { base64ToFile } from "../../../utils/utils";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";
const videoConstraints = {
  facingMode: FACING_MODE_USER,
};

export default function TakePhotoCamera({ onCapture = () => undefined }) {
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [captureImage, setCaptureImage] = useState("");

  const handleChangeFacing = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  const handleClickCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    setCaptureImage(imageSrc);
  }, [webcamRef]);

  const handleDeleteCapture = () => {
    setCaptureImage("");
  };

  const handleSaveCapture = () => {
    const fileUpload = base64ToFile(captureImage, "captureImage.jpeg");
    const filePreview = captureImage;

    onCapture({
      file: fileUpload,
      preview: filePreview,
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperCamera}>
        {captureImage ? (
          <img src={captureImage} alt="" />
        ) : (
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              ...videoConstraints,
              facingMode,
            }}
            ref={webcamRef}
            className={styles.webcam}
          />
        )}
      </div>
      <div className={styles.wrapperAction}>
        <div
          className={cx(
            !captureImage && styles.visibilityHidden,
            styles.buttonHelp
          )}
          onClick={handleDeleteCapture}
        >
          <i className="fas fa-times"></i>
        </div>
        <div
          className={cx(
            captureImage && styles.visibilityHidden,
            styles.buttonTake
          )}
          onClick={handleClickCapture}
        >
          <div className={styles.buttonTakeSmall}></div>
        </div>
        <div
          className={cx(styles.buttonHelp)}
          onClick={() =>
            captureImage ? handleSaveCapture() : handleChangeFacing()
          }
        >
          {captureImage ? (
            <i className="fas fa-check"></i>
          ) : (
            <i className="fas fa-sync-alt"></i>
          )}
        </div>
      </div>
    </div>
  );
}
