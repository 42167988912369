import React from "react";
import FormOrganization from "../FormOrganization";

export default function DetailOrganizationComponent({ initialValues }) {
  return (
    <FormOrganization
      handleSubmit={() => {}}
      isLoadingSubmit={false}
      initialValues={initialValues}
      isView
    />
  );
}
