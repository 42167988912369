import React from "react";
import FormOrganization from "../FormOrganization";

export default function EditOrganizationComponent({
  handleSubmit,
  isLoadingSubmit,
  initialValues,
}) {
  return (
    <FormOrganization
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={initialValues}
      isEdit
    />
  );
}
