import React from "react";
import classNames from "classnames";
import styles from "./LoadingSection.module.css";
import { Spinner } from "react-bootstrap";

export default function LoadingSection({ isPage, className }) {
  return (
    <div className={classNames(className, { [styles.fullPage]: isPage })}>
      <Spinner
        animation="border"
        variant="primary"
        size="lg"
        style={{ width: 45, height: 45 }}
      />
    </div>
  );
}
