import React from "react";

export default function RadioSingleField({
  label,
  id,
  value = false,
  onChange = () => undefined,
  onBlur = () => undefined,
  error,
  disabled = false,
}) {
  return (
    <div class="form-group mb-3 w-100">
      {label && (
        <label className="mb-1 ">
          <strong>{label}</strong>
        </label>
      )}
      <div class="col-sm-10 d-flex gap-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name={label}
            id={id + label}
            value={value}
            checked={!!value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
            disabled={disabled}
          />
          <label className="form-check-label" htmlFor={label}>
            {label}
          </label>
        </div>
      </div>
      {error && (
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: "block" }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
