import React, { useState, useEffect } from "react";
import moment from "moment";
import cx from "classnames";
import styles from "./InputScheduleField.module.css";
import DatePickerIcon from "../DatePickerIcon";
import Spinner from "../Spinner";
import {
  getTimeSlotOneHourFormat,
  getFilteredTime,
  getThirtyMinuteTime,
} from "../../../utils/utils";

const ColumnHeader = ({ selected, date, onClickHeader, disabled, label }) => {
  return (
    <div
      className={cx(
        styles["column-header"],
        !selected && !disabled && styles["hover-column-header"],
        selected
          ? `${styles.bgLightPrimary} ${styles.borderPrimary}`
          : styles["bg-neutral-10"],
        disabled && !selected && styles["text-neutral-60"],
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      )}
      onClick={() => !disabled && onClickHeader(date)}
    >
      <p className="font-weight-bold text-small w-auto mb-0 w-space-no font-w500">
        {date.format("DD MMMM")}
      </p>
      <p className="font-weight-bold text-small mb-0 w-space-no font-w500">
        {label}
      </p>
    </div>
  );
};

const PillTimeField = ({
  disabled,
  selected,
  onClick = () => undefined,
  timeStart,
  timeEnd,
  disablePill = false,
}) => {
  return (
    <div
      onClick={() => (!disabled ? onClick() : {})}
      className={cx(
        disabled && selected && styles.textNeutral10,
        selected && styles.pillSelected,
        disabled && !selected && styles.pillDisable,
        disabled && !selected && "text-muted",
        !selected && !disabled && !disablePill && styles.textPrimaryHover,
        !disablePill && !disabled && "cursor-pointer",
        styles.wrapperPillField
      )}
    >
      <p className="fs-12 font-w500 mb-0">
        {timeStart} - {timeEnd}
      </p>
    </div>
  );
};

export default function InputScheduleField({
  doctorAvailability = [],
  value = [],
  bookingDate,
  searchDate,
  onClick = () => undefined,
  onClickHeader = () => undefined,
  onClickCalendar = () => undefined,
  error,
  loading = false,
  disabled = false,
  disablePill = false,
  required = false,
  noHeader = false,
  label,
  labelClass,
  durationVisit,
  doctorId,
}) {
  const [dayWeek, setDayWeek] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(60);

  useEffect(() => {
    if (durationVisit) {
      setSelectedFilter(durationVisit);
    }
  }, [durationVisit]);

  useEffect(() => {
    let weekData;
    if (!searchDate) {
      weekData = getDayDatas(bookingDate);
    } else {
      weekData = getDayDatas(searchDate);
    }
    setDayWeek(weekData);
  }, [searchDate, bookingDate]);

  const isExistOneHourValue = (item, selectedValue) => {
    return selectedValue.some((value) => value.id === item.id);
  };

  const handleClick = (item) => {
    // const selectedFourItem = [];
    if (selectedFilter === 15) {
      // selectedFourItem.push(item);
      // let secondSelectedItem = null;
      // let thirdSelectedItem = null;
      // if (value.length === 0) {
      //   doctorAvailability.forEach((value) => {
      //     if (value.availability_start === item.availability_end) {
      //       secondSelectedItem = value;
      //       selectedFourItem.push(value);
      //     }

      //     if (
      //       value.availability_start === secondSelectedItem?.availability_end
      //     ) {
      //       thirdSelectedItem = value;
      //       selectedFourItem.push(value);
      //     }

      //     if (
      //       value.availability_start === thirdSelectedItem?.availability_end
      //     ) {
      //       selectedFourItem.push(value);
      //     }
      //   });
      //   onClick([...value, ...selectedFourItem]);
      //   return;
      // }
      if (value.filter((time) => time.id === item.id).length > 0) {
        onClick(value.filter((time) => time.id !== item.id));
        return;
      }

      onClick([...value, item]);
    } else {
      const getValue = doctorAvailability.filter((availableSlot) => {
        const startTime = moment(
          `1970-01-01T${availableSlot.availability_start}:00`,
          "YYYY-MM-DDTHH:mm:ss"
        );
        const endTime = moment(
          `1970-01-01T${availableSlot.availability_end}:00`,
          "YYYY-MM-DDTHH:mm:ss"
        );
        const selectedStartTime = moment(
          `1970-01-01T${item.availability_start}:00`,
          "YYYY-MM-DDTHH:mm:ss"
        );
        const selectedEndTime = moment(
          `1970-01-01T${item.availability_end}:00`,
          "YYYY-MM-DDTHH:mm:ss"
        );

        return (
          startTime.isSameOrAfter(selectedStartTime) &&
          endTime.isSameOrBefore(selectedEndTime) &&
          endTime.isSameOrAfter(selectedStartTime) &&
          startTime.isSameOrBefore(selectedEndTime)
        );
      });

      const filteredValues = value.filter(
        (item) => !getValue.some((deleted) => deleted.id === item.id)
      );
      const isExist = getValue.every((item) =>
        isExistOneHourValue(item, value)
      );

      if (isExist) {
        onClick(filteredValues);
      } else {
        onClick([...value, ...getValue]);
      }
    }
  };

  const disableTime = (index, item) => {
    const currentDate = moment().format("DD-MM-YYYY");
    const selectedDate = bookingDate.format("DD-MM-YYYY");
    const lastTime =
      doctorTimeAvailableValue[doctorTimeAvailableValue.length - 1];
    const doctorTime = doctorTimeAvailable;

    if (currentDate === selectedDate) {
      if (item.availability_start < moment().format("HH:mm")) {
        return true;
      }
    }

    const selectedIndex =
      doctorTimeAvailableValue.length > 0
        ? doctorTime.findIndex(
            (time) => time.availability_start === lastTime.availability_start
          )
        : 0;
    const targetIndex =
      doctorTimeAvailableValue.length > 0
        ? doctorTime.findIndex(
            (time) => time.availability_start === lastTime.availability_end
          )
        : 0;

    if (value.length > 0 && index !== selectedIndex) {
      return true;
    }

    if (index < targetIndex && index !== selectedIndex) {
      return true;
    }

    if (
      doctorTimeAvailableValue.length !== 0 &&
      index > targetIndex &&
      doctorTimeAvailableValue.filter(
        (time) => time.availability_start === item.availability_start
      ).length === 0
    ) {
      return true;
    }

    return false;
  };

  const getDayDatas = (currentDate) => {
    const weekData = [];
    const startDay = moment(currentDate);

    for (let i = 0; i < 7; i++) {
      const date = startDay.clone().add(i, "day");
      const week = date.day() === 0 ? 7 : date.day();
      const label = date.format("dddd");

      weekData.push({ week, date, label });
    }

    return weekData;
  };

  const isIncludedOneHour = (timeSlot) => {
    const groupOneHour =
      getTimeSlotOneHourFormat(value).filter(
        (item) =>
          item.availability_start === timeSlot.availability_start &&
          item.availability_end === timeSlot.availability_end
      ).length > 0;

    if (groupOneHour) {
      return true;
    }

    return false;
  };

  const isIncludedThirtyMinutes = (timeSlot) => {
    const groupThirtyMinutes =
      getThirtyMinuteTime(value).filter(
        (item) =>
          item.availability_start === timeSlot.availability_start &&
          item.availability_end === timeSlot.availability_end
      ).length > 0;

    return groupThirtyMinutes;
  };

  const isIncludedTimeSlot = (timeSlot, filter) => {
    if (filter === 60) {
      return isIncludedOneHour(timeSlot);
    } else if (filter === 30) {
      return isIncludedThirtyMinutes(timeSlot);
    }
    return false;
  };

  const doctorTimeAvailable = getFilteredTime(
    selectedFilter,
    doctorAvailability
  );
  const doctorTimeAvailableValue = getFilteredTime(selectedFilter, value);

  const isAvailableSchedule = doctorTimeAvailable.length > 0;

  return (
    <div className="d-flex flex-column gap-1 w-100">
      {label && (
        <label className={cx(labelClass, "mb-0 font-w600")}>
          {label} {required && <span className="text-danger">*</span>}
        </label>
      )}

      <div className="d-flex flex-column w-100">
        {!noHeader && (
          <div className="header-input-schedule d-flex justify-content-between w-100">
            <div className={styles.wrapperColumnHeader}>
              {dayWeek.map((item, index) => (
                <ColumnHeader
                  date={item.date}
                  label={item.label}
                  key={index}
                  selected={
                    bookingDate &&
                    item.date.format("DD-MM-YYYY") ===
                      bookingDate.format("DD-MM-YYYY")
                  }
                  onClickHeader={onClickHeader}
                  disabled={disabled}
                />
              ))}
            </div>
            <div className={styles.wrapperColumnDate}>
              <DatePickerIcon
                value={bookingDate}
                onChange={(value) => onClickCalendar(value)}
                disabled={disabled}
                shouldDisableDate={(currentDate) => {
                  const currentDateWithoutTime =
                    moment(currentDate).startOf("day");
                  const todayWithoutTime = moment().startOf("day");
                  const threeMonthsFromNow = moment()
                    .add(3, "months")
                    .startOf("day");

                  return (
                    currentDateWithoutTime.isBefore(todayWithoutTime) ||
                    currentDateWithoutTime.isAfter(threeMonthsFromNow)
                  );
                }}
              />
            </div>
          </div>
        )}
        <Spinner loading={loading}>
          <div
            style={noHeader ? { border: "none" } : {}}
            className={cx(
              !isAvailableSchedule && styles.minHeight120,
              styles.wrapperSelectDate
            )}
          >
            {isAvailableSchedule ? (
              <div className={styles.wrapperTimeSlot}>
                {doctorTimeAvailable.map((itemTime, indexTime) => (
                  <PillTimeField
                    timeStart={itemTime.availability_start}
                    timeEnd={itemTime.availability_end}
                    selected={
                      selectedFilter === 15
                        ? value.filter((value) => value.id === itemTime.id)
                            .length > 0
                        : isIncludedTimeSlot(itemTime, selectedFilter)
                    }
                    key={indexTime}
                    onClick={() => !disabled && handleClick(itemTime)}
                    disabled={disableTime(indexTime, itemTime)}
                    disablePill={disablePill}
                  />
                ))}
              </div>
            ) : !loading ? (
              <p className="mb-0 h5 text-center font-w500">
                {!doctorId
                  ? "Pilih dokter terlebih dahulu"
                  : "Jadwal Tidak tersedia"}
              </p>
            ) : null}
          </div>
        </Spinner>
      </div>

      {error && <p className="text-danger fz-16 font-w500">{error}</p>}
    </div>
  );
}
