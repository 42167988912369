import React from "react";
import moment from "moment";
import { Row } from "react-bootstrap";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { useHistory } from "react-router-dom";
import TitlePage from "../../../../components/TitlePage";
import Table from "../../../../components/Table";
import { formatOptionsToArray } from "../../../../../utils/utils";
import PillItem from "../../../../components/PillItem";
import { CardItemFile } from "../../../../components/UploadFileField";

export default function HistoryVisitComponent({
  dataHistory,
  isLoading = false,
  totalPage = 0,
  totalEntries = 0,
  activePage,
  handlePagination,
  itemData = null,
}) {
  const localize = useLocalization();
  const history = useHistory();

  const columns = [
    { label: localize.getText("date") },
    { label: localize.getText("anamnesia") },
    { label: localize.getText("examination") },
    { label: localize.getText("diagnosis") },
    { label: localize.getText("uploadedFiles") },
  ];

  return (
    <div className="card">
      <div className="card-header">
        <TitlePage
          isBack
          text={
            itemData &&
            `${itemData?.name}, ${itemData?.gender?.toLowerCase()}, ${
              itemData?.dob
            }`
          }
        />
      </div>
      <div className="card-body">
        <Table
          columns={columns}
          loading={isLoading}
          activePage={activePage}
          totalPage={totalPage}
          totalShow={dataHistory?.length}
          totalEntries={totalEntries}
          handlePagination={handlePagination}
        >
          {dataHistory?.map((item, index) => (
            <tr
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push({
                  pathname: "/active-visit/assesment",
                  search: `?id=${item?.id}&patientId=${item?.patient?.id}`,
                  state: { isView: true },
                })
              }
            >
              <td>{moment(item.start).format("YYYY-MM-DD") || "-"}</td>
              <td>{item.anamnesis || "-"}</td>
              <td>{item.examination || "-"}</td>
              <td>
                <div className="d-flex flex-wrap gap-2">
                  {formatOptionsToArray(item.diagnosisJson).length > 0
                    ? formatOptionsToArray(item.diagnosisJson).map(
                        (item, index) => <PillItem text={item} key={index} />
                      )
                    : "-"}
                </div>
              </td>
              <td>
                <Row>
                  {item?.fileUploads?.map((file, index) => (
                    <CardItemFile
                      fileUpload={{ name: file, preview: file }}
                      key={index}
                      isView
                    />
                  ))}
                </Row>
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
