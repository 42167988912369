import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  postCredential,
  getCredential,
} from "../../../../../services/SatuSehat/CredentialService";
import CredentialComponent from "../components";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { useModal } from "../../../../../context/ModalProvider";
import ModalSuccessSave from "../components/ModalSuccessSave";

export default function Credential() {
  const notification = useNotification();
  const localize = useLocalization();
  const modal = useModal();

  const [credentialData, setCredentialData] = useState({
    organizationId: "",
    clientKey: "",
    secretKey: "",
  });

  const { isLoading, refetch } = useQuery(["GetCredential"], getCredential, {
    onSuccess: (res) => {
      if (res.data && res.status === 200) {
        const response = res.data;
        setCredentialData({
          organizationId: response.organizationId,
          clientKey: response.clientKey,
          secretKey: response.secretKey,
        });
      }
    },
    refetchOnWindowFocus: false,
  });

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postCredential,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          const response = res.data;
          if (response) {
            modal.show(
              () => (
                <ModalSuccessSave
                  onClickClose={() => modal.dismiss()}
                  organizatioName={response.organizationName}
                  applicationName={response.applicationName}
                  status={response.status}
                />
              ),
              {
                centered: true,
                title: `${localize.getText("notification")} ${localize.getText(
                  "satuSehat"
                )}`,
              }
            );
          }
          refetch();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleChangeField = (name, value) => {
    setCredentialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    mutateSubmit(credentialData);
  };

  return (
    <CredentialComponent
      handleChangeField={handleChangeField}
      isLoadingSubmit={isLoading || isLoadingSubmit}
      initialState={credentialData}
      handleSubmit={handleSubmit}
    />
  );
}
