import React, { useState, useEffect } from "react";
import styles from "./FormPaymentCorrection.module.css";
import { Row, Col } from "react-bootstrap";
import cx from "classnames";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useQuery } from "@tanstack/react-query";

import InputField from "../InputField";
import AsyncSelect from "../AsyncSelect";
import Button from "../Button";
import { formatNumberWithCurrency, findItemById } from "../../../utils/utils";
import { getItemOptions } from "../../../services/Handlers/MasterItemsService";
import WrapperContent from "../WrapperContent";
import { paymentItemType } from "../../../utils/constants";

const emptyPaymentItem = {
  item: null,
  qty: 1,
  amount: null,
};

export default function FormPaymentCorrection({
  onSubmit = () => undefined,
  businessId,
  value = null,
  disabledForm = false,
}) {
  const [itemOptions, setItemOptions] = useState([]);
  const [paymentData, setPaymentData] = useState([]);

  useQuery(["GetItemsOption", businessId], () => getItemOptions(businessId), {
    onSuccess: (res) => {
      if (res.data && res.status === 200) {
        const response = res.data;
        setItemOptions(response);
      }
    },
    enabled: !!businessId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (value && paymentData.length === 0 && itemOptions.length > 0) {
      const paymentItems = [];
      if (
        value.payment_items.filter(
          (value) => value.type === paymentItemType.CORRECTION
        ).length > 0
      ) {
        value.payment_items
          .filter((value) => value.type === paymentItemType.CORRECTION)
          .forEach((item) => {
            paymentItems.push({
              item: findItemById(itemOptions, item.item_id),
              qty: item.quantity,
              amount: item.amount,
            });
          });
      }
      setPaymentData(paymentItems);
    }

    // eslint-disable-next-line
  }, [value, itemOptions]);

  const handleAddPaymentItem = () => {
    const dataPaymentItems = [...paymentData];
    dataPaymentItems.push(emptyPaymentItem);

    setPaymentData(dataPaymentItems);
  };

  const handleDeletePaymentItem = (index) => {
    const dataPaymentItems = [...paymentData];
    dataPaymentItems.splice(index, 1);

    setPaymentData(dataPaymentItems);
  };

  const handleChangeForm = (value, nameItem, indexItem) => {
    const dataPayment = JSON.parse(JSON.stringify([...paymentData]));

    dataPayment.forEach((item, index) => {
      if (index === indexItem) {
        if (nameItem === "item") {
          const selectedItem = findItemById(itemOptions, value.value);
          item["amount"] = selectedItem.price;
        }
        item[nameItem] = value;
      }
    });

    setPaymentData(dataPayment);
  };

  const requiredArray = (value) =>
    value && value.length > 0 ? undefined : "Required";

  const requiredField = (value, label) => {
    if (!value) {
      return `Harap masukkan ${label}`;
    }

    return undefined;
  };

  const handleSubmitPayment = () => {
    const dataPayment =
      value?.payment_items
        .filter((value) => value.type === paymentItemType.CONSULTATION)
        .map((item) => ({
          itemId: item.item_id,
          itemName: item.item_name_original || item.item_name,
          qty: item.quantity,
          amount: item.amount,
          typeDiscount: null,
          discount: null,
          type: item.type,
        })) || [];
    const dataPaymentCorrection = paymentData.map((item) => ({
      ...item,
      itemId: item.item.value,
      itemName: item.item.label,
      type: paymentItemType.CORRECTION,
    }));

    const data = {
      method: value.payment_method,
      cardNumber: value.card_number,
      totalAmount: value?.total_amount || 0,
      totalAmountCorrection: totalAmount,
      paymentItems: [...dataPaymentCorrection, ...dataPayment],
    };

    onSubmit(data);
  };

  const totalAmount = paymentData
    .filter((item) => item.amount !== null)
    .reduce((sum, current) => {
      const amount = Number(current.amount) || 0;

      return sum + amount;
    }, 0);

  return (
    <Form
      onSubmit={handleSubmitPayment}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={{ paymentItems: [...paymentData] }}
    >
      {({ handleSubmit }) => (
        <WrapperContent
          title="Koreksi Pembayaran"
          color={"danger"}
          disableBackground={disabledForm}
        >
          <div className={cx("d-flex flex-column gap-4")}>
            {paymentData.length > 0 ? (
              <div className="d-flex flex-column gap-1">
                <Row>
                  <Col md={4}>
                    <p className={styles.labelForm}>Nama</p>
                  </Col>
                  <Col md={3}>
                    <p className={styles.labelForm}>Jumlah</p>
                  </Col>
                  <Col md={3}>
                    <p className={styles.labelForm}>Harga</p>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <FieldArray name="paymentItems" validate={requiredArray}>
                  {({ fields }) => (
                    <div className="d-flex flex-column gap-3">
                      {fields?.value.map((field, index) => (
                        <Row key={index}>
                          <Col md={4}>
                            <Field
                              name={`paymentItems[${index}].item`}
                              validate={(value) => requiredField(value, "Item")}
                            >
                              {({ input, meta }) => (
                                <AsyncSelect
                                  placeholder="Pilih Item"
                                  isGroup
                                  onBlur={input.onBlur}
                                  error={meta.touched && meta.error}
                                  value={paymentData[index]?.item}
                                  options={itemOptions}
                                  labelKey="label"
                                  valueKey="value"
                                  disabled={disabledForm}
                                  onChange={(value) =>
                                    handleChangeForm(value, "item", index)
                                  }
                                />
                              )}
                            </Field>
                          </Col>
                          <Col md={3}>
                            <Field
                              name={`paymentItems[${index}].qty`}
                              validate={(value) =>
                                requiredField(value, "Jumlah")
                              }
                            >
                              {({ input, meta }) => (
                                <InputField
                                  placeholder={`Masukkan Jumlah`}
                                  onBlur={input.onBlur}
                                  error={meta.touched && meta.error}
                                  value={paymentData[index]?.qty}
                                  disabled={disabledForm}
                                  onChange={(value) =>
                                    handleChangeForm(value, "qty", index)
                                  }
                                />
                              )}
                            </Field>
                          </Col>
                          <Col md={3}>
                            <Field
                              name={`paymentItems[${index}].amount`}
                              validate={(value) =>
                                requiredField(value, "Harga")
                              }
                            >
                              {({ input, meta }) => (
                                <InputField
                                  placeholder="Masukkan Harga"
                                  type="number"
                                  onBlur={input.onBlur}
                                  error={meta.touched && meta.error}
                                  value={paymentData[index]?.amount}
                                  disabled={disabledForm}
                                  onChange={(value) =>
                                    handleChangeForm(value, "amount", index)
                                  }
                                />
                              )}
                            </Field>
                          </Col>
                          <Col
                            md={2}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 12,
                            }}
                          >
                            <Button
                              variant="danger"
                              className={cx("mb-2")}
                              onClick={() => handleDeletePaymentItem(index)}
                              disabled={disabledForm}
                            >
                              <i class="fas fa-trash"></i>
                            </Button>
                            <Button
                              className={cx(
                                index === fields.value.length - 1 ||
                                  (fields.value.length === 1 && index === 0)
                                  ? "visible"
                                  : "invisible",
                                "mb-2"
                              )}
                              disabled={disabledForm}
                              onClick={handleAddPaymentItem}
                            >
                              <i class="fas fa-plus"></i>
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Button
                  leftIcon={<i className="fas fa-plus"></i>}
                  label="Koreksi Pembayaran"
                  variant="danger"
                  style={{ width: "200px" }}
                  onClick={handleAddPaymentItem}
                />
              </div>
            )}
            <hr className="m-0" />
            <div className="d-flex flex-column gap-4 align-items-end">
              <div className={styles.wrapperTotalAmountSuccess}>
                <p>Total Koreksi</p>
                <p>{formatNumberWithCurrency(totalAmount)}</p>
              </div>
              {!disabledForm && paymentData.length > 0 && (
                <Button
                  label="Simpan"
                  variant="danger"
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </WrapperContent>
      )}
    </Form>
  );
}
