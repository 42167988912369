import React, { useState } from "react";
import DetailCategoryItemComponent from "../../components/DetailCategoryItem";
import { useQuery } from "@tanstack/react-query";
import { getCategoryItemDetail } from "../../../../../services/Handlers/MasterCategoryItemService";
import { getParams } from "../../../../../utils/utils";
import LoadingSection from "../../../../components/LoadingSection";

export default function CategoryItemLocation() {
  const id = getParams("id");

  const [initialValues, setInitialValues] = useState({});

  const { isLoading, isFetching } = useQuery(
    ["CategoryItemDetail"],
    () => getCategoryItemDetail(id),
    {
      enabled: !!id,
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          setInitialValues({
            name: response.name,
          });
        }
      },
    }
  );

  if (isLoading || isFetching) {
    <LoadingSection />;
  }

  return <DetailCategoryItemComponent initialValues={initialValues} />;
}
