import React, { useState, useEffect } from "react";
import { fileReader, openLinkNewTab } from "../../../utils/utils";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import { useModal } from "../../../context/ModalProvider";
import classNames from "classnames";
import styles from "./UploadFileField.module.css";
import { Row, Col } from "react-bootstrap";
import TakePhotoCamera from "../TakePhotoCamera";

const ActionUploadFile = ({
  label = "Pilih File",
  children,
  onClick = () => undefined,
}) => {
  return (
    <div className={styles.wrapperAction} onClick={onClick}>
      <div className={styles.actionRounded}>{children}</div>
      <p>{label}</p>
    </div>
  );
};

export const CardItemFile = ({ fileUpload, onClickDelte, isView = false }) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "HEIC"];
  const modal = useModal();

  const isImage = (file) => {
    const ext = file.split(".").pop().toLowerCase();
    return imageExtensions.includes(ext);
  };

  return (
    <Col md={!isView ? 3 : 5}>
      <div
        className={classNames(
          isView && styles.cardFileView,
          styles.cardFileItem
        )}
      >
        {!isView && (
          <div className={styles.buttonDeleteFile} onClick={onClickDelte}>
            <i className={classNames("fas fa-trash")}></i>
          </div>
        )}
        {isImage(fileUpload.name) ? (
          <img
            src={fileUpload.preview}
            alt={fileUpload.name}
            onClick={() =>
              modal.show(
                () => (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={fileUpload.preview}
                      alt={fileUpload.name}
                      style={{ width: 500 }}
                    />
                  </div>
                ),
                { size: "lg" }
              )
            }
          />
        ) : (
          <>
            <i className={classNames("fas fa-file-alt", styles.sizeIcon)}></i>
            <p
              className={styles.labelFileName}
              onClick={() =>
                fileUpload.name.split(":")[0] === "https" &&
                openLinkNewTab(fileUpload.name)
              }
            >
              {fileUpload.name}
            </p>
          </>
        )}
      </div>
    </Col>
  );
};

export default function UploadFileField({
  label,
  error,
  value,
  onChange = () => undefined,
  accept = "image/*",
  disabled = false,
}) {
  const modal = useModal();
  const localize = useLocalization();

  const [fileUploadLocal, setFileUploadLocal] = useState([]);

  useEffect(() => {
    if (value && value.length > 0 && fileUploadLocal.length === 0) {
      const datas = [];
      value.forEach((item) => {
        datas.push({
          name: item,
          preview: item,
        });
      });
      setFileUploadLocal(datas);
    }
  }, [value, fileUploadLocal]);

  const handleChangeFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileUpload = [...fileUploadLocal];
      const fileDocuments = [...value];

      await Promise.all(
        Array.from(e.target.files).map(async (file) => {
          const { fileDocument, fileName, filePreview } = await fileReader(
            file
          );
          fileUpload.push({ name: fileName, preview: filePreview });
          fileDocuments.push(fileDocument);
        })
      );

      setFileUploadLocal(fileUpload);
      onChange(fileDocuments);
    }
  };

  const handleClickDelete = (index) => {
    const fileUpload = [...fileUploadLocal];
    const fileDocuments = [...value];

    fileUpload.splice(index, 1);
    fileDocuments.splice(index, 1);

    setFileUploadLocal(fileUpload);
    onChange(fileDocuments);
  };

  const handleClearFile = () => {
    setFileUploadLocal([]);
    onChange([]);
  };

  const handleTakeCapture = (capture) => {
    const fileUpload = [...fileUploadLocal];
    const fileDocuments = [...value];

    fileUpload.push({ name: "captureImage.jpg", preview: capture.preview });
    fileDocuments.push(capture.file);

    setFileUploadLocal(fileUpload);
    onChange(fileDocuments);
  };

  const handleClickTakePhoto = () => {
    modal.show(
      () => (
        <TakePhotoCamera
          onCapture={(value) => {
            handleTakeCapture(value);
            modal.dismiss();
          }}
        />
      ),
      { centered: true }
    );
  };

  return (
    <div className="form-group mb-3 d-flex flex-column">
      <div className="d-flex justify-content-between">
        <label className="mb-1" htmlFor={"uploadFile"}>
          <strong>{label}</strong>
        </label>
        {fileUploadLocal.length > 0 && !disabled && (
          <div
            className="d-flex align-items-center gap-2 text-danger cursor-pointer"
            onClick={handleClearFile}
          >
            <i className="fas fa-trash-alt"></i>
            <p className="m-0">{localize.getText("clear")}</p>
          </div>
        )}
      </div>
      <div className={styles.wrapperUpload}>
        {fileUploadLocal.length === 0 ? (
          <div className={styles.wrapperEmptyImage}>
            <i className={classNames(styles.imageEmpty, "fas fa-image")}></i>
            <p>Masukkan Foto atau File</p>
          </div>
        ) : (
          <Row className="w-100">
            {fileUploadLocal &&
              fileUploadLocal.map((file, index) => (
                <CardItemFile
                  fileUpload={file}
                  onClickDelte={() => handleClickDelete(index)}
                  isView={disabled}
                />
              ))}
          </Row>
        )}

        {!disabled && (
          <div className="d-flex gap-5">
            <label
              htmlFor={"uploadFile"}
              className="d-flex flex-column gap-3 align-items-center justify-content-center c-pointer"
            >
              <ActionUploadFile label={localize.getText("chooseFile")}>
                <i className={classNames("fas fa-upload", styles.sizeIcon)}></i>
              </ActionUploadFile>
            </label>
            <ActionUploadFile
              label={localize.getText("takePhoto")}
              onClick={handleClickTakePhoto}
            >
              <i className={classNames("fas fa-camera", styles.sizeIcon)}></i>
            </ActionUploadFile>
          </div>
        )}
      </div>
      <input
        type="file"
        id={"uploadFile"}
        style={{ display: "none" }}
        onChange={(e) => handleChangeFile(e)}
        accept={accept}
        disabled={disabled}
        multiple
      />
      {error && (
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: "block" }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
