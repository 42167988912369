import React from "react";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { getParams } from "../../../../../utils/utils";

import TitlePage from "../../../../components/TitlePage";
import SelectField from "../../../../components/SelectField";
import ScheduleField from "../../../../components/ScheduleField";
import SwitchField from "../../../../components/SwitchField";
import Spinner from "../../../../components/Spinner";

export default function ScheduleDoctorComponent({
  onChange,
  isLoading = false,
  isLoadingSchedule = false,
  value,
  durationVisit,
  weekendMode = false,
  handleChangeDuration,
  handleChangeWeekendMode,
}) {
  const localize = useLocalization();
  const doctorName = getParams("doctor-name");

  return (
    <div className="card">
      <Spinner loading={isLoading}>
        <div className="card-body wrapper">
          <TitlePage
            text={`${doctorName} ${localize.getText("schedule")}`}
            isBack
          />
          <div className="d-flex w-100 justify-content-between align-items-center">
            <SelectField
              label="Durasi Visit"
              placeholder="Pilih durasi visit"
              style={{ width: 220 }}
              value={durationVisit}
              data={[
                { value: 15, label: "15 Menit" },
                { value: 30, label: "30 Menit" },
                { value: 60, label: "1 Jam" },
              ]}
              onChange={(value) => handleChangeDuration(value)}
              valueKey="value"
              labelKey="label"
              isBorder
            />
            <SwitchField
              leftLabel="Mode Libur"
              value={weekendMode}
              onChange={(value) => handleChangeWeekendMode(value)}
            />
          </div>
          <ScheduleField
            onChange={onChange}
            value={value}
            isLoading={isLoadingSchedule}
          />
        </div>
      </Spinner>
    </div>
  );
}
