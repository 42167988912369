import React from "react";

export default function TemplateEmail() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        background: "#ffffff",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
          padding: "12px 0",
        }}
      >
        <div
          style={{
            maxWidth: "590px",
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src="https://sistemklinik-public.s3.ap-southeast-1.amazonaws.com/sistemklinik-logo.png"
            style={{ width: "200px" }}
            alt=""
          />
          <img
            src="https://visit-file.s3.ap-southeast-1.amazonaws.com/4d5568e2-2097-439b-8e13-54e3999c0d36.png"
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
            alt=""
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            maxWidth: "590px",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <div
            style={{
              padding: "32px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "18px",
              borderRadius: "4px",
              background: "#F3F0F9",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g clip-path="url(#clip0_633_617)">
                <path
                  d="M13.9999 2.33331C7.55992 2.33331 2.33325 7.55998 2.33325 14C2.33325 20.44 7.55992 25.6666 13.9999 25.6666C20.4399 25.6666 25.6666 20.44 25.6666 14C25.6666 7.55998 20.4399 2.33331 13.9999 2.33331ZM13.9999 23.3333C8.85492 23.3333 4.66659 19.145 4.66659 14C4.66659 8.85498 8.85492 4.66665 13.9999 4.66665C19.1449 4.66665 23.3333 8.85498 23.3333 14C23.3333 19.145 19.1449 23.3333 13.9999 23.3333ZM19.3549 8.84331L11.6666 16.5316L8.64492 13.5216L6.99992 15.1666L11.6666 19.8333L20.9999 10.5L19.3549 8.84331Z"
                  fill="#886CC0"
                />
              </g>
              <defs>
                <clipPath id="clip0_633_617">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p
              style={{
                color: "#886CC0",
                fontSize: "18px",
                fontWeight: 600,
                marginBottom: 0,
              }}
            >
              Pendaftaran Berhasil
            </p>
            <p style={{ marginBottom: 0, fontSize: "16px", fontWeight: 500 }}>
              Terima kasih telah melakukan reservasi di [Nama Klinik]. Berikut
              adalah detail reservasi Anda
            </p>
          </div>
          <div
            style={{
              display: "flex",
              gap: "24px",
              flexDirection: "column",
              padding: "24px 0",
              borderBottom: "1px dashed #DBDBDB",
            }}
          >
            <p style={{ fontSize: "16px", fontWeight: 600, marginBottom: 0 }}>
              Lokasi Klinik
            </p>
            <div style={{ display: "flex", gap: "32px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginBottom: 0,
                    color: "#999",
                    fontWeight: 500,
                  }}
                >
                  Klinik Lorem Ipsum
                </p>
                <div style={{ display: "flex", gap: "8px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_633_623)">
                      <path
                        d="M10.0001 1.66663C6.77508 1.66663 4.16675 4.27496 4.16675 7.49996C4.16675 11.875 10.0001 18.3333 10.0001 18.3333C10.0001 18.3333 15.8334 11.875 15.8334 7.49996C15.8334 4.27496 13.2251 1.66663 10.0001 1.66663ZM5.83341 7.49996C5.83341 5.19996 7.70008 3.33329 10.0001 3.33329C12.3001 3.33329 14.1667 5.19996 14.1667 7.49996C14.1667 9.89996 11.7667 13.4916 10.0001 15.7333C8.26675 13.5083 5.83341 9.87496 5.83341 7.49996Z"
                        fill="#999999"
                      />
                      <path
                        d="M10.0001 9.58329C11.1507 9.58329 12.0834 8.65055 12.0834 7.49996C12.0834 6.34937 11.1507 5.41663 10.0001 5.41663C8.84949 5.41663 7.91675 6.34937 7.91675 7.49996C7.91675 8.65055 8.84949 9.58329 10.0001 9.58329Z"
                        fill="#999999"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_633_623">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p style={{ fontSize: "14px" }}>
                    Ciamis, jalan sukarno hatta, no 121
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginBottom: 0,
                    color: "#999",
                    fontWeight: 500,
                  }}
                >
                  Layanan Dipilih
                </p>
                <p style={{ fontSize: "14px", fontWeight: 500 }}>
                  Perawatan Kulit
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "24px",
              flexDirection: "column",
              padding: "24px 0",
              borderBottom: "1px dashed #DBDBDB",
            }}
          >
            <p style={{ fontSize: "16px", fontWeight: 600, marginBottom: 0 }}>
              Jadwal Kunjungan
            </p>
            <div style={{ display: "flex", gap: "32px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginBottom: 0,
                    color: "#999",
                    fontWeight: 500,
                  }}
                >
                  Tanggal
                </p>
                <p style={{ fontSize: "14px", fontWeight: 500 }}>
                  20 juni 2024
                  <br />
                  14:00 - 15:00 (1 jam)
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginBottom: 0,
                    color: "#999",
                    fontWeight: 500,
                  }}
                >
                  Dokter
                </p>
                <p style={{ fontSize: "14px", fontWeight: 500 }}>
                  Jaka Subanda
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginBottom: 0,
                    color: "#999",
                    fontWeight: 500,
                  }}
                >
                  Nama Pasien
                </p>
                <p style={{ fontSize: "14px", fontWeight: 500 }}>
                  Dendy Juliano
                </p>
              </div>
            </div>
          </div>
          <a
            href="https://calendar.google.com/calendar/r/eventedit?text=Reservasi%20di%20[Nama%20Klinik]&dates=[TanggalMulaiReservasi]T[WaktuMulaiReservasi]Z/[TanggalSelesaiReservasi]T[WaktuSelesaiReservasi]Z&details=Reservasi%20dengan%20[Nama%20Dokter]%20di%20[Nama%20Klinik]&location=[Alamat%20Klinik]"
            style={{
              padding: "8px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              border: "2px solid #886CC0",
              background: "#FFF",
              color: "#886CC0",
              fontSize: "16px",
              fontWeight: 600,
              textDecoration: "none",
            }}
          >
            <p style={{ marginBottom: 0 }}>Tambahkan ke Google Calendar</p>
          </a>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          background: "#886CC0",
          padding: "16px 0",
          boxShadow: "1px 0px 2px 0px rgba(0, 0, 0, 0.25)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#ffffff",
            fontSize: "12px",
            fontWeight: 500,
            marginBottom: 0,
          }}
        >
          Copyright © 2023 Sistem Klinik - All rights reserved
        </p>
      </div>
    </div>
  );
}
