import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { PER_PAGE_SIZE } from "../utils/constants";
import { getActiveVisitAll } from "../services/ActiveVisitService";
import { useNotification } from "../context/NotificationProvider";
import { useLocalization } from "../context/LocalizeContextProvider";

export default function usePatientActive() {
  const notification = useNotification();
  const localize = useLocalization();

  const [page, setPage] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [searchNoTelp, setSearchNoTelp] = useState("");
  const [searchDate, setSearchDate] = useState("");

  const [totalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [dataPatient, setDataPatient] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE_SIZE);

  const { isLoading, isRefetching, refetch } = useQuery(
    [
      "ActivePatientList",
      page,
      searchName,
      searchNoTelp,
      searchDate,
      sizePerPage,
    ],
    () =>
      getActiveVisitAll({
        page,
        searchName,
        searchNoTelp,
        searchDate,
        size: sizePerPage,
      }),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          setDataPatient(res?.data);
          setTotalEntries(Number(res.headers["x-total-count"]));
          setTotalPage(
            Math.ceil(Number(res.headers["x-total-count"]) / sizePerPage)
          );
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      refetchOnWindowFocus: false,
      refetchInterval: 30000,
    }
  );

  const handlePagination = (value) => {
    setPage(value);
  };

  const handleSetFilter = (searchName, searchNoTelp, searchDate) => {
    setSearchName(searchName);
    setSearchNoTelp(searchNoTelp);
    setSearchDate(searchDate);
  };

  const handleSetSizePerPage = (size) => {
    setPage(0);
    setSizePerPage(size);
  };

  return {
    page,
    totalPage,
    totalEntries,
    dataPatient,
    isLoading,
    isRefetching,
    refetch,
    handlePagination,
    handleSetFilter,
    setSizePerPage: handleSetSizePerPage,
    sizePerPage,
    filterSearch: {
      searchName,
      searchNoTelp,
      searchDate,
    },
  };
}
