import React from "react";
import { Form, Field } from "react-final-form";
import InputField from "../InputField";
import Button from "../Button";
import styles from "./ModalEndVisit.module.css";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import Modal from "../Modal";

export default function ModalEndVisit({
  handleHideModal,
  handleConfirmModal,
  isLoading = false,
  show = false,
  visitCompleted = false,
  patientId = null,
}) {
  const localize = useLocalization();
  return (
    <Modal centered visible={show}>
      <div className={styles.wrapper}>
        <div>
          <h3>{localize.getText("confirmation")}</h3>
          <p className={styles.notesConfirm}>
            {localize.getText(
              visitCompleted
                ? "confirmationEndComplete"
                : "confirmationEndEmpty"
            )}
          </p>
        </div>
        <Form
          onSubmit={handleConfirmModal}
          initialValues={{ patientId: patientId }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.wrapper}>
              <Field name="note">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("notes")}
                    isBorder
                    error={meta.touched && meta.error}
                    onChange={(value) => input.onChange(value)}
                    onBlur={input.onBlur}
                    value={input.value}
                  />
                )}
              </Field>
              <Field name="fileUrl">
                {({ input, meta }) => (
                  <InputField
                    label={localize.getText("fileUpload")}
                    type="file"
                    isBorder
                    error={meta.touched && meta.error}
                    onChange={(value) => input.onChange(value)}
                    onBlur={input.onBlur}
                    value={input.value}
                  />
                )}
              </Field>
              <div className={styles.actionWrapper}>
                <Button
                  label="Cancel"
                  variant="danger"
                  onClick={handleHideModal}
                  size="sm"
                />
                <Button
                  label="Confirm"
                  loading={isLoading}
                  size="sm"
                  type="submit"
                />
              </div>
            </form>
          )}
        </Form>
      </div>
    </Modal>
  );
}
