import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { postStartVisit } from "../../../../../services/AssesmentService";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import usePatient from "../../../../../hooks/usePatient";
import ListPatientComponent from "../Component";
import ModalStartVisit from "../../../../components/ModalStartVisit";

export default function ListPatient() {
  const defaultStateShow = {
    patientId: null,
    identificationNumber: null,
  };
  const notification = useNotification();
  const localize = useLocalization();
  const [showStartVisit, setShowStartVisit] = useState(defaultStateShow);

  const {
    page,
    totalPage,
    totalEntries,
    dataPatient,
    isLoading,
    isRefetching,
    sizePerPage,
    setSizePerPage,
    refetch,
    handlePagination,
    handleSetFilter,
  } = usePatient();

  const { isLoading: isLoadingStart, mutate: mutateStartVisit } = useMutation(
    postStartVisit,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successStartVisit"));
          setShowStartVisit(defaultStateShow);
          refetch();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleStartVisit = (item) => {
    setShowStartVisit({
      patientId: item.id,
      identificationNumber: item.identificationNumber,
    });
  };

  return (
    <>
      <ListPatientComponent
        dataPatient={dataPatient}
        isLoading={isLoading || isRefetching}
        isLoadingStart={isLoadingStart}
        totalPage={totalPage}
        totalEntries={totalEntries}
        activePage={page}
        sizePerPage={sizePerPage}
        setSizePerPage={setSizePerPage}
        handlePagination={handlePagination}
        mutateStartVisit={handleStartVisit}
        handleSetFilter={handleSetFilter}
      />
      <ModalStartVisit
        show={!!showStartVisit.patientId}
        patientId={showStartVisit.patientId}
        identificationNumber={showStartVisit.identificationNumber}
        handleHideModal={() => setShowStartVisit(defaultStateShow)}
        handleConfirmModal={mutateStartVisit}
        isLoading={isLoadingStart}
      />
    </>
  );
}
