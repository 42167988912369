import React from "react";
import cx from "classnames";
import Button from "../../../components/Button";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import { openLinkNewTab } from "../../../../utils/utils";

export default function Footer({
  dataLink = [],
  isJoin = false,
  isReservation = false,
}) {
  return (
    <div className={isJoin && styles.wrapperBottom} id="footer">
      <div
        className={cx(
          !isReservation && styles.footerWrapper,
          !isJoin && !isReservation && styles.footerWrapperNoJoin,
          isReservation && styles.footerReservation
        )}
      >
        {isJoin && (
          <div className={styles.joinWrapper}>
            <p>Siap Bekerja sama Dengan kami?</p>
            <Button
              label="Hubungi Kami"
              variant="success"
              className={styles.joinButton}
              onClick={() =>
                openLinkNewTab(`mailto:${process.env.REACT_APP_EMAIL}`)
              }
            />
          </div>
        )}
        {!isReservation && (
          <div className={styles.footerIconWrapper}>
            <img src="/assets/svg/icon-facebook.svg" alt="" />
            <img src="/assets/svg/icon-instagram.svg" alt="" />
            <img src="/assets/svg/icon-whatsapp.svg" alt="" />
            <img src="/assets/svg/icon-linkedin.svg" alt="" />
          </div>
        )}
        {dataLink.length > 0 && (
          <div className={styles.footerLinkWrapper}>
            {dataLink.map((item, index) =>
              item.isLink ? (
                <Link to={item.url} passHref>
                  <a key={index} href="/">
                    {item.label}
                  </a>
                </Link>
              ) : (
                <a href={item.url} key={index}>
                  {item.label}
                </a>
              )
            )}
          </div>
        )}
        <p className={styles.footerCopyRight}>
          Copyright © 2023 Sistem Klinik - All rights reserved
        </p>
      </div>
    </div>
  );
}
