import React from "react";
import { useLocalization } from "../../../../../../context/LocalizeContextProvider";
import styles from "./ModalSuccessSave.module.css";
import Button from "../../../../../components/Button";

export default function ModalSuccessSave({
  onClickClose,
  organizatioName,
  applicationName,
  status,
}) {
  const localize = useLocalization();
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperItem}>
        <div>
          <div className="row">
            <div className="col-md-6">
              <p className={styles.label}>
                {localize.getText("organizationName")}
              </p>
            </div>
            <div className="col-md-6">
              <p className={styles.value}>: {organizatioName}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p className={styles.label}>
                {localize.getText("applicationName")}
              </p>
            </div>
            <div className="col-md-6">
              <p className={styles.value}>: {applicationName}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p className={styles.label}>{localize.getText("status")}</p>
            </div>
            <div className="col-md-6">
              <p className={styles.value}>: {status}</p>
            </div>
          </div>
        </div>
      </div>
      <Button
        label={localize.getText("close")}
        style={{ width: 200 }}
        onClick={onClickClose}
      />
    </div>
  );
}
