import React from "react";
import FormOrganization from "../FormOrganization";

export default function AddOrganizationComponent({
  handleSubmit,
  isLoadingSubmit,
}) {
  return (
    <FormOrganization
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={{}}
    />
  );
}
