import React from "react";
import cx from "classnames";

export default function PillItem({
  text,
  bgColor = "bg-primary",
  isSmall = false,
}) {
  return (
    <div
      className={cx(
        isSmall ? "badge-xs" : "fs-6",
        `${bgColor} text-white rounded-pill py-1 px-2 font-w500 `
      )}
      style={{ width: "max-content" }}
    >
      <p className="mb-0">{text}</p>
    </div>
  );
}
