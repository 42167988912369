import axios from "axios";
import {
  getItemStorage,
  setItemToStorage,
  clearItemStorage,
} from "../utils/utils";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, data = null) => {
  if (error) {
    // DY: FOR LOGOUT AND CLEAR TOKEN
    clearItemStorage();
    window.location.href = "/login";
  } else {
    setItemToStorage("userDetails", data);
    // DY: FOR CHANGE TOKEN AND REFRESH TOKEN
  }
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// INTERCEPTOR REQUEST
instance.interceptors.request.use(
  async (config) => {
    const tokenDetails = getItemStorage("userDetails") || null;
    if (tokenDetails) {
      config.headers["Authorization"] = "Bearer " + tokenDetails.id_token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const tokenDetails = getItemStorage("userDetails") || null;
    const originalRequest = error.config;

    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return instance(originalRequest);
            })
            .catch((err) => {
              return err;
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const tokenRefresh = tokenDetails.refresh_token;

        return new Promise(function (resolve, reject) {
          axios
            .get(`${process.env.REACT_APP_BASE_API_URL}/token/refresh`, {
              headers: {
                refreshToken: tokenRefresh,
              },
            })
            .then(({ data }) => {
              instance.defaults.headers.common["Authorization"] =
                "Bearer " + data.id_token;
              originalRequest.headers["Authorization"] =
                "Bearer " + data.id_token;
              processQueue(null, data);
              resolve(instance(originalRequest));
            })
            .catch((err) => {
              console.log("DY: this is error happend", err);
              processQueue(err, null);
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }

      return error.response;
    }
  }
);

export default instance;
