import instance from "../config/interceptor";

export const registerAccount = async (data) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/register`,
      data
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const loginAccount = async (data) => {
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/authenticate`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const refreshTokenAccount = async (refreshToken) => {
  let _response;
  try {
    const response = await instance.get(
      `${process.env.REACT_APP_BASE_API_URL}/token/refresh`,
      {
        headers: {
          refreshToken,
        },
      }
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const accountActivation = async (key) => {
  let _response;
  try {
    const { response } = await instance.get(
      `${process.env.REACT_APP_BASE_API_URL}/activate?key=${key}`
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getAccountContent = async () => {
  let _response;
  try {
    const response = await instance.get(
      `${process.env.REACT_APP_BASE_API_URL}/account`
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const initResetPassword = async (email) => {
  const response = await instance.post(
    `${process.env.REACT_APP_BASE_API_URL}/account/reset-password/init`,
    email,
    {
      headers: {
        "Content-Type": "text/plain",
      },
    }
  );
  if (response.status !== 200) {
    throw new Error(response.data.message || "Something went wrong!");
  }
  return response;
};

export const validateResetPassword = async (key) => {
  let _response;
  try {
    const request = await instance.get(
      `${process.env.REACT_APP_BASE_API_URL}/account/reset-password/validate?key=${key}`
    );
    _response = request;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const finishInitResetPassword = async (data) => {
  let _response;
  try {
    const { response } = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/account/reset-password/finish`,
      data,
      {
        headers: {
          "Content-Type": "application/problem+json",
        },
      }
    );

    _response = response;
  } catch ({ response }) {
    _response = response;
  }

  return _response;
};

export const onboardingAccount = async (data) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/onboarding`,
      data
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const changePassword = async (data) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/account/change-password`,
      data
    );
    _response = response;
  } catch (err) {
    _response = err;
  }
  return _response;
};
