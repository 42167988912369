import React, { useState, useEffect } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelectRS from "react-select/async";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import cx from "classnames";
import { getCSSVariable } from "../../../utils/utils";

export default function AsyncSelect({
  label,
  labelClass,
  placeholder,
  loadOptions,
  error,
  onChange = () => undefined,
  onBlur,
  isMulti = false,
  disabled = false,
  value,
  options = [],
  isAsync = false,
  isLoading = false,
  isCreatable = false,
  isBordered = false,
  labelKey = "label",
  valueKey = "value",
  fullWidth = false,
  isGroup = false,
  isClearable = false,
  height,
}) {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    const stateData = [];
    if (options.length > 0 && !isGroup) {
      options.forEach((item) => {
        stateData.push({
          value: item[valueKey],
          label: item[labelKey],
        });
      });
      setDatas(stateData);
    }

    if (options.length > 0 && isGroup) {
      setDatas(options);
    }
    // eslint-disable-next-line
  }, [options, isGroup]);

  const customStyles = (baseStyles, state) => {
    // Fetch the CSS variable value
    const primaryColor = getCSSVariable("--primary");

    return {
      ...baseStyles,
      borderColor:
        state.isFocused || state.isSelected || isBordered
          ? primaryColor
          : "#f5f5f5",
      boxShadow: "",
      borderRadius: 12,
      minHeight: !height && 50,
      minWidth: 200,
      height: height,
      "&:hover": {
        borderColor: "",
      },
    };
  };

  return (
    <div className={cx(fullWidth && "w-100", "form-group")}>
      {label && (
        <label className={cx(labelClass, "mb-1")}>
          <strong>{label}</strong>
        </label>
      )}
      {isAsync && isCreatable ? (
        <AsyncCreatableSelect
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          isMulti={isMulti}
          placeholder={placeholder}
          onChange={(value) => onChange(value)}
          onBlur={onBlur}
          isDisabled={disabled}
          value={value}
          styles={{
            control: customStyles,
          }}
          isClearable={isClearable}
        />
      ) : isAsync ? (
        <AsyncSelectRS
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          isMulti={isMulti}
          placeholder={placeholder}
          onChange={(value) => onChange(value)}
          onBlur={onBlur}
          isDisabled={disabled}
          value={value}
          styles={{
            control: customStyles,
          }}
          isClearable={isClearable}
        />
      ) : isCreatable ? (
        <CreatableSelect
          isMulti={isMulti}
          className=""
          classNamePrefix="select"
          name="colors"
          value={value}
          options={datas}
          onChange={(value) => onChange(value)}
          onBlur={onBlur}
          isDisabled={disabled}
          placeholder={placeholder}
          isLoading={isLoading}
          styles={{
            control: customStyles,
          }}
          isClearable={isClearable}
        />
      ) : (
        <Select
          isMulti={isMulti}
          className="basic-multi-select"
          classNamePrefix="select"
          name="colors"
          value={value}
          options={datas}
          onChange={(value) => onChange(value)}
          onBlur={onBlur}
          isDisabled={disabled}
          placeholder={placeholder}
          isLoading={isLoading}
          styles={{
            control: customStyles,
          }}
          isClearable={isClearable}
        />
      )}
      {error && (
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: "block" }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
