import instance from "../../config/interceptor";
import { PER_PAGE_SIZE } from "../../utils/constants";

export const postLocation = async (data) => {
  let _response;
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_BASE_API_URL}/dashboard/satu-sehat/location`,
      data
    );
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getLocations = async ({ page = 1, size = PER_PAGE_SIZE }) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/satu-sehat/location?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};

export const getLocationDetail = async (id) => {
  let _response;
  try {
    let link = `${process.env.REACT_APP_BASE_API_URL}/dashboard/satu-sehat/location/${id}`;

    const response = await instance.get(link);
    _response = response;
  } catch ({ response }) {
    _response = response;
  }
  return _response;
};
