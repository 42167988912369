import React from "react";
import FormCategoryItem from "../FormCategoryItem";

export default function AddCategoryItemComponent({
  handleSubmit,
  isLoadingSubmit,
}) {
  return (
    <FormCategoryItem
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={{}}
    />
  );
}
