import React from "react";
import styles from "./ChangePassword.module.css";
import classNames from "classnames";
import { Form, Field } from "react-final-form";

import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { useLocalization } from "../../../../context/LocalizeContextProvider";

export default function ChangePasswordComponent({
  handleSubmitForm,
  isLoadingSubmit = false,
}) {
  const localize = useLocalization();

  return (
    <div className="card">
      <div className={classNames("card-body", styles.wrapper)}>
        <Form
          onSubmit={handleSubmitForm}
          validate={(values) => {
            const errors = {};

            if (!values.currentPassword) {
              errors.currentPassword = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("currentPassword") }
              );
            }

            if (!values.newPassword) {
              errors.newPassword = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("newPassword") }
              );
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("confirmNewPassword") }
              );
            }

            if (
              values.newPassword &&
              values.confirmPassword &&
              values.newPassword !== values.confirmPassword
            ) {
              errors.newPassword = localize.getText("passwordNotSameField");
            }

            return errors;
          }}
        >
          {({ handleSubmit, form }) => (
            <>
              <form
                className={styles.wrapperForm}
                onSubmit={async (e) => {
                  await handleSubmit(e);
                  form.reset();
                }}
              >
                <Field name="currentPassword">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("currentPassword")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("currentPassword"),
                      })}
                      type="password"
                      error={meta.dirty && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                    />
                  )}
                </Field>

                <Field name="newPassword">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("newPassword")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("newPassword"),
                      })}
                      type="password"
                      error={meta.dirty && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                    />
                  )}
                </Field>

                <Field name="confirmPassword">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("confirmNewPassword")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("confirmNewPassword"),
                      })}
                      type="password"
                      error={meta.dirty && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                    />
                  )}
                </Field>

                <Button
                  label={localize.getText("save")}
                  style={{ width: 180, marginTop: 42 }}
                  type="submit"
                  loading={isLoadingSubmit}
                />
              </form>
            </>
          )}
        </Form>
      </div>
    </div>
  );
}
