import React from "react";
import { Form, Field } from "react-final-form";
import { useLocalization } from "../../../../../../context/LocalizeContextProvider";

import { validEmail } from "../../../../../../utils/constants";
import Button from "../../../../../components/Button";
import InputField from "../../../../../components/InputField";
import TitlePage from "../../../../../components/TitlePage";

export default function FormLocation({
  handleSubmit = () => undefined,
  isLoadingSubmit = false,
  isEdit = false,
  isView = false,
  initialValues,
}) {
  const localize = useLocalization();

  return (
    <div className="card">
      <div className="card-body wrapper">
        <TitlePage
          text={
            (isEdit
              ? localize.getText("edit")
              : isView
              ? localize.getText("detail")
              : localize.getText("add")) +
            " " +
            localize.getText("location")
          }
          isBack
        />
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("name"),
              });
            }
            if (!values.description) {
              errors.description = localize.getText(
                "generalRequiredFieldValidation",
                {
                  fieldName: localize.getText("description"),
                }
              );
            }
            if (!values.email) {
              errors.email = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("email") }
              );
            }
            if (values.email && !values.email.match(validEmail)) {
              errors.email = localize.getText("notValidField", {
                fieldName: localize.getText("email"),
              });
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("phoneNumber") }
              );
            }
            if (!values.website) {
              errors.website = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("website") }
              );
            }
            if (!values.address) {
              errors.address = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("address") }
              );
            }
            if (!values.city) {
              errors.city = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("city"),
              });
            }
            if (!values.postalCode) {
              errors.postalCode = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("postalCode") }
              );
            }
            if (!values.province) {
              errors.province = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("province") }
              );
            }
            if (!values.district) {
              errors.district = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("district") }
              );
            }
            if (!values.village) {
              errors.village = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("village") }
              );
            }
            if (!values.rt) {
              errors.rt = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("rt"),
              });
            }
            if (!values.rw) {
              errors.rw = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("rw"),
              });
            }

            return errors;
          }}
        >
          {({ handleSubmit }) => (
            <>
              <form className="wrapper-form" onSubmit={handleSubmit}>
                <Field name="name">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("name")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("name"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="description">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("description")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("description"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                      type="textarea"
                    />
                  )}
                </Field>
                <Field name="email">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("email")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("email"),
                      })}
                      type="email"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="phoneNumber">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("phoneNumber")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("phoneNumber"),
                      })}
                      type="number"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="website">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("website")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("website"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                      disabled={isView}
                    />
                  )}
                </Field>
                <div className="row">
                  <div className="col-md-3">
                    <Field name="province">
                      {({ input, meta }) => (
                        <InputField
                          label={localize.getText("province")}
                          placeholder={localize.getText("placeholderField", {
                            fieldName: localize.getText("province"),
                          })}
                          error={meta.touched && meta.error}
                          onChange={(value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={isView}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-md-3">
                    <Field name="city">
                      {({ input, meta }) => (
                        <InputField
                          label={localize.getText("city")}
                          placeholder={localize.getText("placeholderField", {
                            fieldName: localize.getText("city"),
                          })}
                          error={meta.touched && meta.error}
                          onChange={(value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={isView}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-md-3">
                    <Field name="district">
                      {({ input, meta }) => (
                        <InputField
                          label={localize.getText("district")}
                          placeholder={localize.getText("placeholderField", {
                            fieldName: localize.getText("district"),
                          })}
                          error={meta.touched && meta.error}
                          onChange={(value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={isView}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-md-3">
                    <Field name="village">
                      {({ input, meta }) => (
                        <InputField
                          label={localize.getText("village")}
                          placeholder={localize.getText("placeholderField", {
                            fieldName: localize.getText("village"),
                          })}
                          error={meta.touched && meta.error}
                          onChange={(value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={isView}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <Field name="postalCode">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("postalCode")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("postalCode"),
                      })}
                      type="number"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                      disabled={isView}
                    />
                  )}
                </Field>
                <div className="row">
                  <div className="col-md-6">
                    <Field name="rt">
                      {({ input, meta }) => (
                        <InputField
                          label={localize.getText("rt")}
                          placeholder={localize.getText("placeholderField", {
                            fieldName: localize.getText("rt"),
                          })}
                          error={meta.touched && meta.error}
                          onChange={(value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={isView}
                          type="number"
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-md-6">
                    <Field name="rw">
                      {({ input, meta }) => (
                        <InputField
                          label={localize.getText("rw")}
                          placeholder={localize.getText("placeholderField", {
                            fieldName: localize.getText("rw"),
                          })}
                          error={meta.touched && meta.error}
                          onChange={(value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={isView}
                          type="number"
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <Field name="address">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("address")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("address"),
                      })}
                      type="textarea"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                      disabled={isView}
                    />
                  )}
                </Field>

                {!isView && (
                  <Button
                    label={localize.getText("save")}
                    style={{ width: 180, marginTop: 42 }}
                    type="submit"
                    loading={isLoadingSubmit}
                  />
                )}
              </form>
            </>
          )}
        </Form>
      </div>
    </div>
  );
}
