import React, { createContext, useContext, useState } from "react";
import styles from "./ModalProvider.module.css";
import classNames from "classnames";
import Button from "../../jsx/components/Button";
import { Modal } from "react-bootstrap";

export const ModalContext = createContext({
  show: (data, options) => {
    // DO NOTHING
  },
  showConfirmation: (data) => {
    // DO NOTHING
  },
  dismiss: () => {
    // DO NOTHING
  },
});

export const useModal = () => useContext(ModalContext);

const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState(null);
  const [modalConfirmation, setModalConfirmation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleHideModal = () => {
    setModalData(null);
    setModalConfirmation(null);
    setIsLoading(false);
  };

  const handleShowModal = (data, options) => {
    setModalData({
      visible: true,
      element: data,
      options,
    });
  };

  const handleShowConfirmation = (data) => {
    setModalConfirmation(data);
  };

  const handleConfirmModal = () => {
    modalConfirmation && modalConfirmation.onConfirm();
    setIsLoading(!isLoading);
  };

  const response = {
    show: handleShowModal,
    showConfirmation: handleShowConfirmation,
    dismiss: handleHideModal,
  };

  return (
    <ModalContext.Provider value={response}>
      <>
        {modalConfirmation && (
          <Modal
            show={!!modalConfirmation}
            centered
            size="sm"
            onHide={handleHideModal}
          >
            <Modal.Body>
              {modalConfirmation?.title && <h5>{modalConfirmation?.title}</h5>}
              <p>{modalConfirmation?.message}</p>
              <div className={styles.actionWrapper}>
                <Button
                  label="Batal"
                  variant="danger"
                  onClick={handleHideModal}
                  size="sm"
                />
                <Button
                  label="Konfirmasi"
                  onClick={handleConfirmModal || handleHideModal}
                  loading={isLoading}
                  size="sm"
                />
              </div>
            </Modal.Body>
          </Modal>
        )}
        {modalData && (
          <Modal
            show={modalData?.visible}
            onHide={handleHideModal}
            centered={modalData?.options?.centered}
            size={modalData?.options?.size}
          >
            <Modal.Body className={styles.modalWrapper}>
              {!modalData?.options?.isForce && (
                <i
                  className={classNames(
                    "fas fa-times-circle",
                    styles.closeIcon
                  )}
                  onClick={handleHideModal}
                ></i>
              )}
              <div
                className={
                  modalData?.options?.isBorderTitle && styles.modalTitleWrapper
                }
              >
                {modalData?.options?.title && (
                  <h4>{modalData?.options?.title}</h4>
                )}
              </div>
              {modalData?.element()}
            </Modal.Body>
          </Modal>
        )}
        {children}
      </>
    </ModalContext.Provider>
  );
};

ModalProvider.defaultProps = {};

export default ModalProvider;
