import React, { useState } from "react";
import EditItemComponent from "../../components/EditItem";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import {
  getItemDetail,
  putItem,
} from "../../../../../services/Handlers/MasterItemsService";
import { useHistory } from "react-router-dom";
import { getParams } from "../../../../../utils/utils";
import LoadingSection from "../../../../components/LoadingSection";

export default function EditItem() {
  const notification = useNotification();
  const localize = useLocalization();
  const history = useHistory();
  const id = getParams("id");

  const [initialValues, setInitialValues] = useState({});

  const { isLoading, isFetching } = useQuery(
    ["ItemDetail"],
    () => getItemDetail(id),
    {
      enabled: !!id,
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          setInitialValues({
            name: response.name,
            price: response.price,
            category_item_id: response.category_item_id,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    putItem,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          history.goBack();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleSubmit = (value) => {
    const data = {
      name: value.name,
      price: value.price,
      category_item_id: value.category_item_id,
      id,
    };
    mutateSubmit(data);
  };

  if (isLoading || isFetching) {
    <LoadingSection />;
  }

  return (
    <EditItemComponent
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={initialValues}
    />
  );
}
