import React from "react";

import { Spinner as SpinnerRB } from "react-bootstrap";
import styles from "./Spinner.module.css";

export default function Spinner({ children, loading = false }) {
  return (
    <div className="position-relative">
      {children}
      {loading && (
        <div className={styles.positionSpinner}>
          <SpinnerRB animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
}
