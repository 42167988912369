import React from "react";
import { Form, Field } from "react-final-form";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";

import Button from "../../../../components/Button";
import InputField from "../../../../components/InputField";
import TitlePage from "../../../../components/TitlePage";

export default function FormLocation({
  handleSubmit = () => undefined,
  isLoadingSubmit = false,
  isEdit = false,
  isView = false,
  initialValues,
}) {
  const localize = useLocalization();

  return (
    <div className="card">
      <div className="card-body wrapper">
        <TitlePage
          text={
            (isEdit
              ? localize.getText("edit")
              : isView
              ? localize.getText("detail")
              : localize.getText("add")) +
            " " +
            localize.getText("category-item")
          }
          isBack
        />
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("name"),
              });
            }

            return errors;
          }}
        >
          {({ handleSubmit }) => (
            <>
              <form className="wrapper-form" onSubmit={handleSubmit}>
                <Field name="name">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("name")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("name"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>

                {!isView && (
                  <Button
                    label={localize.getText("save")}
                    style={{ width: 180, marginTop: 42 }}
                    type="submit"
                    loading={isLoadingSubmit}
                  />
                )}
              </form>
            </>
          )}
        </Form>
      </div>
    </div>
  );
}
