import React from "react";
import FormCategoryItem from "../FormCategoryItem";

export default function DetailCategoryItemComponent({ initialValues }) {
  return (
    <FormCategoryItem
      isView
      handleSubmit={() => {}}
      initialValues={initialValues}
    />
  );
}
