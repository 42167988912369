import React, { useState, useRef, useEffect } from "react";
import { fileReader } from "../../../utils/utils";
import classNames from "classnames";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import styles from "./InputField.module.css";

export default function InputField({
  label,
  placeholder,
  value = null,
  onChange = () => undefined,
  onBlur = () => undefined,
  error,
  type,
  disabled = false,
  rows = 5,
  isBorder = false,
  style,
  classNameInput,
  fullWidth = false,
  autoFocus = false,
  id,
  views,
  format = "DD/MM/YYYY",
  shouldDisableMonth,
  shouldDisableDate,
  shouldDisableYear,
  disableHighlightToday = false,
  isEndField = false,
  disablePast = false,
}) {
  const [openEyes, setOpenEyes] = useState(false);
  const inputRef = useRef(null);

  const onChangeFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { fileDocument } = await fileReader(e.target.files[0]);
      onChange(fileDocument);
    }
  };

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className={classNames("form-group", fullWidth && "w-100")}>
      {label && (
        <label className="mb-1 ">
          <strong>{label}</strong>
        </label>
      )}
      <div className={styles.wrapperInput}>
        {type === "textarea" ? (
          <textarea
            className={classNames(classNameInput, "form-control  h-100")}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
            disabled={disabled}
            rows={rows}
            id={id}
            ref={inputRef}
          />
        ) : type === "file" ? (
          <div className="input-group mb-3">
            <div
              className="form-file"
              style={{
                borderRadius: 16,
                ...style,
                ...{
                  ...(isBorder && {
                    border: "0.5px solid var(--primary)",
                    height: 38,
                  }),
                },
              }}
            >
              <input
                type="file"
                className={classNames(
                  classNameInput,
                  "custom-file-input form-control"
                )}
                style={{ borderRadius: 16 }}
                onChange={(e) => onChangeFile(e)}
                onBlur={onBlur}
                disabled={disabled}
                ref={inputRef}
              />
            </div>
          </div>
        ) : type === "date" ? (
          <DatePicker
            value={moment(value) || null}
            onChange={(value) => {
              if (value && value.isValid()) {
                if (views?.includes("month")) {
                  if (isEndField) {
                    onChange(value.endOf("month").format("YYYY-MM-DD"));
                  } else {
                    onChange(value.startOf("month").format("YYYY-MM-DD"));
                  }
                  return;
                }
                if (views?.includes("year")) {
                  if (isEndField) {
                    onChange(
                      value.endOf("year").endOf("day").format("YYYY-MM-DD")
                    );
                  } else {
                    onChange(
                      value.startOf("year").startOf("day").format("YYYY-MM-DD")
                    );
                  }
                  return;
                }
                onChange(value.format("YYYY-MM-DD"));
              } else {
                onChange(null);
              }
            }}
            disabled={disabled}
            format={format}
            className={classNames("date-picker-input", classNameInput)}
            ref={inputRef}
            views={views}
            disableHighlightToday={disableHighlightToday}
            shouldDisableYear={shouldDisableYear}
            shouldDisableMonth={shouldDisableMonth}
            shouldDisableDate={shouldDisableDate}
            disablePast={disablePast}
          />
        ) : (
          <input
            className={classNames(classNameInput, "form-control")}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
            type={openEyes && type === "password" ? "text" : type}
            style={{
              ...style,
              ...{
                ...(type === "password" && { paddingRight: 50 }),
                ...(isBorder && {
                  border: "0.5px solid var(--primary)",
                  height: 38,
                }),
              },
            }}
            disabled={disabled}
            id={id}
            ref={inputRef}
          />
        )}
        {type === "password" &&
          (openEyes ? (
            <i
              className={classNames("fas fa-eye-slash", styles.iconEye)}
              onClick={() => setOpenEyes(!openEyes)}
            ></i>
          ) : (
            <i
              className={classNames("fas fa-eye fz-24", styles.iconEye)}
              onClick={() => setOpenEyes(!openEyes)}
            ></i>
          ))}
      </div>
      {error && (
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: "block" }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
