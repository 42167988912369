import React from "react";
import AddCategoryItemComponent from "../../components/AddCategoryItem";
import { useMutation } from "@tanstack/react-query";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { useAuth } from "../../../../../context/AuthProvider";
import { postCategoryItem } from "../../../../../services/Handlers/MasterCategoryItemService";

import { useHistory } from "react-router-dom";

export default function AddCategoryItem() {
  const notification = useNotification();
  const localize = useLocalization();
  const history = useHistory();
  const { userDetail } = useAuth();

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postCategoryItem,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          history.goBack();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const handleSubmit = (value) => {
    const data = {
      name: value.name,
      busines_id: userDetail?.businessId,
    };
    mutateSubmit(data);
  };

  return (
    <AddCategoryItemComponent
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
    />
  );
}
