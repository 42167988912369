import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import styles from "./TitlePage.module.css";

export default function TitlePage({ text, isBack = false }) {
  const history = useHistory();
  return (
    <div className={styles.wrapperTitle}>
      {isBack && (
        <i
          onClick={() => history.goBack()}
          className={classNames("fas fa-chevron-left", styles.iconBack)}
        ></i>
      )}
      <p className={styles.textTitle}>{text}</p>
    </div>
  );
}
