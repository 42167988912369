import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { useQuery } from "@tanstack/react-query";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { useAuth } from "../../../../../context/AuthProvider";
import { getCategoryItems } from "../../../../../services/Handlers/MasterCategoryItemService";
import SelectField from "../../../../components/SelectField";

import Button from "../../../../components/Button";
import InputField from "../../../../components/InputField";
import TitlePage from "../../../../components/TitlePage";

export default function FormItem({
  handleSubmit = () => undefined,
  isLoadingSubmit = false,
  isEdit = false,
  isView = false,
  initialValues,
}) {
  const localize = useLocalization();
  const { userDetail } = useAuth();
  const [dataCategoryItem, setDataCategoryItem] = useState([]);

  useQuery(
    ["CategoryItemList", userDetail?.businessId],
    () => getCategoryItems({ businessId: userDetail?.businessId, size: 99999 }),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          setDataCategoryItem(res?.data.data || []);
        }
      },
      enabled: !!userDetail?.businessId,
    }
  );

  return (
    <div className="card">
      <div className="card-body wrapper">
        <TitlePage
          text={
            (isEdit
              ? localize.getText("edit")
              : isView
              ? localize.getText("detail")
              : localize.getText("add")) +
            " " +
            localize.getText("item")
          }
          isBack
        />
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.category_item_id) {
              errors.category_item_id = localize.getText(
                "generalRequiredFieldValidation",
                {
                  fieldName: localize.getText("category-item"),
                }
              );
            }
            if (!values.name) {
              errors.name = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("name"),
              });
            }
            if (!values.price) {
              errors.price = localize.getText(
                "generalRequiredFieldValidation",
                {
                  fieldName: localize.getText("price"),
                }
              );
            }

            return errors;
          }}
        >
          {({ handleSubmit }) => (
            <>
              <form className="wrapper-form" onSubmit={handleSubmit}>
                <Field name="category_item_id">
                  {({ input, meta }) => (
                    <SelectField
                      label={localize.getText("category-item")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("category-item"),
                      })}
                      data={dataCategoryItem}
                      valueKey="id"
                      labelKey="name"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="name">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("name")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("name"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="price">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("price")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("price"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>

                {!isView && (
                  <Button
                    label={localize.getText("save")}
                    style={{ width: 180, marginTop: 42 }}
                    type="submit"
                    loading={isLoadingSubmit}
                  />
                )}
              </form>
            </>
          )}
        </Form>
      </div>
    </div>
  );
}
