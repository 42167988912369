import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import OrganizationComponent from "../components";
import { getOrganizations } from "../../../../../services/SatuSehat/OrganizationService";

export default function Organization() {
  const [dataOrganizations, setDataOrganizations] = useState([]);

  const { isLoading } = useQuery(["OrganizationList"], getOrganizations, {
    onSuccess: (data) => {
      if (data.status === 200 || data.status === 201) {
        setDataOrganizations(data?.data?.entry || []);
      }
    },
  });

  return (
    <OrganizationComponent
      dataOrganizations={dataOrganizations}
      isLoading={isLoading}
    />
  );
}
