import { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNotification } from "../context/NotificationProvider";
import { useLocalization } from "../context/LocalizeContextProvider";
import {
  getHistoryVisits,
  exportHistoryVisits,
} from "../services/HistoryVisitService";
import { PER_PAGE_SIZE } from "../utils/constants";
import moment from "moment";
import { downloadBlob } from "../utils/utils";

export default function useHistoryVisit() {
  const notification = useNotification();
  const localize = useLocalization();
  const defaultFilter = {
    type: { label: "Harian", value: "day" },
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    page: 0,
    size: PER_PAGE_SIZE,
  };

  const [totalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [dataHistoryVisit, setDataHistoryVisit] = useState([]);
  const [filterHistory, setFilterHistory] = useState(defaultFilter);

  const { isLoading, mutate: mutateHistory } = useMutation(
    ["GetHistoryVisits"],
    (data) => getHistoryVisits(data),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          setDataHistoryVisit(res?.data);
          setTotalEntries(Number(res.headers["x-total-count"]));
          setTotalPage(
            Math.ceil(Number(res.headers["x-total-count"]) / filterHistory.size)
          );
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
    }
  );

  const { isLoading: isLoadingExport, mutate: mutateExportHistory } =
    useMutation(["ExportHistoryVisits"], (data) => exportHistoryVisits(data), {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          downloadBlob(res.data, "history-visit");
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
    });

  useEffect(() => {
    mutateHistory(filterHistory);
    // eslint-disable-next-line
  }, []);

  const handleChangeFilter = async (name, value) => {
    if (name === "type") {
      await setFilterHistory((prevState) => ({
        ...prevState,
        from: null,
        to: null,
      }));
    } else if (
      name === "from" &&
      filterHistory.to &&
      moment(value, "YYYY-MM-DD").isAfter(
        moment(filterHistory.to, "YYYY-MM-DD")
      )
    ) {
      await setFilterHistory((prevState) => ({
        ...prevState,
        to: null,
      }));
    }
    setFilterHistory((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClickFilter = () => {
    handleChangeFilter("page", 0);
    mutateHistory({ ...filterHistory, page: 0 });
  };

  const handleChangeSizePerPage = (size) => {
    handleChangeFilter("size", size);
    handleChangeFilter("page", 0);
    mutateHistory({ ...filterHistory, size, page: 0 });
  };

  const handlePagination = (value) => {
    handleChangeFilter("page", value);
    mutateHistory({
      from: filterHistory.from,
      to: filterHistory.to,
      page: value,
      size: filterHistory.size,
    });
  };

  const handleClearFilter = () => {
    setFilterHistory(defaultFilter);
  };

  const handleExportHistory = () => {
    mutateExportHistory(filterHistory);
  };

  return {
    filterHistory,
    totalPage,
    totalEntries,
    dataHistoryVisit,
    isLoading,
    isLoadingExport,
    handlePagination,
    handleClickFilter,
    handleChangeFilter,
    handleClearFilter,
    handleExportHistory,
    handleChangeSizePerPage,
  };
}
