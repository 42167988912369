import React, { useState } from "react";
import DetailOrganizationComponent from "../../components/DetailOrganization";
import { useQuery } from "@tanstack/react-query";
import {
  getParams,
  satuSehatFindAddressExtentionRes,
  satuSehatFindTelecomRes,
} from "../../../../../../utils/utils";
import { getOrganizationDetail } from "../../../../../../services/SatuSehat/OrganizationService";
import LoadingSection from "../../../../../components/LoadingSection";

export default function DetailOrganization() {
  const id = getParams("id");
  const [initialValues, setInitialValues] = useState({});

  const { isLoading } = useQuery(
    ["OrganizationDetail"],
    () => getOrganizationDetail(id),
    {
      enabled: !!id,
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          setInitialValues({
            name: response.name,
            email: satuSehatFindTelecomRes(response, "email"),
            phoneNumber: satuSehatFindTelecomRes(response, "phone"),
            website: satuSehatFindTelecomRes(response, "url"),
            address: response.address[0].line[0],
            city: satuSehatFindAddressExtentionRes(response, "city"),
            postalCode: response.address[0].postalCode,
            province: satuSehatFindAddressExtentionRes(response, "province"),
            district: satuSehatFindAddressExtentionRes(response, "district"),
            village: satuSehatFindAddressExtentionRes(response, "village"),
          });
        }
      },
    }
  );

  if (isLoading) {
    <LoadingSection />;
  }

  return <DetailOrganizationComponent initialValues={initialValues} />;
}
